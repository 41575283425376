import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemAvatar,
  styled,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { getFirstLetter } from '../../../utils';
import { getTruncatedNameByWidth } from '../../../utils/stringutils';
import { CardField, CardProps } from '../model';

export interface CardOverviewProps extends CardProps {
  avatarField?: string;
  cardFields?: CardField[];
  title?: string;
  size?: string;
  icon?: any;
  hover?: boolean;
  minHeight?: number;
}

const CardWrapper = styled(Card)(
  ({ theme }) => `
  
    position: relative;
    overflow: visible;
    border-color: ${
      theme.palette.mode === 'light'
        ? theme.colors.alpha.black[30]
        : theme.colors.borders[1]
    };
    border-style: solid;
    border-width: 1px;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: inherit;
      z-index: 1;
      transition: ${theme.transitions.create(['box-shadow'])};
    }
        
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  
    &.conditional:hover {
      border-color: ${
        theme.palette.mode === 'light'
          ? theme.colors.primary.light
          : theme.colors.borders[1]
      };

      border-style: solid;
      border-width: 1px;
    }

    &.conditionalOnRowClick:hover {
      background-color: ${
        theme.palette.mode === 'light'
          ? '#F8F9FA'
          : theme.colors.alpha.black[30]
      };    }
  `
);

const CardFieldContent = ({ data, cardField }) => {
  if (cardField.objectDecoratorFn) {
    return cardField.objectDecoratorFn(data);
  }
  return (
    <>
      {cardField.fieldDecoratorFn ? (
        data[cardField.fieldName] ? (
          cardField.fieldDecoratorFn(data[cardField.fieldName])
        ) : (
          cardField.fieldDecoratorFn('-')
        )
      ) : data[cardField.fieldName] ? (
        getTruncatedNameByWidth(data[cardField.fieldName], 30, 400, 'body1')
      ) : (
        <Typography
          sx={{
            pt: 1,
            pb: 1,
            lineHeight: 1.6,
          }}
          variant="h6"
        ></Typography>
      )}
    </>
  );
};

const getToLink = (cardField, props) => {
  let url = cardField.fieldLinkURL;
  if (cardField.fieldLinkURLParam) {
    url += props.data[cardField.fieldLinkURLParam];
  }
  if (cardField.fieldSourceURLFn) {
    url += '?from=' + cardField.fieldSourceURLFn(props.data);
    if (props.fieldLinkURLContext) {
      url += props.fieldLinkURLContext;
    }
  }
  return url;
};

const CardOverview: FC<CardOverviewProps> = (props) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const size =
    props.size === 'xlarge'
      ? 12
      : props.size === 'large'
      ? 6
      : props.size === 'small'
      ? 3
      : 4;
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={props.size === 'xlarge' ? 12 : 6} lg={size}>
      <CardWrapper
        className={clsx({
          'Mui-selected': false,
          conditional: props['hover'] !== undefined ? props['hover'] : true,
          conditionalOnRowClick: props.onRowClick ? true : false,
        })}
        sx={{
          ...(props.onRowClick ? { cursor: 'pointer' } : { cursor: 'auto' }),
          ...(props.selected && {
            background: `${theme.colors.secondary.lighter}`,
          }),
        }}
        onClick={() =>
          props.onRowClick ? props.onRowClick(props.data) : undefined
        }
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: '2',
            background:
              theme.palette.mode === 'dark'
                ? `${theme.colors.alpha.black[30]}`
                : `${theme.colors.alpha.black[1]}`,
          }}
        >
          <Box
            px={2}
            py={1}
            minHeight={'49px'}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {props.icon ? (
                <ListItemAvatar
                  sx={{
                    minWidth: 36,
                  }}
                >
                  <props.icon />
                </ListItemAvatar>
              ) : (
                <Avatar
                  sx={{
                    background: (theme: Theme) => theme.palette.primary.light,
                    maxWidth: 36,
                    maxHeight: 36,
                  }}
                  variant="circular"
                >
                  {getFirstLetter(props.data[props.avatarField])}
                </Avatar>
              )}
              {props.title && (
                <Typography variant="h5" lineHeight={1} sx={{ pl: 1 }}>
                  {t(props.title)}
                </Typography>
              )}
            </Box>
            {props.statusFieldDecoratorFn
              ? props.statusFieldDecoratorFn(props.data[props.statusField])
              : props.data[props.statusField]}
          </Box>
          <Divider />
          <Box p={2} display="flex" alignItems="flex-start">
            <Box
              sx={{
                minHeight: props.minHeight && `${props.minHeight}px`,
              }}
            >
              {props.cardFields &&
                props.cardFields.map((cardField, i) => {
                  return (
                    <div key={i}>
                      {cardField.fieldLinkFn || cardField.fieldLinkURL ? (
                        <>
                          {cardField.fieldLinkFn && (
                            <Link
                              component="button"
                              onClick={(event) => {
                                cardField.fieldLinkFn(props.data);
                                event.stopPropagation();
                              }}
                              id={
                                props.primaryKey
                                  ? 'id_button_row_' +
                                    props.data[props.primaryKey] +
                                    '_link'
                                  : 'id_button_row_' + i + '_link'
                              }
                            >
                              <CardFieldContent
                                data={props.data}
                                cardField={cardField}
                              ></CardFieldContent>
                              &nbsp;
                            </Link>
                          )}

                          {cardField.fieldLinkURL && (
                            <Link
                              component="button"
                              sx={{ fontSize: '15px' }}
                              id={
                                props.primaryKey
                                  ? 'id_button_row_' +
                                    props.data[props.primaryKey] +
                                    '_link'
                                  : 'id_button_row_' + i + '_link'
                              }
                              onClick={(event) => {
                                navigate(getToLink(cardField, props));
                                event.stopPropagation();
                              }}
                            >
                              <CardFieldContent
                                data={props.data}
                                cardField={cardField}
                              ></CardFieldContent>
                            </Link>
                          )}
                        </>
                      ) : (
                        <CardFieldContent
                          data={props.data}
                          cardField={cardField}
                        ></CardFieldContent>
                      )}
                    </div>
                  );
                })}
            </Box>
          </Box>
          {props.cardActions && (
            <>
              <Divider />
              <Grid
                container
                onClick={(e) => e.stopPropagation()}
                alignItems="center"
                sx={{ background: `${theme.colors.alpha.black[5]}` }}
              >
                {props.multiSelect && (
                  <Grid item sx={{ ml: 1 }}>
                    <Checkbox
                      color="primary"
                      checked={props.selected}
                      onChange={(_, checked) => {
                        props.onRowSelected(props.id - 1, props.data, checked);
                      }}
                    />
                  </Grid>
                )}
                <Grid item sx={{ ml: 'auto' }}>
                  <Box pl={2} py={1} pr={1}>
                    {props.cardActions.map((action, i) => {
                      return (
                        <Tooltip key={i} title={t(action.caption)} arrow>
                          <IconButton
                            onClick={(event) => {
                              if (!action.disabled) {
                                action.type === 'delete'
                                  ? props.deleteHandler(props.data)
                                  : action.callbackFn(props.data);
                              }
                              event.stopPropagation();
                            }}
                            color={action.color}
                            disabled={action.disabled}
                            id={
                              props.primaryKey
                                ? 'id_button_row_' +
                                  props.data[props.primaryKey] +
                                  '_' +
                                  action.caption
                                : 'id_button_row_' + i + '_' + action.caption
                            }
                          >
                            <action.icon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </CardWrapper>
    </Grid>
  );
};

export default CardOverview;
