import {
  Box,
  Grid,
  MenuItem,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAuth from '../../hooks/useAuth';
import AAIListItem from '../AAIListItem';
import AAISelect from '../AAISelect';

export type AAIRadioGroupProps = {
  label: string;
  lastElement?: boolean;
  compact?: boolean;
  readonly?: boolean;
  equalWidth?: boolean;
  compactlist?: boolean;
  skipConvertToSelect?: boolean;
  disabled?: boolean;
} & RadioGroupProps;

export default function AAIRadioGroup(props: AAIRadioGroupProps) {
  const { t } = useTranslation();
  const auth = useAuth();
  const {
    children,
    equalWidth,
    compact,
    compactlist,
    lastElement,
    skipConvertToSelect,
    disabled,
    ...radioProps
  } = props;
  const { mobileView } = useContext(GlobalContext);

  // Extract options from children for select
  const options = React.Children.toArray(children);

  return (
    <>
      {auth.user.readOnly || props.readonly ? (
        <AAIListItem
          label={t(props.label as string)}
          value={props.value}
          lastElement={props.lastElement}
          compact={compactlist}
        />
      ) : (
        <>
          {mobileView && !props.skipConvertToSelect ? (
            <AAISelect
              id={props.id}
              labelId={props.id + '_label'}
              value={props.value}
              onChange={props.onChange}
              label={t(props.label)}
              sx={{ top: 0 }}
              name={props.name}
              caption={t(props.label)}
              disabled={props.disabled}
            >
              {options.map((option: any) => (
                <MenuItem
                  id={option.props.id}
                  key={option.props.value}
                  value={option.props.value}
                >
                  {t(option.props.label)}
                </MenuItem>
              ))}
            </AAISelect>
          ) : (
            <Box p={!props.compact && 2} py={0}>
              <Grid
                item
                display="flex"
                alignItems={props.row ? 'center' : 'start'}
                justifyContent="left"
              >
                {props.equalWidth ? (
                  <>
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle2"
                        pr={2}
                        pt={props.row ? 0 : 1}
                      >
                        {t(props.label)}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <RadioGroup {...radioProps} children={props.children} />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="subtitle2"
                      pr={2}
                      pt={props.row ? 0 : 1}
                    >
                      {t(props.label)}:
                    </Typography>
                    <RadioGroup {...radioProps} children={props.children} />
                  </>
                )}
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  );
}
