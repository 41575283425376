import { LanguageSwitcher, Logo, SidebarContext, useAuth, Userbox } from "@antara/shared";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { alpha, Box, Card, IconButton, styled, Tooltip, useTheme } from "@mui/material";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router";
import { appConfig } from "src/config";

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 8;
    background-color: ${theme.header.background};
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-left: ${theme.spacing(2)};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

const BoxLogoWrapper = styled(Box)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};

  @media (min-width: ${theme.breakpoints.values.lg}px) {
   // width: calc(${theme.sidebar.width} - ${theme.spacing(4)});
  }
    
`
);

function Header(props) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { user, logout } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const handleResetPassword = () => {
    navigate("/" + id + "/resetpassword");
  };

  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        <Box
          component="span"
          sx={{
            display: { lg: "none", xs: "inline-block" },
            pr: 1,
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButtonPrimary color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon sx={{ ...(theme.header.background === "#FFFFFF" && { color: "primary.main" }) }} />
              ) : (
                <CloseTwoToneIcon />
              )}
            </IconButtonPrimary>
          </Tooltip>
        </Box>
        <BoxLogoWrapper>
          <Logo landingURL={props.landingURL} />
        </BoxLogoWrapper>
        <Box
          component="span"
          sx={{
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          {/* <HeaderSearch /> */}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {appConfig.enableLocale && (
          <LanguageSwitcher mode={theme.colors.primary.main === theme.header.background && "dark"}></LanguageSwitcher>
        )}
        {/* <ThemeSettings mode="dark"></ThemeSettings> */}
        <Userbox
          userName={user.name}
          userRole={user.isGuestAdminOperator ? "guest_operator" : user.isGuestAdminSponsor ? "guest_sponsor" : user.role}
          email={user.email}
          logoutCallback={handleLogout}
          mode="dark"
          resetPassword={user.userType === "local"}
          handleResetPassword={handleResetPassword}
        />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
