import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import { appConfig } from '../config';

const axiosMockInstance = axios.create();
const axiosLiveInstance = axios.create();
export const axiosMockAdapterInstance = new AxiosMockAdapter(
  axiosMockInstance,
  { delayResponse: appConfig.unitTest ? 0 : 500 }
);
const mock = appConfig.mock;

/**
 * Gets random int
 * @param min
 * @param max
 * @returns random int - min & max inclusive
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  if (min > 0) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  return appConfig.unitTest || appConfig.skipError
    ? 0
    : Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getIDFromUrl(): number {
  if (appConfig.unitTest) {
    return 1;
  }
  const urlParts = window.location.href.split('/');
  const id = urlParts[urlParts.length - 1];

  return isNaN(Number(id)) || id.length > 5 ? 1 : +id;
}

export function getIDFromData(
  data: Array<any>,
  idField: string,
  extraIdField: string = ''
): number {
  if (appConfig.unitTest) {
    return 0;
  }
  const urlParts = window.location.href.split('/');
  const id = urlParts[urlParts.length - 1];
  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    if (element[idField] === id) {
      return index;
    }
    // Optioanlly, check for extraIdField
    if (extraIdField && element[extraIdField] === id) {
      return index;
    }
  }
  return 0;
}

/**
 *
 * @param {*} errList - list of possible errors
 * @param {*} key - key name in data part of response
 * @param {*} objList - list of objects in response
 * @returns HTTP status code + response in JSON
 */
export function getListResponse(errList, key, objList, includeTotal = false) {
  const err = errList[getRandomInt(0, errList.length - 1)];

  if (err !== '') {
    return [
      200,
      {
        error: err,
      },
    ];
  }

  const data = {
    data: {},
    error: '',
  };
  if (includeTotal) {
    data.data['total'] = 25;
  }
  data.data[key] = objList;
  data.data['cursor'] = Math.random().toString();
  return [200, data];
}

export default mock === 'true' ? axiosMockInstance : axiosLiveInstance;
