import { LicenseInfo } from '../contexts/CookieAuthContext';
import { getDateDiff } from './dateutils';

interface AlertInfo {
  severity?: 'error' | 'warning' | 'info';
  msg?: string;
}

export const getLicenseAlertInfo = (
  licenseInfo: LicenseInfo,
  t: any
): AlertInfo => {
  const days = getDateDiff(licenseInfo.expiry);
  const alertInfo: AlertInfo = {};
  if (days <= 30 || licenseInfo.type === 'trial') {
    let showContactAdminMsg = true;
    let msg: string;
    if (days < 0) {
      alertInfo.severity = 'error';
    } else {
      alertInfo.severity = 'warning';
    }
    if (licenseInfo.type === 'trial') {
      if (days >= 0) {
        if (days === 0) {
          alertInfo.severity = 'warning';
          msg = t('license_trial_expire_today');
        } else {
          alertInfo.severity = 'info';
          msg = t('license_trial_expiry_days', { days });
          showContactAdminMsg = false;
        }
      } else {
        msg = t('license_trial_expired_alert');
      }
    } else if (days < 0) {
      msg = t('license_subscription_expired_alert');
    } else if (days <= 30) {
      if (days === 0) {
        alertInfo.severity = 'warning';
        msg = t('license_subscription_expire_today');
      } else {
        msg = t('license_subscription_expiry_days', { days });
      }
    }
    if (showContactAdminMsg) {
      msg += ' ' + t('license_contact_admin');
    }
    alertInfo.msg = msg;
  }
  return alertInfo;
};
