/* istanbul ignore file */
// Ignoring from coverage as dummy socket connection is not possible
import { useAuth } from "@antara/shared";
import { Box } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";

const TerminalComponent = ({ device, reconnect, errorHandler }) => {
  let terminalRef = useRef(null);
  const auth = useAuth();

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  // Remove the domain part from username
  const stripUsername = (name: string) => {
    return name.split("@")[0];
  };

  const handleTerminal = useCallback(
    async (terminal, socket) => {
      // Reset terminal style and scroll position
      terminal.reset();
      terminal.scrollToBottom();

      const fitAddon = new FitAddon();
      terminal.loadAddon(fitAddon);
      terminal.open(terminalRef.current);
      fitAddon.fit();

      // Focus on the terminal when the component loads
      terminal.focus();

      await sleep(100);

      if (socket.connected) {
        terminal.write("~$ Connecting ... \n\r~$ ");
      } else {
        errorHandler();
        terminal.write("~$ Unable to connect, check if the DeviceAdmin app is running and retry ... \n\r~$ ");
      }

      terminal.onData((data) => {
        console.log("Input received:", data);
        socket.emit("data", data);
      });
    },
    [errorHandler]
  );

  const handleSocket = useCallback((terminal, socket) => {
    socket.on("connect", () => {
      socket.emit("geometry", terminal.cols, terminal.rows);
    });

    socket.on("status", (data: string) => {
      if (data !== "SSH CONNECTION ESTABLISHED") {
        terminal.write("Connection could not be established. \n\r~$ ");
        console.log("Status = ", data);
      }
    });

    socket.on("ssherror", (data: string) => {
      terminal.write("Connection could not be established. \n\r~$ ");
      terminal.write(data + "\n\r~$ ");
      console.log("SSH Error = ", data);
      terminal.write("Click 'Reconnect' to retry SSH\n\r~$ ");
    });

    socket.on("error", (err: any) => {
      console.log("Error = ", err);
    });

    socket.on("data", (data: string | Uint8Array) => {
      terminal.write(data);
      console.log("received from socket:", data);
    });
  }, []);

  useEffect(() => {
    const socket = io("ws://localhost:2222", {
      transports: ["websocket"],
      upgrade: false,
      path: "/ssh/socket.io",
      query: {
        user: stripUsername(auth.user.loginName),
        password: auth.user.deviceAdministrationCredential,
        host: device.host,
        port: device.port,
      },
    });
    const terminal = new Terminal({
      cursorBlink: true,
      cursorStyle: "underline",
    });

    handleTerminal(terminal, socket);
    handleSocket(terminal, socket);

    return () => {
      terminal.dispose();
      socket.close();
    };
  }, [device, auth.user.deviceAdministrationCredential, auth.user.loginName, handleTerminal, handleSocket, reconnect]);

  return <Box sx={{ height: "100%", mt: 1, overflow: "scroll" }} ref={terminalRef} />;
};

export default TerminalComponent;
