import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  SelectProps,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import AAIListItem from '../AAIListItem';
import { AAIIconButton, AAISelectComponent } from './AAIStyledComponent';
import Close from '@mui/icons-material/Close';
import AAISelectToolTip from './AAISelectToolTip';

export type AAISelectProps = {
  lastElement?: boolean;
  caption?: string;
  helper_text?: any;
  helper_component?: any;
  add_allowed?: boolean | string;
  add_click?: () => void;
  add_tooltip_text?: string;
  add_drawer_component?: JSX.Element;
  delete_allowed?: boolean | string;
  delete_click?: () => void;
  value_decorator?(label: any): JSX.Element;
  readOnly?: boolean;
  compact?: boolean;
  skipReadonly?: boolean;
} & SelectProps;

const AAISelect = (props: AAISelectProps) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { lastElement, value_decorator, skipReadonly, ...selectProps } = props;

  let classes: any = { mx: 2, mt: 1.5, mb: 0, pr: 4, pb: 1 };

  const UpdateProps = () => {
    delete selectProps.compact;
    if (props.delete_allowed && props.add_allowed && props.value) {
      selectProps.endAdornment = (
        <>
          <AAIIconButton
            sx={{
              display: selectProps.value ? '' : 'none',
              marginRight: '24px',
            }}
            onClick={selectProps.delete_click}
          >
            <Close fontSize="medium" color="error" />
          </AAIIconButton>
          <AAISelectToolTip
            add_tooltip_text={selectProps.add_tooltip_text}
            add_click={selectProps.add_click}
          />
        </>
      );
      updateWarningProps(selectProps);
    } else if (props.delete_allowed && props.value) {
      selectProps.endAdornment = (
        <IconButton
          sx={{ display: selectProps.value ? '' : 'none', marginRight: '8px' }}
          onClick={selectProps.delete_click}
        >
          <Close fontSize="medium" color="error" />
        </IconButton>
      );
      selectProps.delete_allowed = 'true';
      delete selectProps.delete_click;
    } else if (props.add_allowed) {
      selectProps.endAdornment = (
        <AAISelectToolTip
          add_tooltip_text={selectProps.add_tooltip_text}
          add_click={selectProps.add_click}
        />
      );
      updateWarningProps(selectProps);
    }
  };

  const updateWarningProps = (selectProps) => {
    selectProps.add_allowed = 'true';
    selectProps.delete_allowed = 'true';
    delete selectProps.add_click;
    delete selectProps.delete_click;
  };

  UpdateProps();

  const isReadonly = () => {
    return !props.skipReadonly && (auth.user.readOnly || props.readOnly);
  };

  if (props.compact) {
    classes = {};
  }

  return (
    <>
      {isReadonly() ? (
        <AAIListItem
          label={props.label}
          value={props.value}
          lastElement={props.lastElement}
          labelDecorator={props.value_decorator}
        />
      ) : (
        <FormControl fullWidth sx={classes}>
          <InputLabel id={props.labelId}>
            {t(props.caption ? props.caption : (props.label as string))}
          </InputLabel>
          <AAISelectComponent {...selectProps} />
          {props.helper_text && !auth.user.readOnly && (
            <FormHelperText error={props.error}>
              {t(props.helper_text)}
            </FormHelperText>
          )}
          {props.helper_component && !auth.user.readOnly && (
            <FormHelperText error={props.error}>
              {props.helper_component}
            </FormHelperText>
          )}
        </FormControl>
      )}
      {selectProps.add_drawer_component && selectProps.add_drawer_component}
    </>
  );
};

export default AAISelect;
