import { useTranslation } from 'react-i18next';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { AAIIconButton } from './AAIStyledComponent';

interface ToolTipProps {
  add_tooltip_text: string;
  add_click: () => void;
}

const AAISelectToolTip = ({ add_tooltip_text, add_click }: ToolTipProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(add_tooltip_text)} arrow>
      <AAIIconButton onClick={add_click} color="primary">
        <AddCircleOutline />
      </AAIIconButton>
    </Tooltip>
  );
};
export default AAISelectToolTip;
