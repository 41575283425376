import { Grid } from '@mui/material';
import React, { memo } from 'react';
import { FC, useState } from 'react';
import { ScrollableSelectorTab } from '../ScrollableSelectorTab';
import { ScrollableSelectorConfig } from './ScrollableSelectorConfig';
import { getObjectIndex } from '../../../utils/arrayutil';

interface ScrollableSelectorTemplateProps {
  selectedRows: any[];
  updateSelectedRows: any;
  config: ScrollableSelectorConfig;
  setFieldValue?(field, value);
}

const ScrollableSelectorTemplate: FC<ScrollableSelectorTemplateProps> = memo(
  (props: ScrollableSelectorTemplateProps) => {
    const [tabIndex, setTabIndex] = useState(
      props.config.defaultTabIndex ? props.config.defaultTabIndex : 0
    );
    const switchTab = (index) => {
      setTabIndex(index);
      if (props.config.tabCallback) {
        props.config.tabCallback(index);
      }
    };
    const [selectedRows, setSelectedRows] = useState(
      props.selectedRows ? props.selectedRows : []
    );
    const addRow = (row) => {
      selectedRows.push(row);
      setSelectedRows(selectedRows);
      if (props.setFieldValue) {
        props.setFieldValue('dirty', true);
      }
      props.updateSelectedRows(selectedRows, 'add');
    };

    const removeRow = (row) => {
      let rowIndex = getObjectIndex(row, selectedRows, props.config.rowKey);
      if (rowIndex !== -1) {
        selectedRows.splice(rowIndex, 1);
      }
      setSelectedRows(selectedRows);
      if (props.setFieldValue) {
        props.setFieldValue('dirty', true);
      }
      props.updateSelectedRows(selectedRows, 'remove');
    };

    return (
      <Grid
        sx={{
          px: 2,
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <ScrollableSelectorTab
            switchTab={switchTab}
            tabIndex={tabIndex}
            config={props.config}
            selectedRows={props.selectedRows}
            addRow={addRow}
            removeRow={removeRow}
          />
        </Grid>
      </Grid>
    );
  }
);

export default ScrollableSelectorTemplate;
