import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/PageTitle';
import {
  getDateTimeFromSelector,
  getFormattedRegularCurrentDateTimeText,
  getFormattedRegularDateText,
} from '../../utils/dateutils';
import { CrudConfig } from './CrudConfig';
import Results from './Results';
function CrudTemplate(props: CrudConfig) {
  const { t } = useTranslation();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [subHeading, setSubHeading] = useState(props.pageSubheading);

  useEffect(() => {
    const dateSelectorCallback = (dateObj) => {
      props.listApiRequestFields[dateObj.field] = getDateTimeFromSelector(
        dateObj.value,
        dateObj.date
      );
      let currentPrefs = JSON.parse(localStorage.getItem('prefs'));
      if (!currentPrefs) {
        currentPrefs = {};
      }
      if (!currentPrefs[props.resourceName]) {
        currentPrefs[props.resourceName] = {};
      }
      currentPrefs[props.resourceName][dateObj.field] = {
        value: dateObj.value,
        date: dateObj.date,
      };
      localStorage.setItem('prefs', JSON.stringify(currentPrefs));
      setForceUpdate((forceUpdate) => !forceUpdate);
    };
    if (props.dateSelectorConfig) {
      props.dateSelectorConfig.dateChangeCallback = dateSelectorCallback;
      let currentPrefs = JSON.parse(localStorage.getItem('prefs'));
      if (currentPrefs) {
        let resPref = currentPrefs[props.resourceName];
        if (resPref) {
          let datePref = resPref[props.dateSelectorConfig.dateFieldName];
          if (datePref) {
            props.dateSelectorConfig.defaultOption = datePref['value'];
            props.listApiRequestFields[props.dateSelectorConfig.dateFieldName] =
              getDateTimeFromSelector(datePref['value'], datePref['date']);
          }
        }
      }
    }
  }, [props.dateSelectorConfig, props.listApiRequestFields]);

  useEffect(() => {
    if (props.saveDataOnRouteChange) {
      const cacheTime = localStorage.getItem(
        'prevDataTime_' + props.resourceName
      );
      let time: string;
      if (
        cacheTime &&
        localStorage.hasOwnProperty('prevData_' + props.resourceName)
      ) {
        time = getFormattedRegularDateText(cacheTime);
      } else {
        localStorage.setItem(
          'prevDataTime_' + props.resourceName,
          new Date().toString()
        );

        time = getFormattedRegularCurrentDateTimeText();
      }
      setSubHeading(t(props.pageSubheading) + ' as on ' + time);
    }

    setForceUpdate((forceUpdate) => !forceUpdate);
  }, [props.forceUpdate]);

  const refreshHandler = () => {
    localStorage.setItem(
      'prevDataTime_' + props.resourceName,
      new Date().toString()
    );
    setSubHeading(
      t(props.pageSubheading) +
        ' as on ' +
        getFormattedRegularCurrentDateTimeText()
    );
  };

  return (
    <>
      {!props.showCustomHeader && (
        <>
          <Helmet>
            <title>{t(props.pageHeading)}</title>
          </Helmet>
          <PageTitle
            heading={props.pageHeading}
            icon={props.pageIcon}
            subHeading={subHeading}
            actions={props.pageActions}
            dateSelector={props.dateSelectorConfig}
          ></PageTitle>
        </>
      )}

      <Grid
        sx={{
          px: props.compact ? 0 : 2,
        }}
        container
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Results
            crudConfig={props}
            forceUpdate={forceUpdate}
            onRefresh={props.saveDataOnRouteChange && refreshHandler}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CrudTemplate;
