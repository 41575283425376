import { useAuth } from "@antara/shared";
import { Box, Container, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import image from "src/assets/images/shield.svg";

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
);

const LicenseWrapper = (props) => {
  const { t }: { t: any } = useTranslation();
  const allowed = useAuth().user.onboardPortalAllowed;
  const [showLicenseInfo, setShowLicenseInfo] = useState<boolean>();

  useEffect(() => {
    if (allowed) {
      setShowLicenseInfo(false);
    } else {
      setShowLicenseInfo(true);
    }
  }, [allowed]);

  return (
    <>
      {showLicenseInfo === false && <>{props.children}</>}
      {showLicenseInfo === true && (
        <>
          <Helmet>
            <title>{t("Error")}</title>
          </Helmet>
          <MainContent>
            <Container maxWidth="md">
              <Box textAlign="center">
                <img alt="404" height={180} src={image} />
                <Typography
                  variant="h3"
                  sx={{
                    mt: 3,
                    mb: 1,
                  }}
                  fontWeight={400}
                >
                  {t("license_expired_msg")}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    mt: 1,
                    mb: 1,
                  }}
                  fontWeight={400}
                >
                  {t("contact_admin")}
                </Typography>
              </Box>
            </Container>
          </MainContent>
        </>
      )}
    </>
  );
};

export default LicenseWrapper;
