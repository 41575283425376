import { Box, Divider, Drawer, styled, useTheme } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AvaButton from '../../components/AvaButton';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import SidebarMenu from '../../components/SidebarMenu';
import { appConfig } from '../../config';
import { SidebarContext } from '../../contexts/SidebarContext';
import { MenuItems, SidebarLayoutProps } from '../base';
import ZoneSelector from '../zoneselector';
import BottomBar from './BottomBar';
import TopbarAlert from '../../components/TopbarAlert';

const MainWrapper = styled(Box)(
  () => `
      flex: 1;
      display: flex;
      height: 100%;

      .footer-wrapper {
        overflow: hidden;
        height: 0;
        box-shadow: none;
        border: 0;
      }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-bottom: ${theme.header.height};
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 5;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 2)};
`
);

const BottomNavigationLayout: FC<SidebarLayoutProps> = ({
  menu = [],
  landingURL = '',
}) => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const [menuItems, setMenuItems] = useState<MenuItems[]>();
  const urlParams = new URLSearchParams(window.location.search);
  const isPrint = urlParams.get('print');

  useEffect(() => {
    for (const level1 of menu) {
      if (level1.items && level1.items.length > 0) {
        for (const level2 of level1.items) {
          if (level2.items && level2.items.length > 0) {
            for (const level3 of level2.items) {
              level3.icon = null;
            }
          }
        }
      }
    }
    setMenuItems(menu);
  }, [menu]);
  return (
    <>
      {isPrint !== 'true' ? (
        <>
          <MainWrapper>
            <MainContent
              sx={{
                '.MuiDrawer-hd': {
                  '& .MuiDrawer-paper': {
                    top: 0,
                    width: `calc(320px + ${theme.spacing(4)})`,
                    position: 'fixed',
                  },
                },
                '.MuiDrawer-fm': {
                  '& .MuiDrawer-paper': {
                    top: 0,
                    position: 'fixed',
                  },
                },
                '.Mui-FixedWrapper': {
                  '.MuiDrawer-root.MuiDrawer-docked': {
                    '.MuiPaper-root': {
                      left: 0,
                      top: 0,
                    },
                  },
                },
              }}
            >
              <Scrollbar>
                <TopbarAlert />
                <Outlet />
              </Scrollbar>
              <AvaButton isBottomNav={true} />
            </MainContent>
            {menuItems && (
              <BottomBar menuItems={menuItems} landingURL={landingURL} />
            )}
          </MainWrapper>
          <Drawer
            sx={{
              display: { lg: 'none', xs: 'inline-block' },
            }}
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={sidebarToggle}
            onClose={closeSidebar}
            variant="temporary"
            elevation={9}
          >
            <SidebarWrapper>
              <Scrollbar>
                <TopSection>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Logo
                      landingURL={landingURL}
                      sidebar
                      title={appConfig.appname ? appConfig.appname : ''}
                    />
                  </Box>
                  <Divider />
                </TopSection>
                <Box sx={{ mx: 1, mt: 1 }}>
                  <ZoneSelector />
                </Box>
                <SidebarMenu menuItems={menu} />
              </Scrollbar>
            </SidebarWrapper>
          </Drawer>
        </>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default BottomNavigationLayout;
