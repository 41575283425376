import React, { FC } from 'react'
import QRCode from 'react-qr-code';

export interface SsidQrCodeProps {
    authentication, ssidName, password: string;
}
export const SsidQrCode: FC<SsidQrCodeProps> = ({authentication, ssidName, password}) => {
    let qrText = `WIFI:T:${authentication};S:${ssidName};P:${password};`
    return (
        <QRCode value={qrText} />
    );
}
