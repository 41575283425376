import ErrorIcon from '@mui/icons-material/Error';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../contexts/GlobalContext';
export type Entities = {
  entityName: string;
  errorDetail: string;
};

export type ErrorData = {
  error: string;
  count: number;
  entities: Entities[];
};

export type ErrorResp = {
  totalCount: number;
  errCount: number;
  errors: ErrorData[];
};

export const getDependencyError = (errorDetails, t) => {
  if (errorDetails) {
    let extractedString = errorDetails.match(/segment=([^ ]*)/i);
    if (extractedString && extractedString.length > 0) {
      let sectionKey = extractedString[0].split('=')[0];
      let sectionValue = extractedString[0].split('=')[1];
      if (sectionKey === 'segment') {
        return t('segment_in_use', { name: sectionValue });
      }
    }
    return t(errorDetails);
  }
  return '';
};

export default function DeleteErrorListPopup({
  errorResp,
  closeCallback,
  resourceName,
}: {
  errorResp: ErrorResp;
  closeCallback();
  resourceName: string;
}) {
  const [open, setOpen] = useState(true);
  const { t }: { t: any } = useTranslation();
  const { mobileView } = useContext(GlobalContext);
  const theme = useTheme();

  const handleClose = () => {
    closeCallback();
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="delete-error-list"
        maxWidth={mobileView ? 'xs' : 'sm'}
        fullWidth
      >
        <DialogTitle
          id="delete_dialog_title"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ErrorIcon color="error" sx={{ mr: 1 }} />
          {t(`${resourceName}_bulk_delete_err`, {
            errCount: errorResp.errCount,
            totalCount: errorResp.totalCount,
            count: errorResp.totalCount,
          })}
        </DialogTitle>
        <DialogContent dividers={true}>
          <Box ref={descriptionElementRef} tabIndex={-1}>
            {errorResp.errors &&
              errorResp.errors.map((val, index) => (
                <Box
                  key={index}
                  sx={{ mb: index < errorResp.errors.length - 1 ? 1 : 0 }}
                >
                  <Typography sx={{ mb: 0.5 }} variant="h6">
                    {val.error === 'entity_referenced_by_other_entities'
                      ? val.count <= 10
                        ? t('entity_in_use_msg', { count: val.count })
                        : t(`${resourceName}_bulk_delete_err_first_ten`, {
                            count: val.count,
                          })
                      : val.count <= 10
                      ? t('entity_delete_internal_err', { count: val.count })
                      : t(`${resourceName}_bulk_delete_internal_err`, {
                          count: val.count,
                        })}
                  </Typography>
                  {(val.error === 'entity_referenced_by_other_entities' ||
                    val.count <= 10) && <Divider />}
                  <Box>
                    {val.entities &&
                      (val.error === 'entity_referenced_by_other_entities' ||
                        val.count <= 10) &&
                      val.entities
                        .slice(0, val.count > 10 ? 10 : val.count)
                        .map((err, i) => (
                          <Box
                            key={i}
                            p={1}
                            sx={{
                              background:
                                i % 2 === 1
                                  ? theme.colors.primary.lighter
                                  : theme.palette.mode === 'light'
                                  ? theme.colors.alpha.white[100]
                                  : theme.colors.alpha.black[1],
                            }}
                          >
                            {err.entityName}
                            {err.errorDetail && <>:&nbsp;</>}
                            {getDependencyError(err.errorDetail, t)}
                          </Box>
                        ))}
                  </Box>
                </Box>
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
