import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useEffect, useRef, useState } from 'react';
import AAITextField from '../AAITextField';
import { SxProps, Theme } from '@mui/system';

function stringToNumber(value: string): number {
  const num = parseFloat(value);
  if (!isNaN(num)) {
    return num;
  }
  return 0;
}

const TimeValidityInput = ({
  label,
  initValue,
  onChange,
  helperText,
  error,
  onlyShowHrs,
  width,
  sx,
  lastElement,
}: {
  label: string;
  initValue: number;
  onChange(newValue: number);
  helperText?: string;
  error: boolean;
  onlyShowHrs?: boolean;
  width?: string;
  sx?: SxProps<Theme>;
  lastElement?: boolean;
}) => {
  const [value, setValue] = useState('');
  const [unit, setUnit] = useState('hours');
  const initialized = useRef<boolean>();
  const handleValueChange = (event) => {
    let inputValue = stringToNumber(event.target.value);
    setValue(inputValue.toString());
    if (unit === 'days') {
      inputValue = inputValue * 24;
    }
    onChange(inputValue);
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (
        unit === 'hours' &&
        initValue !== 0 &&
        initValue % 24 === 0 &&
        !onlyShowHrs
      ) {
        setValue((initValue / 24).toString());
        setUnit('days');
      } else {
        setValue(initValue.toString());
      }
    }
  }, [initValue, unit]);

  const handleUnitChange = (event) => {
    const selectedUnit = event.target.value;
    setUnit(selectedUnit);
    let v = stringToNumber(value);
    if (selectedUnit === 'days') {
      if (v > 0) {
        v = v * 24;
      }
    }
    onChange(v);
  };

  return (
    <AAITextField
      id="time-validity-input"
      label={label}
      variant="outlined"
      compact
      sx={{ width: width ? width : '300px', ...sx }}
      value={value === '0' ? '' : value}
      onChange={handleValueChange}
      error={error}
      valueSuffix={unit}
      helperText={helperText}
      lastElement={lastElement}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {onlyShowHrs ? (
              <>Hours</>
            ) : (
              <Select
                value={unit}
                onChange={handleUnitChange}
                variant="standard"
                disableUnderline
                inputProps={{
                  sx: {
                    '&:focus': {
                      backgroundColor: 'transparent !important', // Set the background color to transparent on focus
                    },
                  },
                }}
              >
                <MenuItem value="hours">Hours</MenuItem>
                <MenuItem value="days">Days</MenuItem>
              </Select>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default TimeValidityInput;
