import { AuthProvider, SidebarProvider } from "@antara/shared";
import ReactDOM from "react-dom";
import App from "./App";
// import 'src/utils/chart';
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import "nprogress/nprogress.css";
import { appConfig } from "./config";

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter basename={appConfig.basename || "/"}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
