import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Button } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
export interface CopyTextProps {
  text: string;
  copyTxt: string;
  copiedTxt: string;
  height: string;
  useBtn: boolean;
  copyFnc?: Function;
  id?: string;
}
export const CopyText: FC<CopyTextProps> = ({
  text,
  copyTxt,
  copiedTxt,
  height = '1em',
  useBtn,
  copyFnc,
  id = '',
}) => {
  const [isCopied, setCopied] = useState<boolean>(false);

  const copyAction = () => {
    if (copyFnc) {
      copyFnc();
    }
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  useEffect(() => {
    setCopied(false);
  }, [text]);

  return (
    <>
      {useBtn ? (
        <CopyToClipboard text={text}>
          <Button
            id={id ? `id_button_${id}_copy` : 'id_button_copy'}
            size="small"
            onClick={() => copyAction()}
            startIcon={<ContentCopyOutlinedIcon />}
            variant="outlined"
          >
            {isCopied ? copiedTxt : copyTxt}
          </Button>
        </CopyToClipboard>
      ) : (
        <CopyToClipboard text={text}>
          <ContentCopyOutlinedIcon
            id={id ? `id_button_${id}_copy` : 'id_button_copy'}
            fontSize="small"
            sx={{ cursor: 'pointer', height: { height } }}
            onClick={() => copyAction()}
          ></ContentCopyOutlinedIcon>
        </CopyToClipboard>
      )}
      {!useBtn && isCopied && copiedTxt ? (
        <span>&nbsp;&nbsp;{copiedTxt}</span>
      ) : null}
    </>
  );
};
