import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DateTimePicker from '@mui/lab/DateTimePicker';
import {
  Box,
  FormControl,
  Grid,
  GridSize,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { appConfig } from '../../config';
import { GlobalContext } from '../../contexts/GlobalContext';

export interface FilterConfig {
  filterType: 'text' | 'select' | 'datetime';
  field?: string;
  xs: GridSize;
  md: GridSize;
  label?: string;
  initVal?: any;
  hide?: boolean;
  dropdownOptions?: any;
  pageFilter?: boolean;
}

export interface FilterConfigs {
  [key: string]: FilterConfig[];
}

export interface FilterProps {
  filters: FilterConfig[];
  size?: 'small' | 'medium';
  callbackFn?: Function;
  background?: string;
  noBorder?: boolean;
  sx?: SxProps<Theme>;
  deleteAll?: boolean;
  deleteCallback?();
  exportAll?: boolean;
  exportAllCallback?(boolean);
  count?: number;
}

export default function Filter({
  filters,
  callbackFn,
  size,
  background,
  noBorder,
  sx,
  deleteAll,
}: FilterProps) {
  const theme = useTheme();
  const { mobileView, tabView } = useContext(GlobalContext);
  const [searchTerm, setSearchTerm] = useState<any>();
  const [fieldVal, setFieldVal] = useState<any>({});
  const [queryValue, setQueryValue] = useState<string>();
  const [highlights, setHighlights] = useState<boolean[]>([]);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    if (filters) {
      // setQueryValue(undefined);
      setKey(Math.random());
    }
    if (!appConfig.unitTest) {
      let tID: any;
      const timeoutId = setTimeout(() => {
        if (filters && filters.length > 0) {
          let h: boolean[] = [];
          for (const iterator of filters) {
            if (iterator.initVal) {
              h.push(true);
            } else {
              h.push(false);
            }
          }
          setHighlights(h);

          tID = setTimeout(() => {
            let h: boolean[] = [...highlights];
            for (let index = 0; index < h.length; index++) {
              h[index] = false;
            }
            setHighlights(h);
          }, 1000);
        }
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
        if (t) {
          clearTimeout(tID);
        }
      };
    } else {
      return () => {};
    }
  }, [filters]);

  useEffect(() => {
    if (!appConfig.unitTest) {
      const delayDebounceFn = setTimeout(() => {
        if (searchTerm) {
          callbackFn({ field: searchTerm.field, value: searchTerm.value });
        }
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else {
      if (searchTerm) {
        callbackFn({ field: searchTerm.field, value: searchTerm.value });
      }
      return () => {};
    }
  }, [searchTerm]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQueryValue(event.target.value);
    setSearchTerm({
      field: event.target.name,
      value: event.target.value.trim(),
    });
    event.persist();
  };

  const handleDropdownChange = (event: SelectChangeEvent): void => {
    callbackFn({ field: event.target.name, value: event.target.value });
  };

  const handleTimeChange = (fieldName: string, newValue: Date | null) => {
    callbackFn({ field: fieldName, value: newValue });
    let newVal = {};
    newVal[fieldName] = newValue;
    setFieldVal(newVal);
  };

  const clearQueryFilter = () => {
    setQueryValue('');
    setSearchTerm({ field: 'query', value: '' });
  };

  const showClearButton = (filter) => {
    if (queryValue != undefined) {
      return queryValue !== '';
    }
    return filter.initVal;
  };

  const getQueryValue = (filter) => {
    if (queryValue != undefined) {
      return queryValue;
    }
    return filter.initVal ? filter.initVal : '';
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      key={key}
      sx={{
        display: 'flex',
        justifyContent: deleteAll ? 'flex-start' : 'center',
        alignItems: 'center',
        ...(theme.palette.mode === 'light'
          ? { backgroundColor: background || '#FBFBF9' }
          : { backgroundColor: theme.colors.alpha.black[1] }),
        ...sx,
        pb: tabView || mobileView ? 0.5 : 0,
      }}
      borderBottom={
        noBorder
          ? 'none'
          : `${
              theme.palette.mode === 'light'
                ? '1px solid lightgray'
                : `1px solid ${theme.colors.borders[1]}`
            }`
      }
    >
      <>
        {filters &&
          filters.map((filter, i) => {
            if (filter.hide) {
              return <React.Fragment key={i} />;
            }
            return (
              <Grid
                key={i}
                item
                xs={filter.xs}
                md={filter.md}
                sx={{
                  ...(filter.filterType === 'select' && { py: 2, px: 1 }),
                }}
              >
                {filter.filterType === 'text' && (
                  <Box px={1} py={2}>
                    <TextField
                      id={'id_filter_' + filter.field + '_input'}
                      sx={{
                        m: 0,
                        backgroundColor:
                          theme.palette.mode === 'light'
                            ? 'white'
                            : `${theme.colors.alpha.black[5]}`,
                        ...(highlights &&
                          highlights[i] && {
                            border: '2px solid',
                            borderColor: 'primary.main',
                            borderRadius: 1,
                          }),
                      }}
                      name={filter.field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchTwoToneIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={() => {
                                clearQueryFilter();
                              }}
                              id={'id_filter_' + filter.field + '_input_clear'}
                            >
                              {showClearButton(filter) && <HighlightOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleQueryChange}
                      placeholder={t(filter.label)}
                      value={getQueryValue(filter)}
                      size={size || 'small'}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Box>
                )}
                {filter.filterType === 'select' && (
                  <FormControl fullWidth>
                    <InputLabel id="data-select-label">
                      {t(filter.label)}
                    </InputLabel>
                    <Select
                      id={'id_filter_' + filter.field + '_select'}
                      sx={{
                        m: 0,
                        verticalAlign: 'center',
                        backgroundColor:
                          theme.palette.mode === 'light'
                            ? 'white'
                            : `${theme.colors.alpha.black[5]}`,
                        ...(highlights &&
                          highlights[i] && {
                            border: '2px solid',
                            borderColor: 'primary.main',
                            borderRadius: 1,
                          }),
                      }}
                      labelId="data-status-label"
                      name={filter.field}
                      size={size || 'small'}
                      label={t(filter.label)}
                      onChange={handleDropdownChange}
                      defaultValue={
                        filter.initVal === 0 ? 0 : filter.initVal || 'any'
                      }
                    >
                      {filter.dropdownOptions.map((item) => {
                        return (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            id={
                              'id_filter_' +
                              filter.field +
                              '_select_option_' +
                              item.value
                            }
                          >
                            {t(item.label)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
                {filter.filterType === 'datetime' && (
                  <Box
                    sx={{ mt: 2, px: 1, backgroundColor: 'white' }}
                    id={'id_filter_' + filter.field + '_datetime'}
                  >
                    <DateTimePicker
                      label={filter.label}
                      value={
                        fieldVal[filter.field]
                          ? fieldVal[filter.field]
                          : new Date()
                      }
                      onChange={(newVal) =>
                        handleTimeChange(filter.field, newVal)
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Box>
                )}
              </Grid>
            );
          })}
      </>
    </Grid>
  );
}
