import { axiosMockAdapterInstance, getListResponse, getRandomInt } from "@antara/shared";
import type { Client } from "src/models/client";

export const clients: Client[] = [
  {
    clientGroupID: 1,
    clientID: "client1",
    mac: "00005e0053af",
    description: "Macbook Pro",
    status: "enabled",
    pskPassphrase: "",
    orgID: "mockorg",
    userID: "test-user",
    username: "test-user",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 2,
    clientID: "client2",
    mac: "10f0.6e00.5122",
    description: "Android",
    status: "disabled",
    pskPassphrase: "ThisIsAntara",
    orgID: "mockorg",
    userID: "test-user",
    username: "test-user",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 3,
    clientID: "client3",
    mac: "01-02-2e-00-53-91",
    description: "Xbox",
    status: "disabled",
    pskPassphrase: "ThisIsAgni",
    orgID: "mockorg",
    userID: "test-user",
    username: "Rohit",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 4,
    clientID: "client4",
    mac: "50 10 53 00 52 ee",
    description: "iPad",
    status: "enabled",
    pskPassphrase: "ThisIsAgni",
    orgID: "mockorg",
    userID: "test-user",
    username: "Kohli",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 0,
    clientID: "client5",
    mac: "56:71:5e:30:53:83",
    description: "iPhone",
    status: "disabled",
    pskPassphrase: "ThisIsBatman",
    orgID: "mockorg",
    userID: "test-user",
    username: "Shastri",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 0,
    clientID: "client6",
    mac: "07:56:5e:11:54:aa",
    description: "My Phone",
    status: "enabled",
    pskPassphrase: "ThisIsBatman",
    orgID: "mockorg",
    userID: "test-user",
    username: "Chahal",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 0,
    clientID: "client7",
    mac: "11:20:3e:40:55:6f",
    description: "Printer",
    status: "enabled",
    pskPassphrase: "ThisIsUs",
    orgID: "mockorg",
    userID: "test-user",
    username: "Sharma",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 0,
    clientID: "client8",
    mac: "01:01:51:01:51:a1",
    description: "Desktop",
    status: "disabled",
    pskPassphrase: "ThisIsUs",
    orgID: "mockorg",
    userID: "test-user",
    username: "Virat",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 0,
    clientID: "client9",
    mac: "01:01:51:01:51:a1",
    description: "Desktop",
    status: "disabled",
    pskPassphrase: "ThisIsUs",
    orgID: "mockorg",
    userID: "test-user",
    username: "Virat",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 0,
    clientID: "client10",
    mac: "01:01:51:01:51:a1",
    description: "Desktop",
    status: "disabled",
    pskPassphrase: "ThisIsUs",
    orgID: "mockorg",
    userID: "test-user",
    username: "Virat",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 0,
    clientID: "client11",
    mac: "01:01:51:01:51:a1",
    description: "Desktop",
    status: "disabled",
    pskPassphrase: "ThisIsUs",
    orgID: "mockorg",
    userID: "test-user",
    username: "Virat",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 0,
    clientID: "client12",
    mac: "01:01:51:01:51:a1",
    description: "Desktop",
    status: "disabled",
    pskPassphrase: "ThisIsUs",
    orgID: "mockorg",
    userID: "test-user",
    username: "Virat",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    clientGroupID: 0,
    clientID: "client13",
    mac: "01:01:51:01:51:a1",
    description: "Desktop",
    status: "disabled",
    pskPassphrase: "ThisIsUs",
    orgID: "mockorg",
    userID: "test-user",
    username: "Virat",
    macVendor: "Dell Inc",
    deviceType: "Comnputer",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const clientgroups = [
  {
    id: 1,
    name: "cg1",
    groupPSKEnabled: true,
    groupPSKPassphrase: "test1",
  },
  {
    id: 2,
    name: "cg2",
    groupPSKEnabled: true,
    groupPSKPassphrase: "test2",
  },
  {
    id: 3,
    name: "cg3",
  },
];

export const cgnetworks = [
  {
    id: 10,
    name: "cgn5",
    authType: "psk",
    ssid: "ssid10",
    connType: "wireless",
  },
  {
    id: 11,
    name: "cgn6",
    authType: "psk",
    ssid: "ssid11",
    connType: "wireless",
  },
  {
    id: 5,
    name: "cgn1",
    authType: "mac_auth",
    ssid: "",
    connType: "wired",
  },
  {
    id: 6,
    name: "cgn2",
    authType: "mac_auth",
    ssid: "",
    connType: "wired",
  },
  {
    id: 7,
    name: "cgn3",
    authType: "cert",
    ssid: "ssid3",
    connType: "wireless",
  },
];

export const networks = [
  {
    id: 1,
    authType: "psk",
    ssid: "ssid8",
    connType: "wireless",
  },
  {
    id: 2,
    authType: "psk",
    ssid: "ssid5",
    connType: "wireless",
  },
  {
    id: 3,
    authType: "cert",
    ssid: "ssid6",
  },
];

export const mockClients = () => {
  axiosMockAdapterInstance.onPost("/api/me.clientGroup.client.list").reply(() => {
    const errorList = [""];
    return getListResponse(errorList, "clients", clients);
  });

  axiosMockAdapterInstance.onPost("/api/me.clientGroup.list").reply(() => {
    const errorList = [""];
    const err = errorList[getRandomInt(0, errorList.length - 1)];

    if (err !== "") {
      return [200, { error: err }];
    }

    const data = { data: { clientGroups: clientgroups }, error: "" };
    return [200, data];
  });

  axiosMockAdapterInstance.onPost("/api/me.clientGroup.client.passphrase.generate").reply(() => {
    const errorList = [""];

    return [
      200,
      {
        data: { pskPassphrase: "aw9eq92u90uw9fhsg09weRad11" },
        error: errorList[getRandomInt(0, errorList.length - 1)],
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.clientGroup.network.list").reply(() => {
    const errorList = [""];
    const err = errorList[getRandomInt(0, errorList.length - 1)];

    if (err !== "") {
      return [200, { error: err }];
    }

    const data = { data: { networks: cgnetworks }, error: "" };
    return [200, data];
  });

  axiosMockAdapterInstance.onPost("/api/me.user.network.list").reply(() => {
    const errorList = [""];
    const err = errorList[getRandomInt(0, errorList.length - 1)];

    if (err !== "") {
      return [200, { error: err }];
    }

    const data = { data: { networks: networks }, error: "" };
    return [200, data];
  });

  axiosMockAdapterInstance.onPost("/api/me.clientGroup.client.add").reply(() => {
    const errorList = [""];
    const err = errorList[getRandomInt(0, errorList.length - 1)];

    if (err !== "") {
      return [200, { error: err }];
    }

    const data = {
      data: {
        clientID: "This-is-my-cliendID",
        pskPassphrase: "ThisisnewPSK",
      },
      error: "",
    };
    return [200, data];
  });

  axiosMockAdapterInstance.onPost("/api/me.client.update").reply(() => {
    const errorList = ["", "user_client_does_not_exist", "internal_error"];

    return [
      200,
      {
        error: errorList[getRandomInt(0, errorList.length - 1)],
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.clientGroup.client.update").reply(() => {
    const errorList = ["", "internal_error"];

    return [
      200,
      {
        error: errorList[getRandomInt(0, errorList.length - 1)],
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.client.delete").reply(() => {
    const errorList = ["", "user_client_does_not_exist", "internal_error"];

    return [
      200,
      {
        error: errorList[getRandomInt(0, errorList.length - 1)],
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.clientGroup.client.delete").reply(() => {
    const errorList = ["", "internal_error"];

    return [
      200,
      {
        error: errorList[getRandomInt(0, errorList.length - 1)],
      },
    ];
  });
};
