import ErrorIcon from '@mui/icons-material/Error';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormattedLogFullDate } from '../../utils/dateutils';

const AvatarWrapperError = styled(Avatar)(
  ({ theme }) => `
      background-color: #FFFFFF;
      color:  ${theme.colors.error.main};
      height: 20px;
`
);

export interface LogEntry {
  payload: string;
  severity: string;
  timestamp: string;
}

export type LogFooter = 'refresh' | 'end' | 'showmore';
export interface ScrollableLogProps {
  logs: LogEntry[];
  logLoaded: boolean;
  caption?: string;
  showCollapse?: boolean;
  footer: LogFooter;
  showMoreCallback?: Function;
  fetchDataByChunks?: boolean;
  nextLogLoaded?: boolean;
}

export const ScrollableLog: FC<ScrollableLogProps> = (props) => {
  const {
    logs,
    logLoaded,
    caption,
    showCollapse,
    footer,
    showMoreCallback,
    nextLogLoaded,
  } = props;
  const { t } = useTranslation();
  const [showLogs, setShowLogs] = useState(true);
  const [stickyHeader, setStickyHeader] = useState(false);
  let offsetTop: number = 0;

  useEffect(() => {
    window.onscroll = () => {
      let header = document.getElementById('header');
      if (offsetTop === 0) {
        offsetTop = header.offsetTop;
      }
      if (window.pageYOffset > offsetTop) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    };
  }, []);

  const onButtonClick = () => {
    setShowLogs(!showLogs);
  };

  const onEditClicked = () => {
    window.scrollTo(0, 0);
  };

  const showMoreHandler = () => {
    showMoreCallback();
  };

  const LoadingSkeleton = () => (
    <Box>
      <Skeleton
        sx={{ my: 1, ml: 4, mr: 2 }}
        variant="rectangular"
        height={20}
      />
      <Divider />
      <Skeleton
        sx={{ my: 1, ml: 4, mr: 2 }}
        variant="rectangular"
        height={20}
      />
      <Divider />
      <Skeleton
        sx={{ my: 1, ml: 4, mr: 2 }}
        variant="rectangular"
        height={20}
      />
      <Divider />
      <Skeleton
        sx={{ my: 1, ml: 4, mr: 2 }}
        variant="rectangular"
        height={20}
      />
      <Divider />
      <Skeleton
        sx={{ my: 1, ml: 4, mr: 2 }}
        variant="rectangular"
        height={20}
      />
      <Divider />
      <Skeleton
        sx={{ my: 1, ml: 4, mr: 2 }}
        variant="rectangular"
        height={20}
      />
    </Box>
  );

  return (
    <Card sx={{ mx: 2, my: 1 }}>
      <Box
        display="flex"
        id="header"
        alignItems="center"
        justifyContent={stickyHeader ? 'start' : 'space-between'}
        sx={{
          background: '#DCDCDC',
          ...(stickyHeader && {
            position: 'fixed',
            top: '70px',
          }),
          width: '100%',
          zIndex: 5,
          p: 2,
        }}
      >
        <Typography variant="h5" lineHeight={1}>
          {caption}
        </Typography>
        {stickyHeader && (
          <Button
            size="small"
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={onEditClicked}
          >
            Edit Filters
          </Button>
        )}
        {showCollapse && (
          <Button
            size="small"
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={onButtonClick}
          >
            {showLogs ? t('Hide Logs') : t('Show Logs')}
          </Button>
        )}
      </Box>
      <Divider />
      {!logLoaded && <LoadingSkeleton />}
      {showLogs && logLoaded && (
        <Box
          sx={{
            p: 1,
          }}
        >
          <List disablePadding>
            {logs.length > 0 ? (
              logs.map((log, i) => (
                <React.Fragment key={i}>
                  <ListItem disableGutters disablePadding>
                    {log.severity === 'ERROR' ||
                    log.severity === 'ALERT' ||
                    log.severity === 'EMERGENCY' ||
                    log.severity === 'CRITICAL' ? (
                      <ListItemAvatar>
                        <AvatarWrapperError>
                          <ErrorIcon />
                        </AvatarWrapperError>
                      </ListItemAvatar>
                    ) : (
                      <ListItemAvatar sx={{ pl: '56px' }}></ListItemAvatar>
                    )}
                    <ListItemText>
                      <code
                        style={{
                          color:
                            log.severity === 'INFO' ||
                            log.severity === 'DEBUG' ||
                            log.severity === 'EMERGENCY'
                              ? 'black'
                              : log.severity === 'WARNING'
                              ? '#B8860B'
                              : 'red',
                          background:
                            log.severity === 'EMERGENCY' ? '#F67280' : 'white',
                        }}
                      >
                        {getFormattedLogFullDate(log.timestamp)} {log.payload}
                      </code>
                    </ListItemText>
                  </ListItem>
                  <Divider
                    sx={{
                      my: 0.5,
                    }}
                  />
                </React.Fragment>
              ))
            ) : (
              <CardContent
                sx={{
                  p: 2,
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography>{t('No log messages added so far.')}</Typography>
              </CardContent>
            )}
          </List>

          {nextLogLoaded ? (
            <>
              {footer === 'end' ? (
                <CardContent
                  sx={{
                    p: 2,
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>
                    {t('- No more logs available for the selected timerange -')}
                  </Typography>
                </CardContent>
              ) : (
                <Box display="flex" justifyContent="center">
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ my: 1 }}
                    onClick={showMoreHandler}
                  >
                    {footer === 'showmore' ? t('Show More') : t('Refresh')}
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <LoadingSkeleton />
          )}
        </Box>
      )}
    </Card>
  );
};
