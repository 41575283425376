import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import {
  Badge,
  Box,
  IconButton,
  ListItem,
  Popover,
  styled,
} from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { FC, MouseEvent, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { TooltipAI } from '../../../../components/TooltipAI';
import { SidebarContext } from '../../../../contexts/SidebarContext';

const IndicatorWrapper = styled(Box)(
  ({ theme }) => `
  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: -${theme.spacing(0.4)};
  width: 18px;
  height: 18px;

  .MuiSvgIcon-root {
    width: 100%;
    height: auto;
  }
`
);

const PopoverWrapper = styled(Popover)(
  ({ theme }) => `
  .MuiList-root {
    min-width: 240px;
    padding: ${theme.spacing(2)} !important;

    .MuiListItem-root {
      padding: 2px 0 !important;

      .MuiIconButton-root {
        width: 100% !important;
        height: auto !important;
        justify-content: flex-start !important;
        font-weight: bold !important;
        background: transparent !important;
        color: ${
          theme.palette.mode === 'light'
            ? theme.colors.alpha.black[70]
            : theme.colors.alpha.white[70]
        } !important;
        padding: ${theme.spacing(1, 2)} !important;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(1.8)};
          top: 19px;
        }

        .name-wrapper {
          display: block !important;
        }

        &.Mui-active,
        &:hover {
          background: ${
            theme.palette.mode === 'light'
              ? theme.colors.alpha.black[10]
              : theme.colors.alpha.white[10]
          } !important;
          color: ${
            theme.palette.mode === 'light'
              ? theme.colors.alpha.black[100]
              : theme.colors.alpha.white[100]
          } !important;
        }
      }
    }  
  }
`
);

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  ...rest
}) => {
  const { t }: { t: any } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <TooltipAI disableInteractive title={t(name)} placement="right" arrow>
          <IconButton
            id={'menu_group_' + name}
            className={clsx({ 'Mui-active': open })}
            onClick={handleClick}
          >
            {Icon && <Icon />}
            {badge === '' ? (
              <Badge color="primary" variant="dot" />
            ) : (
              <Badge badgeContent={badge} />
            )}

            <IndicatorWrapper>
              {open ? (
                <KeyboardArrowLeftTwoToneIcon />
              ) : (
                <KeyboardArrowRightTwoToneIcon />
              )}
            </IndicatorWrapper>
          </IconButton>
        </TooltipAI>
        <PopoverWrapper
          disableScrollLock
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          open={open}
        >
          {children}
        </PopoverWrapper>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest}>
      <IconButton
        className={clsx({ 'Mui-active': active })}
        component={RouterLink}
        onClick={closeSidebar}
        to={link}
        id={name}
      >
        {Icon && <Icon sx={{ mr: 0.5 }} />}
        <span className="name-wrapper">{t(name)}</span>
        {badge === '' ? (
          <Badge color="primary" variant="dot" />
        ) : (
          <Badge badgeContent={badge} />
        )}
      </IconButton>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default SidebarMenuItem;
