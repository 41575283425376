import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAuth from '../../hooks/useAuth';
import { CheckBeforeDeleteConfig, ReferenceCheckConfig } from '../../utils';
import { postAPI, postAPIWithResponse } from '../../utils/httputils';
import { showNotification } from '../../utils/notifyutils';
import DeletePopup from '../DeletePopup';
import MessagePopup from '../MessagePopup';

export interface DeleteRequestProps {
  orgID: string;
  id?: number;
  userID?: string;
  name?: string;
  zoneID?: number;
  clientID?: number;
}

export interface DeleteActionProps {
  id: number | string;
  entityName: string;
  caption: string;
  deleteMsg: string;
  resourceName: string;
  deleteUrl: string;
  callbackHandler: any;
  deleteErrorHandler: (resp: string, type: string, t: any) => void;
  title: string;
  icon?: boolean;
  variant?: 'text' | 'contained' | 'outlined';
  iconOnly?: boolean;
  shortCaption?: string;
  type?: string;
  enableDelete?: (type: string) => boolean;
  referenceCheck?: ReferenceCheckConfig;
  checkBeforeDelete?: CheckBeforeDeleteConfig;
  listResourceName?: string;
}

export default function DeleteActions(props: DeleteActionProps) {
  const { t }: { t: any } = useTranslation();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { zoneID } = useContext(GlobalContext);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [referenceMsg, setReferenceMsg] = useState([]);
  const deleteApiReferencePath = 'config.entity.references.get';
  let allowDelete = true;
  if (props.enableDelete && props.type) {
    allowDelete = props.enableDelete(props.type);
  }
  if (!allowDelete) {
    return <></>;
  }

  const invokePreDeleteCheck = (
    checkBeforeDeleteConfig: CheckBeforeDeleteConfig,
    id: any
  ) => {
    var req = checkBeforeDeleteConfig.apiReq;
    req[checkBeforeDeleteConfig.entityKey] = id;
    return postAPIWithResponse(checkBeforeDeleteConfig.apiUrl, req);
  };

  const checkDeleteReferenceError = () => {
    if (props.checkBeforeDelete) {
      invokePreDeleteCheck(props.checkBeforeDelete, props.id).then((resp) => {
        const blockDelete = props.checkBeforeDelete.apiCallBack(resp.data);
        if (blockDelete && props.checkBeforeDelete.apiErrorText) {
          showNotification(
            enqueueSnackbar,
            props.entityName + ' : ' + t(props.checkBeforeDelete.apiErrorText),
            'error'
          );
        } else {
          checkDeleteReferenceErrorFn();
        }
      });
    } else {
      checkDeleteReferenceErrorFn();
    }
  };

  const checkDeleteReferenceErrorFn = () => {
    const payload = {
      entityID: props.id,
      entityName: props.entityName,
      entityType: props.referenceCheck.entityType,
      orgID: auth.user.orgID,
      zoneID,
    };
    postAPI(deleteApiReferencePath, payload, (resp) => {
      if (!resp.error || resp.error === '') {
        const referencesResponse = resp.data.references;
        const referencesKeyList = Object.keys(resp.data.references);
        const referenceObjList = [];
        if (referencesKeyList.length) {
          referencesKeyList.map((reference) => {
            referencesResponse[reference].map((item) => {
              referenceObjList.push({
                name: reference,
                value: item.entityName,
                linkUrl:
                  (props.referenceCheck.referenceList &&
                    props.referenceCheck.referenceList[reference] &&
                    `/${props.referenceCheck.referenceList[reference]}/${item.entityID}`) ||
                  (props.referenceCheck.globalReferenceList &&
                    props.referenceCheck.globalReferenceList[reference] &&
                    `/${props.referenceCheck.globalReferenceList[reference]}`),
              });
            });
          });
          setReferenceMsg(referenceObjList);
          setShowMessagePopUp(true);
        } else {
          setShowDeletePopUp(true);
        }
      }
    });
  };

  const deleteHandler = () => {
    let req: DeleteRequestProps = {
      orgID: auth.user.orgID,
      id: props.id as number,
    };
    if (props.resourceName === 'users') {
      req = {
        orgID: auth.user.orgID,
        userID: props.id as string,
      };
    } else if (props.resourceName === 'clients') {
      req = {
        orgID: auth.user.orgID,
        clientID: props.id as number,
      };
    } else if (props.resourceName === 'id_clientgroups') {
      req = {
        orgID: auth.user.orgID,
        userID: props.id as string,
        name: props.entityName,
        zoneID,
      };
    }
    postAPI(props.deleteUrl, req, (resp) => {
      if (!resp.error || resp.error === '') {
        showNotification(
          enqueueSnackbar,
          props.entityName + ' : ' + t(`${props.title} deleted successfully`),
          'success'
        );

        setShowDeletePopUp(false);

        // When delete is successful, clear cache for pagination to work as usual.
        if (props.listResourceName) {
          localStorage.removeItem('prevData_' + props.listResourceName);
          localStorage.removeItem('prevDataTime_' + props.listResourceName);
        }

        props.callbackHandler();
      } else {
        let msg = props.entityName + ' : ';
        if (resp.error === 'entity_referenced_by_other_entities') {
          msg += props.deleteErrorHandler(resp, props.resourceName, t);
        } else {
          msg += t(props.resourceName + resp.errorl);
        }

        showNotification(enqueueSnackbar, msg, 'error');
      }
    });
  };

  return (
    <>
      <Button
        sx={{ px: 2, borderRadius: 0, justifyContent: 'start' }}
        fullWidth
        size="large"
        onClick={() => {
          props.referenceCheck
            ? checkDeleteReferenceError()
            : setShowDeletePopUp(true);
        }}
        startIcon={<DeleteIcon fontSize="small" />}
        color={'error'}
        id={'id_page_button_' + props.caption}
      >
        {t(props.caption)}
      </Button>
      {showDeletePopUp && (
        <DeletePopup
          deleteMsg={t(props.deleteMsg)}
          entityName={props.entityName}
          deleteHandler={deleteHandler}
          closeHandler={() => setShowDeletePopUp(false)}
        ></DeletePopup>
      )}
      {showMessagePopUp && (
        <MessagePopup
          message={`Cannot delete '${props.entityName}'`}
          entityName={props.entityName}
          closeHandler={() => setShowMessagePopUp(false)}
          referenceMsg={referenceMsg}
          iconMapping={props.referenceCheck.entityIconMap}
        ></MessagePopup>
      )}
    </>
  );
}
