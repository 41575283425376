import { useEffect, useRef, useState } from 'react';

import {
  IconButton,
  Box,
  List,
  ListItem,
  Typography,
  ListItemText,
  Popover,
  Tooltip,
  styled,
} from '@mui/material';
import internationalization from '../../translations/i18n';
import { useTranslation } from 'react-i18next';

import zhFlag from '../../assets/images/zh.svg';
import usFlag from '../../assets/images/us.svg';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import React from 'react';

const ImageWrapper = styled('img')(
  () => `
        width: 30px;
        margin: 3px;
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);

function LanguageSwitcher(props) {
  const { i18n } = useTranslation();
  const { t }: { t: any } = useTranslation();
  const getLanguage = i18n.language;

  const switchLanguage = ({ lng }: { lng: any }) => {
    internationalization.changeLanguage(lng);
    localStorage.setItem('i18n', lng);
  };
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    let lang = localStorage.getItem('i18n');
    if (lang === 'en' || lang === 'zh') {
      internationalization.changeLanguage(lang);
    } else {
      var userLang = navigator.language;
      if (userLang.startsWith('zh')) {
        internationalization.changeLanguage('zh');
        localStorage.setItem('i18n', 'zh');
      }
    }
  }, []);

  return (
    <>
      <Tooltip arrow title={t('Languages')}>
        <IconButtonWrapper
          id="id_button_languageSelect_toggle"
          color="primary"
          ref={ref}
          onClick={handleOpen}
          sx={{
            ...(props.mode === 'dark' && {
              color: 'white',
            }),
            // display: "flex", flexDirection: "column"
          }}
        >
          <LanguageOutlinedIcon />
          {/* <span style={{ fontSize: 8 }}>EN</span> */}
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: 240,
          }}
        >
          <Typography
            sx={{
              mt: 1,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
            variant="body1"
          >
            {t('Languages')}
          </Typography>
          <List
            sx={{
              p: 2,
            }}
            component="nav"
          >
            <ListItem
              className={
                getLanguage === 'en' || getLanguage === 'en-US' ? 'active' : ''
              }
              button
              id="id_language_select_en"
              onClick={() => {
                switchLanguage({ lng: 'en' });
                handleClose();
              }}
            >
              <ImageWrapper alt="English" src={usFlag} />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="English"
              />
            </ListItem>
            <ListItem
              id="id_language_select_zh"
              className={getLanguage === 'zh' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'zh' });
                handleClose();
              }}
            >
              <ImageWrapper alt="Chinese" src={zhFlag} />
              <ListItemText
                sx={{
                  pl: 1,
                }}
                primary="Chinese"
              />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
