import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';

interface PasswordFieldProps {
  label?: string;
  value: string;
  name?: string;
  size?: string; // small or medium
  onChange?: (fieldName: string, value: string) => void;
  error?: any;
  helperText?: any;
  compact?: boolean;
  lastElement?: boolean;
  id?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
}

const PasswordField: FC<PasswordFieldProps> = (props: PasswordFieldProps) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [showPsk, setShowPsk] = useState(false);

  const handleShowPsk = () => {
    setShowPsk(!showPsk);
  };

  const isReadonly = () => {
    return auth.user?.readOnly || props.readOnly;
  };

  return (
    <>
      {isReadonly() ? (
        <>
          <Grid
            container
            alignItems={'center'}
            sx={{ ml: props.compact ? 3 : 1, py: 0.5 }}
          >
            <Grid item>{t(props.label)}</Grid>
            <Grid item sx={{ ml: 'auto' }}>
              <Grid container alignItems={'center'} spacing={1}>
                <Grid item>{showPsk ? props.value : '**********'}</Grid>
                <Grid item sx={{ mr: props.compact ? 6 : 2 }}>
                  <IconButton
                    id={
                      props.id
                        ? 'id_button_toggle_' + props.id
                        : 'id_button_showPassphrase_toggle'
                    }
                    edge="end"
                    aria-label="copy"
                    onClick={handleShowPsk}
                  >
                    {showPsk ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!props.lastElement && <Divider variant="middle"></Divider>}
        </>
      ) : (
        <FormControl
          variant="outlined"
          fullWidth={props.fullWidth ? props.fullWidth : true}
          error={props.error}
        >
          {props.label && (
            <InputLabel
              htmlFor={props.id ? 'id_' + props.id : 'id_passphrase_text'}
            >
              {props.label}
            </InputLabel>
          )}
          <OutlinedInput
            size={props.size === 'small' ? 'small' : 'medium'}
            id={props.id ? 'id_' + props.id : 'id_passphrase_text'}
            data-testid={props.id ? 'id_' + props.id : 'id_passphrase_text'}
            type={showPsk ? 'text' : 'password'}
            autoComplete="off"
            value={props.value}
            autoFocus={props.autoFocus}
            name={props.name || 'passphrase'}
            onChange={(e) =>
              props.onChange &&
              props.onChange(props.name || 'passphrase', e.target.value)
            }
            disabled={!props.onChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  id={
                    props.id
                      ? 'id_button_toggle_' + props.id
                      : 'id_button_showPassphrase_toggle'
                  }
                  aria-label="toggle password visibility"
                  onClick={handleShowPsk}
                  edge="end"
                >
                  {showPsk ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={props?.label}
          />
          {props.helperText && (
            <FormHelperText error={props.error || false}>
              {props.helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </>
  );
};

export default PasswordField;
