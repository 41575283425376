import { FC, useState, createContext } from 'react';
import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

type GlobalContext = {
  mobileView: boolean;
  tabView: boolean;
  zoneID: number;
  zoneName: string;
  setZoneID: (number, string) => void;
  titleHeight: number;
  setTitleHeight: (number) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GlobalContext = createContext<GlobalContext>({} as GlobalContext);

export const GlobalContextProvider: FC = ({ children }) => {
  const theme = useTheme();
  const [zoneID, saveZoneID] = useState<number>(0);
  const zoneName = '';
  const [titleHeight, saveTitleHeight] = useState(170);
  const mobileView =
    useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true }) !== true;
  const tabView = useMediaQuery('(min-width:1200px)', { noSsr: true }) !== true;
  const setZoneID = (zone: number, zoneName: string) => {
    localStorage.setItem('zone', zone.toString());
    localStorage.setItem('zonename', zoneName);
    saveZoneID(zone);
  };
  const setTitleHeight = (val: number) => {
    saveTitleHeight(val);
  };

  return (
    <GlobalContext.Provider
      value={{
        mobileView,
        zoneID,
        zoneName,
        setZoneID,
        tabView,
        titleHeight,
        setTitleHeight,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
