import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import accessDenied from '../../assets/images/access_denied.jpeg';
import agniBlueLogo from '../../assets/agni-blue.png';
import agniLogo from '../../assets/agni.png';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { redirectURLs } from '../../config';

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
);

function StatusPage(props) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState<any>();
  const params = useParams();

  useEffect(() => {
    const prevUrl: string = localStorage.getItem('404Url');
    if (prevUrl) {
      for (const iterator of redirectURLs) {
        if (prevUrl.includes(iterator.url)) {
          setRedirectUrl(iterator);
          break;
        }
      }
      localStorage.removeItem('404Url');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {t('Status')} - {props.status}
        </title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box
            textAlign="center"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 150px)',
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {(props.status === '404' ||
                props.status === '502' ||
                props.status === '503') && (
                <img
                  alt={props.status}
                  height={100}
                  src={theme.palette.mode === 'light' ? agniBlueLogo : agniLogo}
                />
              )}
              {props.status === '403' && (
                <img alt="403" height={250} src={accessDenied} />
              )}
              <Typography
                variant="h2"
                sx={{
                  mt: 5,
                  mb: 2,
                }}
              >
                {props.status === '404' && (
                  <>
                    {redirectUrl ? (
                      <span>
                        {t(redirectUrl.resourceName)}&nbsp;
                        {t('you were looking for does not exist.')}
                      </span>
                    ) : (
                      <span>
                        {t('The page you were looking for does not exist.')}
                      </span>
                    )}
                  </>
                )}
                {props.status === '403' && (
                  <span>
                    {t("You don't have permission to access this page.")}
                  </span>
                )}
                {props.status === '502' && <span>{t('Bad Gateway')}</span>}
                {props.status === '503' && <span>{t('app_not_ready')}</span>}
              </Typography>
              {!props.hideNav && (
                <Box textAlign="center">
                  {redirectUrl && (
                    <Button
                      onClick={() =>
                        navigate(
                          redirectUrl.redirectUrl
                            ? redirectUrl.redirectUrl
                            : redirectUrl.url
                        )
                      }
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      {t('View')}&nbsp;{t(redirectUrl.resourceName)}s
                    </Button>
                  )}
                  <Button
                    onClick={() =>
                      props.homeURLFromParam && params.id
                        ? navigate('/' + params.id)
                        : navigate('/')
                    }
                    variant="outlined"
                  >
                    {t(props.status === '503' ? 'Retry' : 'Go to Homepage')}
                  </Button>
                </Box>
              )}
            </Stack>
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default StatusPage;
