import { Box, useTheme } from '@mui/material';
import PubSub from 'pubsub-js';
import React, { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AvaButton from '../../components/AvaButton';
import TopbarAlert from '../../components/TopbarAlert';
import { SidebarLayoutProps, getSidebarToggleVal } from '../base';
import Header from './Header';
import Sidebar from './Sidebar';

const AccentHeaderLayout: FC<SidebarLayoutProps> = ({
  menu = [],
  landingURL = '',
}) => {
  const theme = useTheme();
  const [collapseSidebar, setCollapseSidebar] = useState(getSidebarToggleVal());
  const urlParams = new URLSearchParams(window.location.search);
  const isPrint = urlParams.get('print');

  const collapseSidebarCallback = () => {
    if (collapseSidebar) {
      localStorage.setItem('sidebartoggle', 'n');
    } else {
      localStorage.setItem('sidebartoggle', 'y');
    }
    setCollapseSidebar((collapseSidebar) => !collapseSidebar);
    PubSub.publish('sidebartoggle', collapseSidebar ? 'n' : 'y');
  };

  return (
    <>
      {isPrint !== 'true' ? (
        <>
          <Header landingURL={landingURL} />
          <Sidebar
            menuItems={menu}
            landingURL={landingURL}
            collapseSidebarCallback={collapseSidebarCallback}
            toggleSidebar={collapseSidebar}
          />
          <Box
            sx={{
              position: 'relative',
              zIndex: 5,
              flex: 1,
              display: 'flex',
              pt: `${theme.header.height}`,
              ...(!collapseSidebar
                ? {
                    [theme.breakpoints.up('lg')]: {
                      pl: `${theme.sidebar.width}`,
                    },
                  }
                : {
                    [theme.breakpoints.up('lg')]: {
                      ml: 10,
                    },
                  }),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Box flexGrow={1}>
                <TopbarAlert />
                <Outlet />
              </Box>
            </Box>
            <AvaButton />
          </Box>
        </>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default AccentHeaderLayout;
