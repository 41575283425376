import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IconButton, Zoom } from '@mui/material';
import { IconVariant, useSnackbar } from 'notistack';
import * as React from 'react';

export const showNotification = (
  enqueueSnackbar,
  msg,
  msgType,
  durationInSec?: number
) => {
  enqueueSnackbar(msg, {
    variant: msgType,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    TransitionComponent: Zoom,
    autoHideDuration: durationInSec
      ? durationInSec * 1000
      : msgType === 'error'
      ? 6000
      : 3000,
  });
};

const iconStyles = {
  fontSize: 20,
  marginInlineEnd: 8,
};

export const NotificationIconVariants: IconVariant = {
  default: undefined,
  success: <CheckCircleOutlineIcon style={iconStyles} />,
  warning: <WarningAmberIcon style={iconStyles} />,
  error: <ErrorOutlineIcon style={iconStyles} />,
  info: <LightbulbCircleIcon style={iconStyles} />,
};

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon fontSize="small" sx={{ color: 'white' }} />
    </IconButton>
  );
}

export default SnackbarCloseButton;
