import {
  AppInit,
  GlobalContextProvider,
  NotificationIconVariants,
  SnackbarCloseButton,
  ThemeProviderWrapper,
  useAuth,
} from "@antara/shared";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { useRoutes } from "react-router-dom";
import routes from "./router";
import i18n from "./translations/i18n";
import { mockAxios } from "./utils/axiosutil";

const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();

  useEffect(() => {
    mockAxios();
  }, []);

  return (
    <ThemeProviderWrapper>
      <I18nextProvider i18n={i18n}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <GlobalContextProvider>
            <SnackbarProvider
              maxSnack={6}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              iconVariant={NotificationIconVariants}
              action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            >
              <CssBaseline />
              {auth.isInitialized ? content : <AppInit />}
            </SnackbarProvider>
          </GlobalContextProvider>
        </LocalizationProvider>
      </I18nextProvider>
    </ThemeProviderWrapper>
  );
};
export default App;
