import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
// import GroupsIcon from "@mui/icons-material/Groups";
import EditIcon from '@mui/icons-material/Edit';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {PageAction} from '../../components/PageTitle/PageAction';
import {BodyCell} from '../../components/Table/model';
import {CardAction} from '../../components/common/model';
import useAuth from '../../hooks/useAuth';
import CrudTemplate from '../../templates/CrudTemplate';
import { CrudConfig } from '../../templates/CrudTemplate/CrudConfig';
import { getFormattedRegularDate } from '../../utils/dateutils';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import {
  getGuestStatusLabel,
  getGuestUserFilterConfig,
  getOperatorGuestUserFilterConfig,
  getTypeLabel,
  GuestDefaults,
  guestUserCardConfig,
  guestUserFilterConfig,
  guestUserHeadCells,
  GuestUserTabs,
  onboardGuestSponsorBodyCells,
  onboardGuestSponsorHeadCells,
} from './GuestConfig';
import { showNotification } from '../../utils/notifyutils';
import { useTranslation } from 'react-i18next';

const GuestUsers = ({
  apiPrefix,
  isOnboard,
  guestUserURL,
  redirectToSponsorURL,
  manageSettingsHandler,
}: {
  apiPrefix: string;
  isOnboard: boolean;
  guestUserURL: string;
  redirectToSponsorURL?: string;
  manageSettingsHandler?: any;
}) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();
  const getFrom = () => {
    return guestUserURL;
  };

  const updateEntityHandler = (selectedData) => {
    navigate(guestUserURL + selectedData.userID);
  };

  const addEntityHandler = () => {
    navigate(guestUserURL + 'new');
  };

  const operatorGuestUserFilterConfig = getOperatorGuestUserFilterConfig(
    auth.user.userID
  );

  // const manageBatchHandler = () => {
  //   navigate("/identity/guests/batches");
  // };

  const readonlyActions: CardAction[] = [
    {
      caption: 'show_details',
      callbackFn: updateEntityHandler,
      icon: VisibilityIcon,
      color: 'primary',
    },
  ];

  const cardActions: CardAction[] = [
    {
      caption: 'Update',
      callbackFn: updateEntityHandler,
      icon: EditIcon,
      color: 'primary',
    },
  ];

  const guestUserBodyCells: BodyCell[] = [
    {
      id: 'loginName',
      bold: true,
    },
    {
      id: 'email',
    },
    {
      id: 'sponsorName',
      linkURL: isOnboard ? '' : redirectToSponsorURL,
      linkURLParam: isOnboard ? '' : 'sponsorID',
      linkSourceURLFn: isOnboard ? null : getFrom,
    },
    {
      id: 'portalID',
      labelFn: getTypeLabel,
    },
    // {
    //   id: "batchName",
    // },
    {
      id: 'status',
      labelFn: getGuestStatusLabel,
    },
    {
      id: 'validFrom',
      labelFn: getFormattedRegularDate,
    },
    {
      id: 'validTo',
      labelFn: getFormattedRegularDate,
    },
  ];

  const copyPortalLink = () => {
    const data = window.location.origin + "/onboard/" + auth.user.orgID;
    navigator.clipboard.writeText(data);
    showNotification(enqueueSnackbar, t("Portal URL has been copied to clipboard"), "success");
  };

  const pageActions: PageAction[] = isOnboard
    ? [
        {
          caption: 'id_guests_add_import',
          shortCaption: 'id_guests_add_import_short',
          icon: AddTwoToneIcon,
          callbackHandler: addEntityHandler,
        },
      ]
    : [
        // {
        //   caption: "Manage Batches",
        //   icon: GroupsIcon,
        //   callbackHandler: manageBatchHandler,
        //   showForReadonlyUser: true,
        // },
        {
          caption: "self_service_portal",
          shortCaption: "Portal",
          icon: ContentCopyIcon,
          callbackHandler: copyPortalLink,
        },
        {
          caption: 'id_guests_add_import',
          shortCaption: 'id_guests_add_import_short',
          icon: AddTwoToneIcon,
          callbackHandler: addEntityHandler,
        },
        {
          caption: 'Settings',
          icon: SettingsIcon,
          callbackHandler: manageSettingsHandler,
          showForReadonlyUser: true,
        },
      ];

  const myFilterConfig = isOnboard
    ? auth.user.isGuestAdminOperator
      ? operatorGuestUserFilterConfig
      : getGuestUserFilterConfig(false)
    : guestUserFilterConfig;

  const myHeadCells =
    isOnboard && !auth.user.isGuestAdminOperator
      ? onboardGuestSponsorHeadCells
      : guestUserHeadCells;

  const myBodyCells =
    isOnboard && !auth.user.isGuestAdminOperator
      ? onboardGuestSponsorBodyCells
      : guestUserBodyCells;

  const guestConfig: CrudConfig = {
    resourceName: isOnboard ? 'id_guests_onboard' : 'id_guests', // keeping separate to avoid sponsorFilter in AGNI from storage
    prefsCacheVersion: 1,
    primaryKey: 'userID',
    newEntity: GuestDefaults,

    listApiPath: apiPrefix + '.list',
    listApiResponseHeader: 'users',
    deleteApiPath: apiPrefix + '.delete',
    onRowClick: updateEntityHandler,

    nameField: 'loginName',
    deleteKeyField: 'userID',
    fieldsForContainsFilter: ['loginName'],

    pageHeading: 'id_guests_users',
    pageSubheading: 'id_guests_desc',
    pageActions: pageActions,
    pageIcon: FollowTheSignsIcon,

    showToggleBtn: true,

    tabFilterField: 'userType',
    tabs: GuestUserTabs,
    showTabs: isOnboard && !auth.user.isGuestAdminOperator ? false : true,
    defaultTabField: 'all',

    filterConfig: myFilterConfig,
    headCells: myHeadCells,
    bodyCells: myBodyCells,

    actions: cardActions,
    cardType: 'overview',
    cardConfig: guestUserCardConfig,
    readonlyActions,
    fetchDataByChunks: true,
    multiSelect: true,
    bulkDeleteProps: auth.user.readOnly
      ? null
      : {
          deletePayloadKey: 'userIDList',
          deleteKey: 'userID',
        },
    bulkExportProps: {
      exportPayloadKey: 'userIDList',
      exportKey: 'userID',
    },
    apiPrefix: apiPrefix,
      saveDataOnRouteChange: true,
    showBulkInReadonly: true,
  };

  return <CrudTemplate {...guestConfig} forceUpdate={false} />;
};

export default GuestUsers;
