import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { appConfig } from '../config';
import { themeCreator, themeMap } from './base';
// import { CacheProvider } from '@emotion/react';
// import createCache from '@emotion/cache';
// import stylisRTLPlugin from 'stylis-plugin-rtl';

// const cacheRtl = createCache({
//   key: 'bloom-ui',
// prepend: true,
//   // @ts-ignore
//   stylisPlugins: [stylisRTLPlugin]
// });

export const ThemeContext = React.createContext(
  // tslint:disable-next-line
  (themeName: string): void => {
    console.log(themeName);
  }
);

const ThemeProviderWrapper: React.FC = (props) => {
  const [themeName, _setThemeName] = useState('AristaLightTheme');
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  useEffect(() => {
    let currentTheme: string;
    if (appConfig.appname === 'onboard' || appConfig.appname === 'mc') {
      currentTheme = 'AristaLightTheme';
    } else if (!localStorage.getItem('themeSelected')) {
      // Add listener to update styles
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => {
          e.matches
            ? setThemeName('AristaDarkThemePlus')
            : setThemeName('AristaLightTheme');
        });

      // Setup dark/light mode for the first time
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? setThemeName('AristaDarkThemePlus')
        : setThemeName('AristaLightTheme');
    } else {
      currentTheme = localStorage.getItem('appTheme') || 'AristaLightTheme';
      if (!Object.keys(themeMap).includes(currentTheme)) {
        currentTheme = 'AristaLightTheme';
      }
      setThemeName(currentTheme);
    }
    // Remove listener
    return () => {
      if (!appConfig.mock) {
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .removeEventListener('change', () => {});
      }
    };
  }, []);

  return (
    <StylesProvider injectFirst>
      {/* <CacheProvider value={cacheRtl}> */}
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
      {/* </CacheProvider> */}
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
