import styled from "@emotion/styled";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import image from "src/assets/images/shield.svg";

const MainContent = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      overflow: auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `
);

const OnboardStatusPage = () => {
  const { t } = useTranslation();
  const [queryParam] = useSearchParams();
  let error_code = queryParam.get("error_code");
  let success = queryParam.get("success");

  return (
    <>
      {error_code && (
        <Helmet>
          <title>
            {t("Error")} - {t(error_code)}
          </title>
        </Helmet>
      )}
      {success && (
        <Helmet>
          <title>{success && t("Success")}</title>
        </Helmet>
      )}
      <MainContent>
        {error_code && (
          <Container maxWidth="md">
            <Box textAlign="center">
              <img alt={t(error_code)} height={180} src={image} />
            </Box>
            <Box textAlign="center">
              <Typography
                variant="h3"
                sx={{
                  mt: 3,
                  mb: 1,
                }}
              >
                {t(error_code)}
              </Typography>
              <Typography variant="h4" fontWeight={400}>
                {t("contact_admin")}
              </Typography>
            </Box>
          </Container>
        )}
        {success && (
          <Container maxWidth="md">
            <Box textAlign="center">
              <CheckCircleOutlineIcon sx={{ height: "7em", width: "7em" }} color="success"></CheckCircleOutlineIcon>
            </Box>
            <Box textAlign="center">
              <Typography variant="h4" fontWeight={400}>
                {t("login_success")}
              </Typography>
            </Box>
          </Container>
        )}
      </MainContent>
    </>
  );
};

export default OnboardStatusPage;
