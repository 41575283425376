import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Link,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { CardAction } from '../common/model';
import { BodyCell } from './model';

export interface TableRowProps {
  resourceName: string;
  primaryKey?: string;
  rowID: number;
  bodyCells: BodyCell[];
  row: any;
  actions?: CardAction[];
  collapsibleContent?: any;
  collapsibleContentProvider?: any;
  collapsibleDiffProvider?: any;
  collapsibleDetailsProvider?: any;
  updateHandler?: any;
  deleteHandler?: any;
  onRowClick?: any;
  collapseAll: boolean;
  multiSelect?: boolean;
  onRowSelected?(index: number, row: any, checked: boolean): void;
  selected?: boolean;
  onRouteChange?(index: number): void;
}

const TableCellContent = ({ row, bodyCell }) => {
  return (
    <>
      {bodyCell.labelFn ? (
        bodyCell.passRow ? (
          bodyCell.labelFn(row[bodyCell.id], row)
        ) : (
          bodyCell.labelFn(row[bodyCell.id])
        )
      ) : (
        <Typography variant={bodyCell.bold ? 'h5' : 'body1'}>
          {row[bodyCell.id]}
        </Typography>
      )}
    </>
  );
};

const TableRowAI: FC<TableRowProps> = (props: TableRowProps) => {
  const [open, setOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const getToLink = (bodyCell, props) => {
    let url = bodyCell.linkURL;
    if (bodyCell.linkURLParam) {
      url += props.row[bodyCell.linkURLParam];
    }
    if (bodyCell.linkSourceURLFn) {
      url += '?from=' + bodyCell.linkSourceURLFn(props.row);
    }
    return url;
  };

  useEffect(() => {
    setOpen(false);
  }, [props.collapseAll]);

  const rowClickHandler = () => {
    if (props.onRowClick) {
      if (props.onRouteChange) {
        props.onRouteChange(props.rowID);
      }
      props.onRowClick(props.row);
    }
  };
  return (
    <>
      <TableRow
        hover
        sx={{
          ...(props.rowID % 2 === 0
            ? {
                background: `${
                  theme.palette.mode === 'light'
                    ? theme.colors.alpha.black[5]
                    : theme.colors.alpha.black[70]
                }`,
              }
            : {
                background:
                  theme.palette.mode === 'light'
                    ? 'white'
                    : theme.colors.alpha.black[1],
              }),
          ...(props.onRowClick ? { cursor: 'pointer' } : { cursor: 'auto' }),
          ...(props.selected && {
            background: `${theme.colors.secondary.lighter}`,
          }),
        }}
        onClick={rowClickHandler}
        id={'row_' + props.rowID}
      >
        {props.multiSelect && (
          <TableCell
            padding="checkbox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              color="primary"
              checked={props.selected}
              onChange={(_, checked) => {
                props.onRowSelected(props.rowID - 1, props.row, checked);
              }}
            />
          </TableCell>
        )}
        {props.collapsibleContent && (
          <TableCell sx={{ width: 50, padding: 1 }}>
            <IconButton
              data-testid={
                props.primaryKey
                  ? 'id_button_row_' + props.row[props.primaryKey] + '_collapse'
                  : 'id_button_row_' + props.rowID + '_collapse'
              }
              aria-label="expand row"
              size="small"
              onClick={(event) => {
                setOpen(!open);
                event.stopPropagation();
              }}
              id={
                props.primaryKey
                  ? 'id_button_row_' + props.row[props.primaryKey] + '_collapse'
                  : 'id_button_row_' + props.rowID + '_collapse'
              }
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell sx={{ width: 50 }}>
          <Typography>{props.rowID}</Typography>
        </TableCell>
        {props.bodyCells.map((bodyCell, i) => (
          <TableCell key={i} align={bodyCell.centerAlign ? 'center' : 'left'}>
            {bodyCell.linkFn || bodyCell.linkURL ? (
              <>
                {bodyCell.linkFn && (
                  <Link
                    component="button"
                    id={
                      props.primaryKey
                        ? 'id_button_row_' +
                          props.row[props.primaryKey] +
                          '_link'
                        : 'id_button_row_' + props.rowID + '_link'
                    }
                    onClick={(event) => {
                      bodyCell.linkFn(props.row);
                      event.stopPropagation();
                    }}
                  >
                    <TableCellContent
                      row={props.row}
                      bodyCell={bodyCell}
                    ></TableCellContent>
                  </Link>
                )}
                {bodyCell.linkURL && (
                  <Link
                    component="button"
                    sx={{ fontSize: '15px' }}
                    onClick={(event) => {
                      if (props.onRouteChange) {
                        props.onRouteChange(props.rowID);
                      }
                      navigate(getToLink(bodyCell, props));
                      event.stopPropagation();
                    }}
                    id={
                      props.primaryKey
                        ? 'id_button_row_' +
                          props.row[props.primaryKey] +
                          '_link'
                        : 'id_button_row_' + props.rowID + '_link'
                    }
                  >
                    <TableCellContent
                      row={props.row}
                      bodyCell={bodyCell}
                    ></TableCellContent>
                  </Link>
                )}
              </>
            ) : (
              <TableCellContent
                row={props.row}
                bodyCell={bodyCell}
              ></TableCellContent>
            )}
          </TableCell>
        ))}
        {props.actions ? (
          <TableCell
            align="right"
            sx={{
              mr: 1,
              minWidth: (props.actions.length * 50).toString() + 'px',
            }}
          >
            {props.actions.map((action, index) => {
              return (
                <Tooltip title={t(action.caption)} key={index} arrow>
                  <span>
                    <IconButton
                      name={t(action.caption)}
                      aria-label={t(action.caption)}
                      onClick={(event) => {
                        if (!action.disabled) {
                          if (action.type === 'delete') {
                            props.deleteHandler(props.row);
                          } else {
                            if (action.changeURL && props.onRouteChange) {
                              props.onRouteChange(props.rowID);
                            }
                            action.callbackFn(props.row, props.rowID - 1); // Passing index of edited clicked row
                          }
                        }
                        event.stopPropagation();
                      }}
                      color={action.color}
                      sx={{
                        ...(action.disabled && {
                          cursor: 'default',
                          '&:hover': {
                            background: 'none',
                          },
                          color:
                            theme.palette.mode === 'light'
                              ? theme.colors.alpha.black[30]
                              : theme.colors.alpha.white[30],
                        }),
                      }}
                      id={
                        props.primaryKey
                          ? 'id_button_row_' +
                            props.row[props.primaryKey] +
                            '_' +
                            action.caption
                          : 'id_button_row_' +
                            props.rowID +
                            '_' +
                            action.caption
                      }
                      data-testid={
                        props.primaryKey
                          ? 'id_button_row_' +
                            props.row[props.primaryKey] +
                            '_' +
                            action.caption
                          : 'id_button_row_' +
                            props.rowID +
                            '_' +
                            action.caption
                      }
                    >
                      <action.icon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              );
            })}
          </TableCell>
        ) : (
          <TableCell align="center">
            <Typography noWrap>
              {props.updateHandler != null && (
                <Tooltip title={t('Update')} arrow>
                  <IconButton
                    name={t('Update')}
                    aria-label={t('Update')}
                    onClick={(event) => {
                      if (props.onRouteChange) {
                        props.onRouteChange(props.rowID);
                      }
                      props.updateHandler(props.row);
                      event.stopPropagation();
                    }}
                    color="primary"
                    id={
                      props.primaryKey
                        ? 'id_button_row_' +
                          props.row[props.primaryKey] +
                          '_update'
                        : 'id_button_row_' + props.rowID + '_update'
                    }
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {props.deleteHandler != null && (
                <Tooltip title={t('Delete')} arrow>
                  <IconButton
                    name={t('Delete')}
                    aria-label={t('Delete')}
                    onClick={(event) => {
                      props.deleteHandler(props.row);
                      event.stopPropagation();
                    }}
                    color="error"
                    id={
                      props.primaryKey
                        ? 'id_button_row_' +
                          props.row[props.primaryKey] +
                          '_delete'
                        : 'id_button_row_' + props.rowID + '_delete'
                    }
                  >
                    <DeleteTwoToneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
          </TableCell>
        )}
      </TableRow>
      {open && props.collapsibleContent && (
        <TableRow>
          <TableCell
            sx={{ padding: 0, borderBottom: 'none' }}
            colSpan={props.bodyCells.length + 3}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  borderLeft: `5px solid ${theme.colors.primary.main}`,
                  borderBottom: `1px solid ${theme.colors.alpha.black[30]}`,
                }}
              >
                <Box sx={{ padding: 1 }}>
                  <props.collapsibleContent
                    contentProvider={props.collapsibleContentProvider}
                    rowData={props.row}
                    diffProvider={props.collapsibleDiffProvider}
                    detailsProvider={props.collapsibleDetailsProvider}
                  />
                </Box>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TableRowAI;
