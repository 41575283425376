import { darken, lighten } from '@mui/material';
import PropTypes from 'prop-types';
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useNavigate } from 'react-router';
import { appConfig } from '../config';
import { ThemeContext } from '../theme/ThemeProvider';
import { themeCreator } from '../theme/base';
import { getOrgIDFromUrl, postAPI } from '../utils/httputils';
import { isUserReadonly } from '../utils/rbacutils';

export type AdminRole = 'superuser' | 'admin' | 'operator';

export type AdminStatus = 'enabled' | 'invited' | 'disabled';

export type UserType = 'local' | 'external';

export type LicenseType = 'base' | 'trial' | 'subscription';

export interface LicenseInfo {
  type: LicenseType;
  count: number;
  expiry: string;
  trialDone: boolean;
  contactName: string;
  contactEmail: string;
}

interface AdminUserSettings {
  avaConsentAccept: boolean;
  avaConsentUpdatedAt: string;
}

export interface Admin {
  orgID: string;
  userID?: string;
  email: string;
  name: string;
  role: AdminRole;
  status: AdminStatus;
  pskPassphrase: string;
  readOnly: boolean;
  onboardReadOnly?: boolean;
  userType?: UserType;
  loginName?: string;
  // below fields are applicable only for onboard - device admin and upsk limit scenario
  deviceAdministrationAllowed?: boolean;
  deviceAdministrationCredential?: string;
  deviceAdminTokenExpiresAt?: string;
  licenseInfo?: LicenseInfo;
  pskExpired?: boolean;
  pskCreatedAt?: string;
  upskMaxPersonalClients?: number;
  eapMaxPersonalClients?: number;
  upskExpiryEnabled?: boolean;
  upskExpiryPeriod?: number;
  onboardPortalAllowed?: boolean;
  idpType?: string;
  orgName?: string;
  isGuestAdminSponsor?: boolean;
  isGuestAdminOperator?: boolean;
  settings?: AdminUserSettings;
}

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: Admin | null;
}

export interface AuthContextValue extends AuthState {
  method: 'Cookie';
  login: (options?: any) => Promise<void>;
  logout: () => void;
  updateUserProps: (
    readonly: boolean,
    licenseType?: 'base' | 'trial' | 'subscription',
    deviceAdminTokenExpiresAt?: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: Admin | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: Admin;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitializeAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isAuthenticated: appConfig.unitTest,
  isInitialized: false,
  user: appConfig.unitTest
    ? {
        orgID: 'mockorg',
        email: 'mock@mock.com',
        name: 'mock',
        role: 'superuser',
        status: 'enabled',
        pskPassphrase: 'abcd1234xyz',
        readOnly: false,
        onboardReadOnly: false,
        deviceAdministrationAllowed: true,
        deviceAdministrationCredential: '',
        deviceAdminTokenExpiresAt: '0001-01-01T00:00:00Z',
        userType: 'local',
        idpType: 'google',
        orgName: 'Mock Organization',
        licenseInfo: {
          type: 'subscription', // base | trial | subscription
          count: 100,
          expiry: '2030-06-06T13:41:19.003781Z',
          trialDone: true,
          contactName: 'admin',
          contactEmail: 'admin@admin.com',
        },
        settings: {
          avaConsentAccept: false,
          avaConsentUpdatedAt: '0001-01-01T00:00:00Z',
        },
      }
    : null,
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Cookie',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUserProps: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const setThemeName = useContext(ThemeContext);
  useEffect(() => {
    if (window.location.href.includes('status/503')) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
      return;
    }
    const initialize = async (): Promise<void> => {
      let loginAPI: string;
      if (appConfig.appname === 'onboard') {
        loginAPI = 'me.user.info';
      } else if (appConfig.appname === 'mc') {
        loginAPI = 'mc.user.me';
      } else {
        loginAPI = 'org.admin.user.me';
      }
      try {
        if (appConfig.appname === 'onboard') {
          const orgID = getOrgIDFromUrl();
          if (orgID) {
            postAPI(
              'prelogin.selfServicePortal.theme.get',
              { orgID },
              (resp) => {
                if (resp.error === '' && Object.keys(resp.data).length !== 0) {
                  appConfig.onboardTheme = resp.data;
                  changeOnboardTheme(
                    resp.data.themeColor,
                    resp.data.headerBackground
                    //   resp.data.headerTextColor
                  );
                }
                populateUserObj(loginAPI);
              }
            );
          } else {
            populateUserObj(loginAPI);
          }
        } else {
          populateUserObj(loginAPI);
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const populateUserObj = (loginAPI: string) => {
    postAPI(loginAPI, null, (resp) => {
      if (!resp.error || resp.error === '') {
        appConfig.platform = resp.data.orgPlatform || 'gcp';
        let userObject: Admin = resp.data;
        if (appConfig.appname === 'onboard') {
          userObject.onboardReadOnly = resp.data.isSelfServicePortalReadOnly;
        } else {
          let readOnlyUser = isUserReadonly(userObject.role);
          userObject.readOnly = readOnlyUser;
        }
        if (appConfig.appname === 'launchpad') {
          if (resp.data.idpType === 'cvcue') {
            window.location.href =
              window.location.origin + '/agni/monitoring/dashboard';
          }
        }
        if (appConfig.appname === 'agni') {
          appConfig.avaConsentAccept = userObject?.settings?.avaConsentAccept;
          postAPI(
            'config.deviceAdmin.settings.get',
            { orgID: userObject.orgID },
            (resp) => {
              if (!resp.error || resp.error === '') {
                appConfig.deviceAdminAllowed = resp.data.config.enabled;
              }
              postAPI(
                'org.license.get',
                { orgID: userObject.orgID },
                (resp) => {
                  if (!resp.error || resp.error === '') {
                    userObject.licenseInfo = resp.data;
                  }
                  // Check if eduroam concourse app is installed
                  postAPI(
                    'config.concourse.app.install.get',
                    {
                      name: 'eduroam',
                      orgID: userObject.orgID,
                    },
                    (resp) => {
                      if (!resp.error || resp.error === '') {
                        appConfig.eduroamEnabled = true;
                      }
                    }
                  );
                  dispatch({
                    type: 'INITIALIZE',
                    payload: {
                      isAuthenticated: true,
                      user: userObject,
                    },
                  });
                }
              );
            }
          );
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: userObject,
            },
          });
        }
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    });
  };

  const changeOnboardTheme = (
    primaryColor: string,
    navBarColor: string
    // navBarTextColor: string
  ) => {
    const curThemeName = 'AristaLightTheme';
    const theme = themeCreator(curThemeName);
    theme.colors.primary = {
      lighter: lighten(primaryColor, 0.85),
      light: lighten(primaryColor, 0.3),
      main: primaryColor,
      dark: darken(primaryColor, 0.2),
    };
    theme.palette.primary = {
      light: lighten(primaryColor, 0.3),
      main: primaryColor,
      dark: darken(primaryColor, 0.2),
      contrastText: '#FFFFFF',
    };
    if (navBarColor) {
      theme.header.background = navBarColor;
    } else {
      theme.header.background = '#FFFFFF';
    }
    if (navBarColor === primaryColor) {
      theme.header.textColor = '#FFFFFF';
    } else {
      theme.header.textColor = primaryColor;
    }

    theme.sidebar.menuItemColorActive = primaryColor;
    theme.sidebar.menuItemIconColorActive = primaryColor;
    theme.components.MuiToggleButton.styleOverrides.root = {
      color: primaryColor,
      background: '#FFFFFF',
      transition: 'all .2s',
      '&:hover, &.Mui-selected, &.Mui-selected:hover': {
        background: primaryColor,
        color: '#FFFFFF',
      },
    };
    theme.components.MuiSwitch.styleOverrides.colorPrimary = {
      '& .MuiSwitch-thumb': {
        backgroundColor: '#FFFFFF',
      },
      '&.Mui-checked .MuiSwitch-thumb': {
        backgroundColor: primaryColor,
      },
    };
    setThemeName(curThemeName);
  };

  const login = async (): Promise<void> => {
    let isAuthenticated = true;
    localStorage.setItem('isAuthenticated', 'Y');
    if (isAuthenticated) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user: null,
        },
      });
    }
  };

  // This is used in UT to update user properties to suit tese cases.
  // It can be extended to support more variants such as license etc.
  const updateUserProps = async (
    readonly: boolean,
    licenseType: 'base' | 'trial' | 'subscription',
    deviceAdminTokenExpiresAt
  ): Promise<void> => {
    if (appConfig.unitTest) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: {
            orgID: 'mockorg',
            email: 'mock@mock.com',
            name: 'mock',
            role: 'superuser',
            status: 'enabled',
            pskPassphrase: 'abcd1234xyz',
            readOnly: readonly,
            onboardReadOnly: false,
            deviceAdministrationAllowed: true,
            deviceAdministrationCredential: '',
            deviceAdminTokenExpiresAt,
            userType: 'local',
            idpType: 'google',
            orgName: 'Mock Organization',
            licenseInfo: {
              type: licenseType ? licenseType : 'subscription', // base | trial | subscription
              count: 100,
              expiry: '2030-06-06T13:41:19.003781Z',
              trialDone: true,
              contactName: 'admin',
              contactEmail: 'admin@admin.com',
            },
          },
        },
      });
    } else {
      if (deviceAdminTokenExpiresAt) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            ...state,
            user: { ...state.user, deviceAdminTokenExpiresAt },
          },
        });
      }
    }
  };

  const logout = (): void => {
    switch (appConfig.appname) {
      case 'agni':
        // API to logout will be called from SignOut UI.
        navigate('/signout');
        dispatch({
          type: 'LOGOUT',
        });
        break;
      case 'launchpad':
        postAPI('org.admin.user.logout', {}, () => {
          if (appConfig.mock === 'true') {
            window.location.href = '/login';
          } else {
            window.location.href = '/launchpad';
          }
          dispatch({
            type: 'LOGOUT',
          });
        });
        break;
      case 'onboard':
        postAPI('me.user.logout', {}, (resp) => {
          if (resp.error === '') {
            let orgID = resp.data.orgID;
            navigate('/' + orgID + '/signout');
          }
          if (appConfig.mock !== 'true') {
            dispatch({
              type: 'LOGOUT',
            });
          }
        });
        break;
      case 'mc':
        postAPI('mc.logout', {}, (resp) => {
          if (appConfig.mock === 'true') {
            window.location.href = '/login';
          } else {
            if (resp.error === '') {
              window.location.href = resp.data.loginURL;
            }
          }
          dispatch({
            type: 'LOGOUT',
          });
        });
        break;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Cookie',
        login,
        logout,
        updateUserProps,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
