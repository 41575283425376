import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface UnsavedChangePromptProps {
  showDialog: any;
  cancelNavigation: any;
  confirmNavigation: any;
}

const UnsavedChangePrompt: FC<UnsavedChangePromptProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={showDialog}
      onClose={cancelNavigation}
      aria-labelledby="alert-unsaved-title"
      aria-describedby="alert-unsaved-description"
    >
      <DialogTitle id="alert-unsaved-title">{t('leave_page')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('unsaved_warning_msg')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelNavigation} color="primary" size="small">
          {t('stay')}
        </Button>
        <Button
          onClick={confirmNavigation}
          color="error"
          variant="contained"
          size="small"
          autoFocus
        >
          {t('leave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsavedChangePrompt;
