import { LanguageSwitcher, Logo, useAuth, Userbox } from "@antara/shared";
import { alpha, Box, styled, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { appConfig } from "src/config";
import { useTranslation } from "react-i18next";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 8;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(8px);
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: 0;
            width: auto;
        }
`
);

function Header({ onLogout }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
  };

  const handleResetPassword = () => {
    navigate("/" + id + "/resetpassword");
  };

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        <Box
          component="span"
          sx={{
            mr: 1,
            display: { xs: "inline-block" },
          }}
        >
          <Logo landingURL={"/" + id} title={t("onboard")} />
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {appConfig.enableLocale && (
          <LanguageSwitcher mode={theme.colors.primary.main === theme.header.background && "dark"}></LanguageSwitcher>
        )}
        <Userbox
          userName={user.name}
          mode="dark"
          logoutCallback={handleLogout}
          resetPassword={user.userType === "local"}
          handleResetPassword={handleResetPassword}
        />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
