import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Box,
  Checkbox,
  IconButton,
  Skeleton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../contexts/GlobalContext';
import Scrollbar from '../../Scrollbar';
import { CardAction } from '../../common/model';
import TableRowAI from '../TableRow';
import { BodyCell, HeadCell, Order, SortOrder } from '../model';

interface InfiniteScrollTableProps {
  rows: any[];
  resourceName: string;
  primaryKey?: string;
  headCells: HeadCell[];
  bodyCells: BodyCell[];
  actions?: CardAction[];
  getActions?(row: any): CardAction[];
  updateHandler?: any;
  deleteHandler?: any;
  defaultSort?: Order;
  defaultSortCol: string;
  noRowsMsgKey: string;
  collapsibleContent?: any;
  collapsibleContentProvider?: any;
  collapsibleDiffProvider?: any;
  collapsibleDetailsProvider?: any;
  scrollCallback?: Function;
  dataLoaded?: boolean;
  fetchDataByChunks?: boolean;
  sortCallback?: Function;
  onRowClick?: Function;
  multiSelect?: boolean;
  onRowSelected?(index: number, row: any, checked: boolean): void;
  rowsSelected?: Map<number, any>;
  onRouteChange?(index: number): void;
  disableScroll?: boolean;
  maxRowOnSelect?: number;
}

const InfiniteScrollTable: FC<InfiniteScrollTableProps> = (
  props: InfiniteScrollTableProps
) => {
  const [order, setOrder] = useState<Order>(
    props.defaultSort ? props.defaultSort : 'asc'
  );
  const { titleHeight } = useContext(GlobalContext);
  const [orderBy, setOrderBy] = useState<string>(props.defaultSortCol);
  const [collapseAll, setCollapseAll] = useState(false);
  const { t }: { t: any } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [rows, setRows] = useState(props.rows);
  const [clientHeight] = useState(window.innerHeight);
  const [allChecked, setAllChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const maxRowsOnSelect = 100;
  useEffect(() => {
    setDataLoaded(props.dataLoaded);
  }, [props.dataLoaded]);

  useEffect(() => {
    // need to clear state if user pressed cancel
    if (props.rowsSelected?.size === 0) {
      setAllChecked(false);
    }
  }, [props.rowsSelected]);

  useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    let sortOrder: SortOrder = {
      orderByField: property,
      sortDirection: isAsc ? 'desc' : 'asc',
    };
    props.sortCallback(sortOrder);
  };

  const handleScroll = () => {
    props.scrollCallback();
  };

  const LoadingSkeleton = () => (
    <Box>
      <Skeleton sx={{ my: 1, mx: 1 }} variant="rectangular" height={50} />
      <Skeleton sx={{ my: 1, mx: 1 }} variant="rectangular" height={50} />
      <Skeleton sx={{ my: 1, mx: 1 }} variant="rectangular" height={50} />
      <Skeleton sx={{ my: 1, mx: 1 }} variant="rectangular" height={50} />
      <Skeleton sx={{ my: 1, mx: 1 }} variant="rectangular" height={50} />
      <Skeleton sx={{ my: 1, mx: 1 }} variant="rectangular" height={50} />
    </Box>
  );

  const onRowSelected = (index: number, row: any, checked: boolean) => {
    if (
      props.rowsSelected.size < (props.maxRowOnSelect || maxRowsOnSelect) ||
      !checked
    ) {
      setOpen(false);
      props.onRowSelected(index, row, checked);
    } else {
      setOpen(true);
    }
  };

  const handleClose = (_) => {
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {dataLoaded ? (
        <>
          {!rows || rows.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 5,
                }}
                variant="h4"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t(props.noRowsMsgKey)}
              </Typography>
            </>
          ) : (
            <TableContainer
              sx={{
                ...(!props.disableScroll &&
                  clientHeight > 840 && {
                    height: `calc(100vh - ${titleHeight + 130}px)`,
                  }),
                ...(!props.disableScroll &&
                  clientHeight <= 840 && { height: '500px' }),
              }}
            >
              <Scrollbar
                onScrolledToBottom={
                  props.fetchDataByChunks ? handleScroll : null
                }
                disableScroll={props.disableScroll}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {props.multiSelect && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={props.rowsSelected.size === rows?.length}
                            indeterminate={
                              props.rowsSelected &&
                              props.rowsSelected.size > 0 &&
                              props.rowsSelected.size < rows?.length
                            }
                            onChange={() => {
                              props.onRowSelected(-1, {}, !allChecked);
                              setAllChecked(!allChecked);
                            }}
                          />
                        </TableCell>
                      )}
                      {props.collapsibleContent && (
                        <TableCell sx={{ width: 50, padding: 1 }}>
                          <Tooltip title="Collapse all rows">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setCollapseAll(!collapseAll)}
                              id="id_button_table_collapse_all"
                            >
                              <ExpandLessIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell sx={{ width: 50 }}>#</TableCell>

                      {props.headCells.map(
                        (headCell) =>
                          headCell.allowSort && (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? 'right' : 'left'}
                              padding={
                                headCell.disablePadding ? 'none' : 'normal'
                              }
                              sortDirection={
                                orderBy === headCell.id ? order : false
                              }
                            >
                              <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                  orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={() => {
                                  handleRequestSort(headCell.id);
                                }}
                              >
                                {t(headCell.label)}
                                {orderBy === headCell.id ? (
                                  <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                      ? 'sorted descending'
                                      : 'sorted ascending'}
                                  </Box>
                                ) : null}
                              </TableSortLabel>
                            </TableCell>
                          )
                      )}
                      {props.headCells.map(
                        (headCell) =>
                          !headCell.allowSort && (
                            <TableCell
                              key={headCell.id}
                              align={headCell.centerAlign ? 'center' : 'left'}
                            >
                              {t(headCell.label)}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRowAI
                        key={index}
                        resourceName={props.resourceName}
                        primaryKey={props.primaryKey}
                        collapseAll={collapseAll}
                        row={row}
                        bodyCells={props.bodyCells}
                        rowID={index + 1}
                        actions={
                          props.actions ? props.actions : props.getActions(row)
                        }
                        collapsibleContent={props.collapsibleContent}
                        collapsibleContentProvider={
                          props.collapsibleContentProvider
                        }
                        collapsibleDiffProvider={props.collapsibleDiffProvider}
                        collapsibleDetailsProvider={
                          props.collapsibleDetailsProvider
                        }
                        updateHandler={props.updateHandler}
                        deleteHandler={props.deleteHandler}
                        onRowClick={props.onRowClick}
                        multiSelect={props.multiSelect}
                        onRowSelected={onRowSelected}
                        selected={
                          props.rowsSelected &&
                          props.rowsSelected.get(index) !== undefined
                            ? true
                            : false
                        }
                        onRouteChange={props.onRouteChange}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          )}
        </>
      ) : (
        <LoadingSkeleton />
      )}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={t('max_row_select', {
          count: props.maxRowOnSelect || maxRowsOnSelect,
        })}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ mb: 2 }}
      />
    </>
  );
};

export default InfiniteScrollTable;
