import { Box, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import AAIListItem from '../AAIListItem';

export type AAITextFieldProps = {
  lastElement?: boolean;
  compact?: boolean;
  compactlist?: boolean;
  readonly?: boolean;
  compactWidth?: boolean;
  valueSuffix?: string;
  wordLimit?: number;
} & TextFieldProps;

export default function AAITextField(props: AAITextFieldProps) {
  const auth = useAuth();
  const { t } = useTranslation();
  const {
    lastElement,
    compact,
    compactlist,
    readonly,
    compactWidth,
    valueSuffix,
    ...TextFieldProps
  } = props;

  return (
    <>
      {auth.user?.readOnly || props.readonly ? (
        <AAIListItem
          label={t(props.label as string)}
          value={`${props.value || '-'} ${valueSuffix ? t(valueSuffix) : ''}`}
          lastElement={props.lastElement}
          multiline={props.multiline}
          compact={props.compactlist}
          wordLimit={props.wordLimit}
          skipTranslate
        />
      ) : (
        <Box
          sx={{ ...(!props.compactWidth && { width: '100%' }) }}
          p={!props.compact && 2}
          py={!props.compact && 1}
        >
          <TextField {...(TextFieldProps as TextFieldProps)} />
        </Box>
      )}
    </>
  );
}
