import { GlobalContext, PageTitle, StatusPage, useAuth } from "@antara/shared";
import SettingsIcon from "@mui/icons-material/Settings";
import Grid from "@mui/material/Grid";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Results from "./Results";

const Setup = () => {
  const { t } = useTranslation();
  const { mobileView } = useContext(GlobalContext);
  const auth = useAuth();

  return (
    <>
      {auth.user.deviceAdministrationAllowed && !mobileView ? (
        <>
          <Helmet>
            <title>{t("Setup")}</title>
          </Helmet>
          <PageTitle heading={t("Setup")} icon={SettingsIcon} subHeading={t("setup_subheading")}></PageTitle>
          <Grid
            sx={{
              px: 4,
            }}
            container
            direction="row"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12} md={8} lg={6}>
              <Results />
            </Grid>
          </Grid>
        </>
      ) : (
        <StatusPage status="403" hideNav />
      )}
    </>
  );
};

export default Setup;
