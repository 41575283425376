import EventIcon from '@mui/icons-material/Event';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, ButtonGroup } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../contexts/GlobalContext';
import { LOCALE_MAP } from '../../translations/i18n';

export interface DateSelectorOption {
  label: string;
  shortLabel?: string;
  value: string;
}

export interface DateSelectorProps {
  config: DateSelectorConfig;
}

export interface DateSelectorConfig {
  /**
   * Date Selector options list
   */
  dateOptions: DateSelectorOption[];

  /**
   * Default selected option in Date Selector
   */
  defaultOption: string;

  /**
   * Show Custom option in Date Selector
   */
  showCustomDateOption?: boolean;

  /**
   * Default Custom Date value
   */
  defaultCustomDate?: Date;

  /**
   * Date Selection Callback Function
   */
  dateChangeCallback?: any;

  /**
   * Date Field Key in API Request
   */
  dateFieldName: string;
}

export const DateSelector: FC<DateSelectorProps> = (props) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { mobileView } = useContext(GlobalContext);
  const [value, setValue] = useState<Date | null>(
    props.config.defaultCustomDate ? props.config.defaultCustomDate : null
  );
  const [open, setOpen] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState(props.config.defaultOption);

  useEffect(() => {
    setSelectedBtn(props.config.defaultOption);
  }, [props.config.defaultOption]);

  const handleClick = (val) => {
    setSelectedBtn(val);
    props.config.dateChangeCallback({
      field: props.config.dateFieldName,
      value: val,
      date: null,
    });
  };

  const handleDateChange = (val) => {
    setValue(val);
    props.config.dateChangeCallback({
      field: props.config.dateFieldName,
      value: 'custom',
      date: val,
    });
  };

  const handleCustomClick = () => {
    setOpen(!open);
    setSelectedBtn('custom');
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        aria-label="text button group"
        sx={{ border: '1px solid lightgray' }}
      >
        {props.config.dateOptions.map((option) => (
          <Button
            id={'id_button_dateSelector_' + option.value}
            key={option.value}
            color={selectedBtn === option.value ? 'primary' : 'inherit'}
            onClick={() => handleClick(option.value)}
            sx={{
              ...(selectedBtn === option.value
                ? { backgroundColor: 'primary' }
                : { backgroundColor: 'white' }),
            }}
          >
            {mobileView
              ? t(option.shortLabel ? option.shortLabel : option.label)
              : t(option.label)}
          </Button>
        ))}
        {props.config.showCustomDateOption && (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={LOCALE_MAP[lang]}
          >
            <DatePicker
              disableMaskedInput={true}
              open={open}
              onClose={() => setOpen(false)}
              label="From Date"
              value={value}
              disableFuture={true}
              onChange={(newValue) => {
                handleDateChange(newValue);
              }}
              renderInput={(params) => {
                return (
                  <Button
                    id="id_button_dateSelector_custom"
                    color={selectedBtn === 'custom' ? 'primary' : 'inherit'}
                    ref={params.inputRef}
                    variant="contained"
                    onClick={handleCustomClick}
                    startIcon={<EventIcon />}
                    sx={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      ...(selectedBtn === 'custom'
                        ? { backgroundColor: 'primary' }
                        : { backgroundColor: 'white' }),
                    }}
                  >
                    {t('Custom')}
                  </Button>
                );
              }}
            />
          </LocalizationProvider>
        )}
      </ButtonGroup>
    </>
  );
};
