import { AAISelect } from "@antara/shared";
import { Box, Button, Card, Grid, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { appConfig } from "src/config";
import { UAParser } from "ua-parser-js";

const Results = () => {
  const { t } = useTranslation();
  const [os, setOS] = useState("macOS"); // support only windows and mac as of now

  const handleOSChange = (e) => {
    setOS(e.target.value);
  };

  const downloadSetup = () => {
    // Download executable
    let a = document.createElement("a");
    if (os === "Windows") {
      a.href = (appConfig.mock ? "" : "/onboard") + "/apps/AGNIDeviceAdmin.exe";
      a.download = "AGNIDeviceAdmin.exe";
    } else if (os === "macOS") {
      a.href = (appConfig.mock ? "" : "/onboard") + "/apps/AGNIDeviceAdmin.dmg";
      a.download = "AGNIDeviceAdmin.dmg";
    }
    document.body.appendChild(a);
    a.click();
  };

  useEffect(() => {
    const parser = new UAParser();
    let parserOS = parser.getOS().name;
    if (parserOS && parserOS.indexOf("Windows") !== -1) {
      setOS("Windows");
    }
  }, []);

  return (
    <Card
      sx={{
        pr: 1,
        pl: 1,
        pt: 1,
        mb: 3,
      }}
    >
      <Box sx={{ minHeight: 100 }}>
        <Grid item xs={12} sm={10}>
          <AAISelect
            id="id_os_select"
            labelId="os_label"
            value={os}
            onChange={handleOSChange}
            label={t("device_os")}
            sx={{ top: 0 }}
            name="deviceOS"
            caption={t("device_os")}
            size="small"
            skipReadonly
          >
            <MenuItem key={"Mac"} value={"macOS"}>
              {t("apple_mac")}
            </MenuItem>
            <MenuItem key={"Windows"} value={"Windows"}>
              {t("msft_win")}
            </MenuItem>
          </AAISelect>
        </Grid>

        <Box p={1}>
          <Typography variant="body1" gutterBottom sx={{ pl: "1em" }}>
            {t("setup_desc")}
          </Typography>
        </Box>

        <Box p={1}>
          <Typography variant="body1" gutterBottom sx={{ pl: "2em" }}>
            {t("setup_step_1")}
          </Typography>
        </Box>

        <Box p={1}>
          <Typography variant="body1" gutterBottom sx={{ pl: "2em" }}>
            {t("setup_step_2")}
          </Typography>
        </Box>

        <Box p={1}>
          <Typography variant="body1" gutterBottom sx={{ pl: "2em" }}>
            {t("setup_step_3")}
          </Typography>
        </Box>

        <Box p={1}>
          <Typography variant="body1" gutterBottom sx={{ pl: "2em" }}>
            {t("setup_step_4")}
          </Typography>
        </Box>

        <Box p={1}>
          <Typography variant="body1" gutterBottom sx={{ pl: "2em" }}>
            {t("setup_step_5")}
          </Typography>
        </Box>

        <Box
          sx={{
            p: 3,
            textAlign: "right",
          }}
        >
          <Button
            id="id_button_download"
            sx={{
              mr: 1,
            }}
            color="primary"
            type="submit"
            variant="contained"
            onClick={downloadSetup}
          >
            {t("Download")}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default Results;
