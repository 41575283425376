import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { SidebarContext } from '../../../contexts/SidebarContext';
import Scrollbar from '../../Scrollbar';
import { CardAction } from '../../common/model';
import CardKeyValue from '../CardKeyValue';
import CardLogo from '../CardLogo';
import CardOverview from '../CardOverview';
import ImageCard from '../ImageCard';

export interface CardListProps {
  primaryKey?: string;
  config: any;
  type: string;
  data: any[];
  actions: any[];
  getActions?(row: any): CardAction[];
  dataLoaded: boolean;
  scrollCallback: Function;
  fetchDataByChunks: boolean;
  deleteHandler?(row: any): void;
  onRowClick: Function;
  onRowSelected?(index: number, row: any, checked: boolean): void;
  multiSelect?: boolean;
  rowsSelected?: Map<number, any>;
  noRowsMsgKey: string;
  noTopbar?: boolean;
  onRouteChange?(index: number): void;
  maxRowOnSelect?: number;
}

export default function CardList(props: CardListProps) {
  const [clientHeight] = useState(window.innerHeight);
  const { titleHeight } = useContext(GlobalContext);
  const { t }: { t: any } = useTranslation();
  const { sidebarName } = useContext(SidebarContext);
  const [open, setOpen] = useState(false);
  const maxRowsOnSelect = 100;

  const handleScroll = () => {
    props.scrollCallback();
  };

  const onRowSelected = (index: number, row: any, checked: boolean) => {
    if (
      props.rowsSelected.size < (props.maxRowOnSelect || maxRowsOnSelect) ||
      !checked
    ) {
      setOpen(false);
      props.onRowSelected(index, row, checked);
    } else {
      setOpen(true);
    }
  };

  const handleClose = (_) => {
    setOpen(false);
  };

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  const LoadingSkeleton = (props: { height: number; columns: number }) => {
    if (props.columns === 3) {
      return (
        <Grid container columnSpacing={3} rowSpacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <Skeleton
              variant="rectangular"
              height={props.height}
              sx={{ borderRadius: 1 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Skeleton
              variant="rectangular"
              height={props.height}
              sx={{ borderRadius: 1 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Skeleton
              variant="rectangular"
              height={props.height}
              sx={{ borderRadius: 1 }}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container columnSpacing={3} rowSpacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              variant="rectangular"
              height={props.height}
              sx={{ borderRadius: 1 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              variant="rectangular"
              height={props.height}
              sx={{ borderRadius: 1 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              variant="rectangular"
              height={props.height}
              sx={{ borderRadius: 1 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Skeleton
              variant="rectangular"
              height={props.height}
              sx={{ borderRadius: 1 }}
            />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      {props.dataLoaded ? (
        <>
          {!props.data || props.data.length === 0 ? (
            <Typography
              sx={{
                py: 5,
              }}
              variant="h4"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {t(props.noRowsMsgKey)}
            </Typography>
          ) : (
            <Box
              my={1}
              sx={{
                ...(clientHeight > 840 && {
                  height: props.noTopbar
                    ? `calc(100vh - ${
                        titleHeight + (sidebarName === 'top_nav' ? 130 : 110)
                      }px)`
                    : `calc(100vh - ${
                        titleHeight + (sidebarName === 'top_nav' ? 170 : 150)
                      }px)`,
                }),
                ...(clientHeight <= 840 && { height: '500px' }),
                overflowY: 'auto',
              }}
            >
              <Scrollbar
                onScrolledToBottom={
                  props.fetchDataByChunks ? handleScroll : null
                }
              >
                <Grid container columnSpacing={3} rowSpacing={2} px={2}>
                  {props.type === 'overview' &&
                    props.data.map((data, i) => {
                      return (
                        <CardOverview
                          {...props.config}
                          primaryKey={props.primaryKey}
                          cardActions={
                            props.actions
                              ? props.actions
                              : props.getActions(data)
                          }
                          deleteHandler={props.deleteHandler}
                          key={i}
                          data={data}
                          style={{ margin: '100px' }}
                          onRowClick={props.onRowClick}
                          id={i + 1}
                          multiSelect={props.multiSelect}
                          onRowSelected={onRowSelected}
                          selected={
                            props.rowsSelected &&
                            props.rowsSelected.get(i) !== undefined
                          }
                        />
                      );
                    })}
                  {props.type === 'keyvalue' &&
                    props.data.map((item, i) => {
                      return (
                        <CardKeyValue
                          {...props.config}
                          primaryKey={props.primaryKey}
                          cardActions={
                            props.actions
                              ? props.actions
                              : props.getActions(item)
                          }
                          deleteHandler={props.deleteHandler}
                          key={i}
                          data={item}
                          onRowClick={props.onRowClick}
                          id={i + 1}
                          multiSelect={props.multiSelect}
                          onRowSelected={onRowSelected}
                          selected={
                            props.rowsSelected &&
                            props.rowsSelected.get(i) !== undefined
                          }
                          onRouteChange={props.onRouteChange}
                        />
                      );
                    })}
                  {props.type === 'logo' &&
                    props.data.map((item, i) => {
                      return (
                        <CardLogo
                          {...props.config}
                          primaryKey={props.primaryKey}
                          key={i}
                          data={item}
                          cardActions={props.actions}
                          onRowClick={props.onRowClick}
                        />
                      );
                    })}
                  {props.type === 'image' &&
                    props.data.map((item, i) => {
                      return (
                        <ImageCard
                          {...props.config}
                          primaryKey={props.primaryKey}
                          key={i}
                          data={item}
                          deleteHandler={props.deleteHandler}
                          cardActions={
                            props.actions
                              ? props.actions
                              : props.getActions(item)
                          }
                          onRowClick={props.onRowClick}
                        />
                      );
                    })}
                </Grid>
              </Scrollbar>
            </Box>
          )}
        </>
      ) : (
        <LoadingSkeleton
          height={
            props.type === 'overview'
              ? 210
              : props.type === 'keyvalue'
              ? 300
              : 250
          }
          columns={props.type === 'logo' || props.type === 'image' ? 4 : 3}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={t('max_row_select', {
          count: props.maxRowOnSelect || maxRowsOnSelect,
        })}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ mb: 2 }}
      />
    </>
  );
}
