import { Box, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import agniBlue from '../../assets/agni-blue.png';
import agniWhite from '../../assets/agni.png';
import { appConfig } from '../../config';
import useAuth from '../../hooks/useAuth';

const LogoWrapperLink = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: 0;
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        justify-content: center;
        width: 100%;
`
);

const LogoWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: 0;
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        justify-content: center;
        width: 100%;
`
);

export const getCompanyLogo = (): any => {
  if (appConfig.appname === 'onboard') {
    const theme = appConfig.onboardTheme;
    if (theme) {
      if (theme.logo) {
        return theme.logo;
      }
    }
  }
};

const getImage = (mode: string, sidebar: boolean): any => {
  let imageSrc: any;
  const companyLogo = getCompanyLogo();
  if (companyLogo) {
    imageSrc = companyLogo;
  } else if (sidebar && mode === 'light') {
    imageSrc = agniBlue;
  } else {
    imageSrc = agniWhite;
  }
  return imageSrc;
};

const getWidth = (size: string): number => {
  if (size === 'small') {
    return 100;
  } else {
    return 150;
  }
};

function Logo({
  sidebar,
  title,
  landingURL,
  showAppNameInMobile,
}: {
  sidebar?: boolean;
  title?: string;
  landingURL?: string;
  showAppNameInMobile?: boolean;
}) {
  let theme = useTheme();
  const { user } = useAuth();
  const { t }: { t: any } = useTranslation();
  const contrastHeader = theme.colors.primary.main !== theme.header.background;
  // const aristaLogo = theme.colors.primary.main === '#326297';
  const [imageSrc, setImageSrc] = useState<string>();
  const [company, setCompany] = useState('arista');
  const [appName, setAppName] = useState<string>();

  const useWhiteColor = (): boolean => {
    if (appConfig.appname === 'onboard') {
      if (contrastHeader) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setCompany('arista');
    if (appConfig.appname === 'onboard') {
      setImageSrc(getImage(theme.palette.mode, sidebar));
      const onboardTheme = appConfig.onboardTheme;
      setAppName('Self-Service Portal');
      if (onboardTheme?.portalName) {
        setAppName(onboardTheme.portalName);
      }
    } else {
      if (appConfig.appname !== 'agni') {
        setAppName(title);
      } else {
        if (user.orgName) {
          setAppName(user.orgName);
        }
      }
      setImageSrc(getImage(theme.palette.mode, sidebar));
    }
  }, [title, company]);

  const MobileLogo = (
    <img
      alt="Arista"
      src={getImage(theme.palette.mode, sidebar)}
      height={45}
      width={getWidth('small')}
    ></img>
  );

  const logoImage = (
    <>
      <img alt="" src={imageSrc} height={50}></img>

      {appName && (
        <>
          <Box
            display="flex"
            component="span"
            justifyContent="center"
            flexWrap="wrap"
            alignContent={'center'}
          >
            <Typography
              variant="h3"
              sx={{
                ...(useWhiteColor()
                  ? { color: 'white' }
                  : { color: `${theme.header.textColor}` }),
                px: 0.5,
              }}
            >
              ｜
            </Typography>
          </Box>
          <Box
            display="flex"
            component="span"
            justifyContent="center"
            alignItems={'center'}
            alignContent={'center'}
          >
            <Typography
              variant="h4"
              fontSize={17}
              sx={{
                ...(useWhiteColor()
                  ? { color: 'white' }
                  : { color: `${theme.header.textColor}` }),
              }}
            >
              {t(appName)}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
  return (
    <>
      <Box
        component="span"
        sx={{
          display: { sm: 'flex', xs: 'none' },
        }}
      >
        {landingURL && (
          <LogoWrapperLink
            id="id_button_logo"
            to={landingURL}
            sx={{
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            {logoImage}
          </LogoWrapperLink>
        )}
        {!landingURL && (
          <LogoWrapper sx={{ justifyContent: 'center' }}>
            {logoImage}
          </LogoWrapper>
        )}
      </Box>

      <Box
        component="span"
        sx={{
          display: { sm: 'none', xs: 'flex' },
          flexDirection: 'column',
        }}
      >
        {landingURL ? (
          <LogoWrapperLink to={landingURL} sx={{ justifyContent: 'center' }}>
            {MobileLogo}
          </LogoWrapperLink>
        ) : (
          <LogoWrapper sx={{ justifyContent: 'center' }}>
            {MobileLogo}
          </LogoWrapper>
        )}
        {appName && showAppNameInMobile && (
          <Box display="flex" component="span" justifyContent="center">
            <Typography
              variant="h4"
              sx={{
                ...(useWhiteColor()
                  ? { color: 'white' }
                  : { color: `${theme.header.textColor}` }),
              }}
            >
              {t(appName)}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

export default Logo;
