import {
  Divider,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTruncatedName } from '../../utils/stringutils';

export interface AAIListItemProps {
  label: any;
  value: any;
  lastElement: boolean;
  multiline?: boolean;
  labelDecorator?(label: any): JSX.Element;
  compact?: boolean;
  skipTranslate?: boolean;
  wordLimit?: number;
}

export default function AAIListItem(props: AAIListItemProps) {
  const { t } = useTranslation();
  return (
    <>
      {props.multiline ? (
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="subtitle1">{t(props.label)}</Typography>
          </Grid>
          <Grid item sx={{ ml: 'auto' }}>
            <Typography>
              <pre
                style={{
                  fontFamily: 'inherit',
                  margin: 0,
                  marginTop: '2px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {props.skipTranslate ? props.value : t(props.value)}
              </pre>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <ListItem sx={{ py: 1, px: props.compact ? 1 : 3 }}>
          <ListItemText
            primary={
              <Typography variant="subtitle1">{t(props.label)}</Typography>
            }
          />
          {props.labelDecorator
            ? props.labelDecorator(props.value)
            : props.value
            ? getTruncatedName(
                props.skipTranslate ? props.value : t(props.value),
                props.wordLimit ? props.wordLimit : 150
              )
            : '-'}
        </ListItem>
      )}
      {!props.lastElement && <Divider variant="middle"></Divider>}
    </>
  );
}
