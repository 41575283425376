/* Source - https://dev.to/bangash1996/detecting-user-leaving-page-with-react-router-dom-v602-33ni */

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { appConfig } from '../config';
import { useBlocker } from './useBlocker';

export function useCallbackPrompt(when: boolean): (boolean | (() => void))[] {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<string>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setLastLocation(null);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      // in if condition we are checking next location and current location are equals or not
      let nextPath: string = nextLocation.location.pathname;
      if (nextPath.startsWith(appConfig.basename)) {
        nextPath = '/' + nextPath.substring(appConfig.basename.length);
      }
      let currentPath = location.pathname;
      let skipWarning: boolean;
      if (
        (currentPath.startsWith('/concourse/explore/') &&
          nextPath.startsWith('/concourse/installed-apps/')) ||
        (currentPath === '/access/segments' &&
          nextPath.startsWith('/access/segments/')) ||
        (currentPath === '/config/administration' &&
          nextPath.startsWith('/config/administration/'))
      ) {
        skipWarning = true;
      }
      if (
        currentPath.endsWith('/new') ||
        currentPath.includes('/clone_') ||
        currentPath.endsWith('/add') ||
        skipWarning
      ) {
        const lastIndex = currentPath.lastIndexOf('/');
        if (lastIndex !== -1) {
          currentPath = currentPath.substring(0, lastIndex);
        }
        if (nextPath.includes(currentPath + '/') || skipWarning) {
          setLastLocation(nextPath);
          setConfirmedNavigation(true);
          return false;
        }
      }
      if (!confirmedNavigation && nextPath !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextPath);
        return false;
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation, location]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation);

      // Clean-up state on confirmed navigation
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
