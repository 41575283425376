import { axiosMockAdapterInstance, getRandomInt } from "@antara/shared";

export const mockPassphrase = () => {
  axiosMockAdapterInstance
    .onPost("/api/me.user.passphrase.generate")
    .reply(() => {
      const errorList = ["", "internal_error"];

      return [
        200,
        {
          data: { pskPassphrase: "aw9eq92u90uw9fhsg09weRad" },
          error: errorList[getRandomInt(0, errorList.length - 1)],
        },
      ];
    });

  axiosMockAdapterInstance
    .onPost("/api/me.user.passphrase.update")
    .reply(() => {
      const errorList = [""];

      return [
        200,
        {
          data: { pskPassphrase: "aw9eq92u90uw9fhsg09weRad" },
          error: errorList[getRandomInt(0, errorList.length - 1)],
        },
      ];
    });
};
