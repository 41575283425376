import { differenceInMinutes, parseISO } from "date-fns";
export const getDeviceType = (deviceType) => {
  if (deviceType) {
    if (deviceType.indexOf("Mac") !== -1 || deviceType.indexOf("iOS") !== -1) {
      return "Apple";
    }

    if (deviceType.indexOf("Android") !== -1) {
      return "Android";
    }

    if (deviceType.indexOf("Chrome OS") !== -1) {
      return "Chrome OS";
    }

    if (deviceType.indexOf("Windows") !== -1) {
      return "Windows";
    }
    if (deviceType.indexOf("Linux") !== -1) {
      return "Linux";
    }
  }
  // default to Apple as of now
  return "Apple";
};

export const isDateGreaterThanCurrent = (dateToCheck) => {
  const currentDate = new Date();
  const diffValue = differenceInMinutes(parseISO(dateToCheck), parseISO(currentDate.toISOString()));
  return diffValue > 5;
};
