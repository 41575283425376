import { PageTitleWrapper, postAPI, showNotification, useAuth } from "@antara/shared";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Header from "../register/Header";
import Results from "./Results";

const ViewPassphrase = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { logout } = useAuth();
  const [networks, setNetworks] = useState([]);

  const onLogout = async (): Promise<void> => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const getNetworks = useCallback(async () => {
    postAPI("me.client.onboard.info", {}, (resp) => {
      if (resp.error === "") {
        if (resp.data?.networks) {
          let pskNetworks = resp.data.networks.filter((network) => network.authType === "psk");
          setNetworks(pskNetworks);
        }
      } else {
        showNotification(enqueueSnackbar, t("client_get_err"), "error");
      }
    });
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    getNetworks();
  }, [getNetworks]);

  return (
    <>
      <Header onLogout={onLogout}></Header>
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
          pt: `${theme.header.height}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box flexGrow={1}>
            <Helmet>
              <title>{t("personal_passphrase")}</title>
            </Helmet>
            <PageTitleWrapper>
              <Grid container alignItems="center" justifyContent="center">
                <Box display="flex" alignItems="center">
                  <Box>
                    <Typography variant="h3" component="h3" gutterBottom>
                      {t("personal_passphrase")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </PageTitleWrapper>

            <Grid
              sx={{
                px: 4,
              }}
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={4}
            >
              <Grid item xs={12} md={8} lg={6}>
                <Results networks={networks} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewPassphrase;
