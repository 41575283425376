import { Theme, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

export const getLocationText = (location: string, limit: number): string => {
  if (!location) {
    return '';
  }

  if (location.length <= limit) {
    return location;
  }

  let max = location.length;
  let last = location.lastIndexOf('/');
  let str = '';
  while (max - last < limit) {
    str = location.substring(last);
    last = location.lastIndexOf('/', last - 1);
  }
  return '*' + str;
};

export const getTruncatedName = (
  name: string,
  lengthLimit: number
): JSX.Element => {
  const displayEle = (name, noWrap = false) => {
    return (
      <Typography
        noWrap={noWrap}
        sx={{
          maxWidth: '50%',
        }}
        // variant="subtitle2"
      >
        {name}
      </Typography>
    );
  };
  if (name && name.length > lengthLimit) {
    return <Tooltip title={name}>{displayEle(name, true)}</Tooltip>;
  }
  return displayEle(name);
};

export const getTruncatedNameByWidth = (
  name: string,
  lengthLimit: number,
  width: number,
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
    | 'inherit',
  sx?: SxProps<Theme>
): JSX.Element => {
  const displayEle = (name, noWrap = false) => {
    return (
      <Typography
        noWrap={noWrap}
        sx={{
          maxWidth: width + 'px',
          ...sx,
        }}
        variant={variant}
      >
        {name || '-'}
      </Typography>
    );
  };
  if (name && name.length > lengthLimit) {
    return <Tooltip title={name}>{displayEle(name, true)}</Tooltip>;
  }
  return displayEle(name);
};

export const getTruncatedNameByText = (text: string) => {
  return getTruncatedNameByWidth(text, 30, 200, 'body1');
};

export const getTruncatedNameByTextBold = (text: string) => {
  return getTruncatedNameByWidth(text, 25, 200, 'h5');
};

export const getTruncatedNameByLongText = (text: string) => {
  return getTruncatedNameByWidth(text, 70, 450, 'body1');
};

export const getTruncatedSubtitleByLongText = (text: string) => {
  return getTruncatedNameByWidth(text, 70, 450, 'subtitle1');
};

export const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const uint8ArrayToString = (uint8Array: Uint8Array): string => {
  const decoder = new TextDecoder();
  return decoder.decode(uint8Array);
};
