import { Box, Grid, Switch, SwitchProps, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import AAIListItem from '../AAIListItem';

export type AAISwitchProps = {
  lastElement?: boolean;
  status_caption?: string;
  variant: 'caption-value' | 'caption' | 'value';
  value_decorator?(label: any): JSX.Element;
  hide_switch?: boolean;
  nonresponsive?: boolean;
  readOnly?: boolean;
  compact?: boolean;
  compactlist?: boolean;
} & SwitchProps;

export default function AAISwitch(props: AAISwitchProps) {
  const auth = useAuth();
  const { t } = useTranslation();
  const {
    hide_switch,
    nonresponsive,
    value_decorator,
    compact,
    compactlist,
    lastElement,
    ...switchProps
  } = props;

  const isReadonly = () => {
    return auth.user?.readOnly || props.readOnly;
  };

  return (
    <>
      {isReadonly() ? (
        <AAIListItem
          label={props.status_caption}
          value={props.value || (!props.value_decorator ? 'False' : false)}
          lastElement={props.lastElement}
          labelDecorator={props.value_decorator}
          compact={compactlist}
        />
      ) : (
        <Box
          p={props.compact ? 0 : props.variant === 'caption' ? 1 : 2}
          pt={props.compact ? 0 : 1}
        >
          <Grid
            item
            xs={12}
            sm={props.nonresponsive ? 12 : 10}
            lg={props.nonresponsive ? 12 : 8}
          >
            <Grid container alignItems="center" flexWrap={'nowrap'}>
              {props.variant === 'caption-value' && (
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  justifyContent="left"
                >
                  {props.status_caption && (
                    <Typography variant="subtitle2" sx={{ pr: 2 }}>
                      {t(props.status_caption)}:
                    </Typography>
                  )}
                  <Typography variant="subtitle2">
                    {props.value_decorator
                      ? props.value_decorator(props.value)
                      : props.value}
                  </Typography>
                </Grid>
              )}
              {props.variant === 'caption' && (
                <Grid item xs={6}>
                  {props.status_caption && (
                    <Typography variant="subtitle2">
                      {t(props.status_caption)}:
                    </Typography>
                  )}
                </Grid>
              )}
              {props.variant === 'value' && (
                <Grid item>
                  <Typography variant="body1" fontWeight={500}>
                    {props.value_decorator
                      ? props.value_decorator(props.value)
                      : props.value}
                  </Typography>
                </Grid>
              )}

              {!props.hide_switch && props.variant !== 'caption' && (
                <Grid
                  item
                  sx={{
                    ml: 1,
                  }}
                >
                  <Switch {...switchProps} />
                </Grid>
              )}
              {props.variant === 'caption' && (
                <Grid item xs={6}>
                  <Switch {...switchProps} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
