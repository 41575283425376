import { Box, CircularProgress, useTheme } from '@mui/material';
import React from 'react';
import agniLogo from '../../assets/agni.png';
import agniBlueLogo from '../../assets/agni-blue.png';

import { appConfig } from '../../config';

function AppInit() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {appConfig.appname === 'onboard' ? (
        <CircularProgress />
      ) : (
        <img
          src={theme.palette.mode === 'light' ? agniBlueLogo : agniLogo}
          height="70px"
          alt="Arista"
        />
      )}
    </Box>
  );
}

export default AppInit;
