import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import PropTypes from 'prop-types';
import React, { FC, ReactNode } from 'react';

interface LabelProps {
  className?: string;
  color?:
    | 'primary'
    | 'black'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'purple';
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

const LabelWrapper = styled('span')(
  ({ theme }) => `
      background-color: ${
        theme.palette.mode === 'light'
          ? theme.colors.alpha.black[5]
          : theme.colors.alpha.white[5]
      };
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      border-radius: ${theme.general.borderRadius};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: ${theme.spacing(3)};
      
      &.MuiLabel {
        
        &-primary {
          background-color: ${
            theme.palette.mode === 'light'
              ? theme.colors.primary.lighter
              : theme.colors.primary.dark
          };   
          border: 1px solid ${
            theme.palette.mode === 'light'
              ? 'lightgray'
              : theme.colors.primary.dark
          };
        }
        
        &-secondary {
          background-color: ${
            theme.palette.mode === 'light'
              ? theme.colors.secondary.lighter
              : theme.colors.secondary.dark
          };
          border: 1px solid ${
            theme.palette.mode === 'light'
              ? 'lightgray'
              : theme.colors.secondary.dark
          };
        }
        
        &-success {
          background-color: ${
            theme.palette.mode === 'light'
              ? theme.colors.success.lighter
              : theme.colors.success.dark
          };
          border: 1px solid ${
            theme.palette.mode === 'light'
              ? 'lightgray'
              : theme.colors.success.dark
          };
        }
        
        &-warning {
          background-color: ${
            theme.palette.mode === 'light'
              ? theme.colors.warning.lighter
              : theme.colors.warning.dark
          };
          border: 1px solid ${
            theme.palette.mode === 'light'
              ? 'lightgray'
              : theme.colors.warning.dark
          };
        }
              
        &-error {
          background-color: ${
            theme.palette.mode === 'light'
              ? theme.colors.error.lighter
              : theme.colors.error.dark
          };
          border: 1px solid ${
            theme.palette.mode === 'light'
              ? 'lightgray'
              : theme.colors.error.dark
          };
        }
        
        &-info {
          background-color: ${
            theme.palette.mode === 'light'
              ? theme.colors.info.lighter
              : theme.colors.info.dark
          };
          border: 1px solid ${
            theme.palette.mode === 'light'
              ? 'lightgray'
              : theme.colors.info.dark
          };
        }

        &-purple {
          background-color: ${
            theme.palette.mode === 'light' ? '#EDE3F1' : '#9E4784'
          };
          border: 1px solid ${
            theme.palette.mode === 'light' ? 'lightgray' : '#9E4784'
          };
        }
      }
`
);

const Label: FC<LabelProps> = ({
  className = '',
  color = 'secondary',
  sx,
  children,
  ...rest
}) => {
  return (
    <LabelWrapper sx={{ ...sx }} className={'MuiLabel-' + color} {...rest}>
      {children}
    </LabelWrapper>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'black',
    'secondary',
    'error',
    'warning',
    'success',
    'info',
    'purple',
  ]),
};

export default Label;
