import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import RefreshIcon from '@mui/icons-material/Refresh';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';

import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  styled,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, {
  FC,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import CardList from '../../components/Card/CardList';
import DeletePopup from '../../components/DeletePopup';
import Filter, { FilterConfig } from '../../components/Filter';
import MessagePopup from '../../components/MessagePopup';
import InfiniteScrollTable from '../../components/Table/InfiniteScrollTable';
import { BodyCell, HeadCell, SortOrder } from '../../components/Table/model';
import { applyFilters } from '../../components/Table/PaginationTable/utils';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAuth from '../../hooks/useAuth';
import useRefMounted from '../../hooks/useRefMounted';
import { CheckBeforeDeleteConfig } from '../../utils';
import {
  createAndDownloadCSV,
  postAPI,
  postAPIWithResponse,
  postAPIWithResponseAsync,
} from '../../utils/httputils';
import { showNotification } from '../../utils/notifyutils';
import { isHexadecimal } from '../../utils/validatorutils';
import { CrudConfig } from './CrudConfig';
import DeleteErrorListPopup, { ErrorResp } from './DeleteErrorListPopup';

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
      @media (max-width: ${theme.breakpoints.values.md}px) {
        .MuiTabs-scrollableX {
          overflow-x: auto !important;
        }
  
        .MuiTabs-indicator {
            box-shadow: none;
        }
      }
      `
);

interface ResultsProps {
  crudConfig: CrudConfig;
  forceUpdate: boolean;
  queryText?: string;
  filter?: any;
  onRefresh?();
}

interface DBFilter {
  field: string;
  value: string;
}

const Results: FC<ResultsProps> = (props: ResultsProps) => {
  const auth = useAuth();
  const theme = useTheme();
  const [selectedEntityData, setSelectedEntityData] = useState({});
  const [query, setQuery] = useState<string>('');
  const [filters, setFilters] = useState({});
  const [filterConfig, setFilterConfig] = useState<any>();
  const [cardActions, setCardActions] = useState(props.crudConfig.actions);
  const [selectActions, setSelectActions] = useState(
    props.crudConfig.rowSelectActions
  );
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [toggleView, setToggleView] = useState<string | null>(
    props.crudConfig.toggleView ? props.crudConfig.toggleView : 'table_view'
  );
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [showMessagePopUp, setShowMessagePopUp] = useState(false);
  const [referenceMsg, setReferenceMsg] = useState([]);
  const [openConfirmBulkDelete, setOpenConfirmBulkDelete] =
    useState<boolean>(false);
  const [cursor, setCursor] = useState('');
  const [prevCursor, setPrevCursor] = useState('');
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const limit = 30;
  const { mobileView, zoneID } = useContext(GlobalContext);
  const [rowsSelected, setRowsSelected] = useState<Map<number, any>>(
    new Map<number, any>()
  );
  const [multiSelect, setMultiSelect] = useState(false);
  const deleteApiReferencePath = 'config.entity.references.get';
  const [headCells, setHeadCells] = useState<HeadCell[]>([]);
  const [bodyCells, setBodyCells] = useState<BodyCell[]>([]);
  const [rowCount, setRowCount] = useState<number>(undefined);
  const [selectAll, setSelectAll] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [deleteErrorObj, setDeleteErrorObj] = useState<ErrorResp>();
  const rowsSelectedRef = useRef<Map<number, any>>(new Map<number, any>());
  const [showMaxLimitWarning, setShowMaxLimitWarning] = useState(false);
  const maxRowsOnSelect = 100;

  /** Get data from backend */
  const getListData = useCallback(
    async (reset: boolean) => {
      if (!filterConfig) {
        return;
      }
      if (reset) {
        setRowsSelected(new Map());
        rowsSelectedRef.current = null;
        setCursor('');
        setPrevCursor('');
      }
      let reqMap = new Map<string, string>();
      if (props.crudConfig.listApiRequestFields) {
        Object.keys(props.crudConfig.listApiRequestFields).forEach((key) => {
          reqMap[key] = props.crudConfig.listApiRequestFields[key];
        });
      }
      let pageFilters = {};
      for (let f of filterConfig) {
        if (f.pageFilter === true) {
          pageFilters[f.field] = '1';
        }
      }
      if (props.crudConfig.fetchDataByChunks) {
        setReqFilters(pageFilters, reqMap);
        if (reset) {
          setDataLoaded(false);
        }
        reqMap['limit'] = limit;
      }
      reqMap['orgID'] = auth.user.orgID;
      if (zoneID !== 0) {
        reqMap['zoneID'] = zoneID;
      }
      let prevData;
      if (isMountedRef.current) {
        let loaded = false;
        if (!loaded) {
          postAPI(props.crudConfig.listApiPath, reqMap, (resp) => {
            if (!resp.error || resp.error === '') {
              if (props.crudConfig.saveDataOnRouteChange) {
                const prevDataStr = localStorage.getItem(
                  'prevData_' + props.crudConfig.resourceName
                );
                if (prevDataStr) {
                  localStorage.removeItem(
                    'prevData_' + props.crudConfig.resourceName
                  );
                  prevData = JSON.parse(prevDataStr);
                  let newItemAdded = false;
                  if (
                    prevData.data &&
                    prevData.data.length > 0 &&
                    prevData.data[0]['updatedAt']
                  ) {
                    const oldDate = new Date(prevData.data[0]['updatedAt']);
                    let d = resp.data[props.crudConfig.listApiResponseHeader];
                    if (d && d.length > 0) {
                      const newDate = new Date(d[0]['updatedAt']);
                      if (newDate > oldDate) {
                        newItemAdded = true;
                        prevData = undefined;
                      }
                    }
                  }
                  if (!newItemAdded && !props.crudConfig.fetchDataByChunks) {
                    setCursor(prevData.cursor);
                    setFilteredData(prevData.data);
                    setDataLoaded(true);
                    moveToPrevPosition(prevData.rowID);
                    loaded = true;
                  }
                }
              }
              if (!loaded) {
                if (resp.data.count !== undefined) {
                  setRowCount(resp.data.count);
                }
                if (props.crudConfig.fetchDataByChunks) {
                  if (prevData) {
                    setFilteredData(prevData.data);
                    moveToPrevPosition(prevData.rowID);
                  } else {
                    setFilteredData(
                      resp.data[props.crudConfig.listApiResponseHeader]
                    );
                  }
                  setCursor(resp.data['cursor']);
                } else {
                  const filterData = applyFilters(
                    resp.data[props.crudConfig.listApiResponseHeader],
                    query,
                    filters,
                    props.crudConfig.fieldsForContainsFilter,
                    props.crudConfig.defaultSort
                      ? props.crudConfig.defaultSort
                      : 'asc',
                    props.crudConfig.defaultSortCol
                      ? props.crudConfig.defaultSortCol
                      : props.crudConfig.nameField,
                    props.crudConfig.boolDropdownField
                  );
                  setFilteredData(filterData);
                }
                props.crudConfig.shareRowData &&
                  props.crudConfig.shareRowData([
                    ...filteredData,
                    ...resp.data[props.crudConfig.listApiResponseHeader],
                  ]);
                setDataLoaded(true);
              } else {
                showNotification(
                  enqueueSnackbar,
                  t(props.crudConfig.resourceName + '_list_err_' + resp.error),
                  'error'
                );
              }
            } else {
              showNotification(
                enqueueSnackbar,
                t(props.crudConfig.resourceName + '_list_err_' + resp.error),
                'error'
              );
            }
          });
        }
      }
    },
    [
      isMountedRef,
      auth,
      enqueueSnackbar,
      t,
      props.crudConfig.resourceName,
      props.crudConfig.listApiPath,
      props.crudConfig.listApiResponseHeader,
      props.crudConfig.listApiRequestFields,
      props.crudConfig.fetchDataByChunks,
      filters,
      props.crudConfig.boolDropdownField,
      props.crudConfig.defaultSortCol,
      props.crudConfig.nameField,
      query,
      props.crudConfig.fieldToDBFieldMap,
      props.crudConfig.defaultSort,
      filterConfig,
      zoneID,
    ]
  );

  useEffect(() => {
    let savedFilters;
    let fConfig: FilterConfig[];
    let filterAsObject = false;
    let tab, resourcePrefs;
    let currentPrefs = JSON.parse(localStorage.getItem('prefs'));
    if (currentPrefs) {
      resourcePrefs = currentPrefs[props.crudConfig.resourceName];
    }
    tab = resourcePrefs?.tab;
    if (props.crudConfig.filterConfig) {
      if (Array.isArray(props.crudConfig.filterConfig)) {
        fConfig = props.crudConfig.filterConfig;
      } else {
        fConfig =
          props.crudConfig.filterConfig[
            tab || props.crudConfig.defaultTabField || 'all'
          ];

        if (!fConfig) {
          fConfig =
            props.crudConfig.filterConfig[props.crudConfig.defaultTabField];
        }

        filterAsObject = true;
      }
    }
    if (!props.crudConfig.disableFilterCaching) {
      let filterConfigured: boolean;
      if (currentPrefs) {
        if (filterAsObject && resourcePrefs) {
          if (!tab || tab === 'all') {
            resourcePrefs['tab'] = props.crudConfig.defaultTabField || 'all';
          }
          if (resourcePrefs['tab']) {
            resourcePrefs =
              currentPrefs[
                props.crudConfig.resourceName + '_' + resourcePrefs['tab']
              ];
            if (resourcePrefs) {
              resourcePrefs['tab'] = tab;
            }
          }
        }
        if (resourcePrefs) {
          // check for prefs cache version and if mismatch, clear cache entry for the resource
          if (props.crudConfig.prefsCacheVersion && !filterAsObject) {
            if (
              !resourcePrefs['version'] ||
              resourcePrefs['version'] !== props.crudConfig.prefsCacheVersion
            ) {
              currentPrefs[props.crudConfig.resourceName] = {};
            }

            currentPrefs[props.crudConfig.resourceName]['version'] =
              props.crudConfig.prefsCacheVersion;

            resourcePrefs = currentPrefs[props.crudConfig.resourceName];
          }

          if (fConfig) {
            setFilterUIConfig(fConfig, resourcePrefs);
            filterConfigured = true;
          }
          savedFilters = resourcePrefs['filters'];
          if (savedFilters) {
            if (fConfig) {
              for (let index = 0; index < fConfig.length; index++) {
                if (
                  fConfig[index].initVal &&
                  savedFilters[fConfig[index].field]
                ) {
                  savedFilters[fConfig[index].field] = fConfig[index].initVal;
                }
              }
            }
            setFilters(resourcePrefs['filters']);
          }
          if (mobileView && props.crudConfig.showToggleBtn) {
            setToggleView('card_view');
          } else if (resourcePrefs['view']) {
            setToggleView(resourcePrefs['view']);
          }
        } else {
          currentPrefs[props.crudConfig.resourceName] = {};
        }
      } else {
        currentPrefs = {};
        currentPrefs[props.crudConfig.resourceName] = {};
      }
      if (!filterConfigured) {
        configureFilters(fConfig, currentPrefs);
      }

      currentPrefs[props.crudConfig.resourceName]['tab'] =
        tab || props.crudConfig.defaultTabField || 'all';
      localStorage.setItem('prefs', JSON.stringify(currentPrefs));

      if (mobileView && props.crudConfig.showToggleBtn) {
        setToggleView('card_view');
      }
    } else {
      configureFilters(fConfig);
    }

    // Check if headCells is array. if it's an array then we have same columns in grid for all tabs.
    // If it's an object then we have different columns per tabs.
    if (Array.isArray(props.crudConfig.headCells)) {
      setHeadCells(props.crudConfig.headCells as HeadCell[]);
      setBodyCells(props.crudConfig.bodyCells as BodyCell[]);
    } else {
      let tabFilter;
      if (savedFilters) {
        tabFilter = savedFilters[props.crudConfig.tabFilterField];
      }
      if (tabFilter && props.crudConfig.bodyCells[tabFilter]) {
        setHeadCells(props.crudConfig.headCells[tabFilter]);
        setBodyCells(props.crudConfig.bodyCells[tabFilter]);
      } else {
        setHeadCells(
          props.crudConfig.headCells[props.crudConfig.defaultTabField || 'all']
        );
        setBodyCells(
          props.crudConfig.bodyCells[props.crudConfig.defaultTabField || 'all']
        );
      }
    }
    if (!tab && !props.crudConfig.disableFilterCaching) {
      handleTabsChange(null, props.crudConfig.defaultTabField || 'all');
    }
  }, [
    props.crudConfig.filterConfig,
    props.crudConfig.resourceName,
    props.crudConfig.bodyCells,
    props.crudConfig.headCells,
    props.crudConfig.tabFilterField,
  ]);

  const setReqFilters = (pageFilters: any, reqMap: any): any => {
    let dbFields: DBFilter[] = [];
    Object.keys(filters).forEach((key) => {
      let dbField: any;
      if (props.crudConfig.fieldToDBFieldMap) {
        dbField = props.crudConfig.fieldToDBFieldMap[key]
          ? props.crudConfig.fieldToDBFieldMap[key]
          : key;
      } else {
        dbField = key;
      }
      if (pageFilters[dbField]) {
        reqMap[dbField] = filters[key];
      } else {
        if (filters[key] || filters[key] === 0) {
          dbFields.push({ field: dbField, value: filters[key] });
        }
      }
    });
    if (dbFields.length > 0) {
      reqMap['filters'] = dbFields;
    }
    if (query !== '') {
      if (props.crudConfig.skipFormatQuery) {
        reqMap['query'] = query;
      } else {
        reqMap['query'] = isHexadecimal(query)
          ? query.replaceAll(/[:\-]/g, '')
          : query;
      }
    }
    return reqMap;
  };

  const configureFilters = (
    fConfig: FilterConfig[],
    currentPrefs: any = undefined
  ) => {
    setFilterConfig(fConfig);
    let initFilters = {};
    let initFilterAdded: boolean;
    for (let index = 0; index < fConfig.length; index++) {
      if (fConfig[index].initVal || fConfig[index].initVal === 0) {
        initFilters[fConfig[index].field] = fConfig[index].initVal;
        initFilterAdded = true;
        // if pagefilter, set it in prefs outside of filter too.
        if (currentPrefs && fConfig[index].pageFilter) {
          currentPrefs[props.crudConfig.resourceName][fConfig[index].field] =
            fConfig[index].initVal;
        }
      }
    }
    if (initFilterAdded) {
      setFilters(initFilters);
      if (currentPrefs) {
        currentPrefs[props.crudConfig.resourceName]['filters'] = initFilters;
        localStorage.setItem('prefs', JSON.stringify(currentPrefs));
      }
    }
  };

  const moveToPrevPosition = (rowID: string) => {
    let row = +rowID;
    if (row > 10) {
      row--;
      setTimeout(() => {
        const element = document.getElementById('row_' + row);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.log('element not found', row);
        }
      }, 500);
    }
  };

  const setFilterUIConfig = (fConfig: FilterConfig[], resourcePrefs: any) => {
    if (fConfig) {
      if (
        resourcePrefs['tab'] &&
        !Array.isArray(props.crudConfig.filterConfig)
      ) {
        fConfig =
          props.crudConfig.filterConfig[resourcePrefs['tab']] || fConfig;
      }
      let querySet = false;
      for (let index = 0; index < fConfig.length; index++) {
        fConfig[index].initVal =
          typeof fConfig[index].initVal === 'number' ? 0 : '';
        if (fConfig[index].field && resourcePrefs[fConfig[index].field]) {
          if (!fConfig[index].initVal) {
            fConfig[index].initVal = resourcePrefs[fConfig[index].field];
          }
          if (fConfig[index].field == 'query') {
            querySet = true;
            setQuery(fConfig[index].initVal);
          }
        }
      }
      if (!querySet) {
        setQuery('');
      }
      setFilterConfig(fConfig);
    }
  };
  /** get next data set from backend */
  const getNextDataSet = () => {
    if (!cursor || cursor === prevCursor || cursor === '') {
      return;
    }
    let reqMap = new Map<string, string>();
    if (props.crudConfig.listApiRequestFields) {
      Object.keys(props.crudConfig.listApiRequestFields).forEach((key) => {
        reqMap[key] = props.crudConfig.listApiRequestFields[key];
      });
    }
    let pageFilters = {};
    for (let f of filterConfig) {
      if (f.pageFilter === true) {
        pageFilters[f.field] = '1';
      }
    }
    setReqFilters(pageFilters, reqMap);
    reqMap['orgID'] = auth.user.orgID;
    reqMap['limit'] = limit;
    if (cursor !== '') {
      reqMap['cursor'] = cursor;
    }
    setPrevCursor(cursor);
    postAPI(props.crudConfig.listApiPath, reqMap, (resp) => {
      if (!resp.error || resp.error === '') {
        if (resp.data.count !== undefined) {
          setRowCount(resp.data.count);
        }
        if (resp.data[props.crudConfig.listApiResponseHeader]) {
          const newRows = [
            ...filteredData,
            ...resp.data[props.crudConfig.listApiResponseHeader],
          ];
          setFilteredData(newRows);
          if (selectAll) {
            selectAllRows(newRows);
          } else {
            setSelectAll(false);
          }
        }
        if (resp.data['cursor'] && resp.data['cursor'] !== '') {
          setCursor(resp.data['cursor']);
        }
        props.crudConfig.shareRowData &&
          props.crudConfig.shareRowData([
            ...filteredData,
            ...resp.data[props.crudConfig.listApiResponseHeader],
          ]);
      } else {
        showNotification(
          enqueueSnackbar,
          t(props.crudConfig.resourceName + '_list_err_' + resp.error),
          'error'
        );
      }
    });
  };

  useEffect(() => {
    let interval: any;
    resetPage();
    getListData(true);
    if (
      props.crudConfig.refreshConfig &&
      props.crudConfig.refreshConfig.autoRefresh
    ) {
      interval = setInterval(function () {
        if (document.visibilityState === 'visible') {
          console.log('Calling from auto refresh');
          getListData(false);
        }
      }, props.crudConfig.refreshConfig.refreshInterval * 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [getListData, props.forceUpdate, props.crudConfig.refreshConfig]);

  useEffect(() => {
    if (!auth.user.readOnly || props.crudConfig.showBulkInReadonly) {
      setMultiSelect(props.crudConfig.multiSelect);
    }
  }, [props.crudConfig.multiSelect]);

  const updatePref = (item: string, val: any, tab = '') => {
    if (!props.crudConfig.disableFilterCaching) {
      let currentPrefs = JSON.parse(localStorage.getItem('prefs'));
      let resName = props.crudConfig.resourceName;
      if (tab) {
        resName = props.crudConfig.resourceName + '_' + tab;
        if (!currentPrefs[resName]) {
          currentPrefs[resName] = {};
        }
      }
      currentPrefs[resName][item] = val;
      localStorage.setItem('prefs', JSON.stringify(currentPrefs));
    }
  };

  const handleTabsChange = (_event: SyntheticEvent, tabsValue: unknown) => {
    let value: string = null;

    if (tabsValue !== 'all') {
      value = tabsValue as string;
    }
    let updatedFilters;
    if (Array.isArray(props.crudConfig.filterConfig)) {
      updatedFilters = {
        ...filters,
        [props.crudConfig.tabFilterField]: value,
      };
      updatePref('filters', updatedFilters);
    } else {
      let currentPrefs = JSON.parse(localStorage.getItem('prefs'));
      if (!value) {
        value = 'all';
      }
      const resourcePref =
        currentPrefs[props.crudConfig.resourceName + '_' + value];
      let f: any;
      if (resourcePref && resourcePref['filters']) {
        f = resourcePref['filters'];
        updatedFilters = {
          ...f,
          [props.crudConfig.tabFilterField]: value,
        };
        const fc =
          props.crudConfig.filterConfig[
            value || props.crudConfig.defaultTabField || 'all'
          ];
        setFilterUIConfig(fc, resourcePref);
      } else {
        updatedFilters = {
          [props.crudConfig.tabFilterField]: value,
        };
        setFilterConfig(
          props.crudConfig.filterConfig[
            value || props.crudConfig.defaultTabField || 'all'
          ]
        );
        setQuery('');
      }

      updatePref('filters', updatedFilters, value);
    }
    updatePref('tab', tabsValue);
    setFilters(updatedFilters);
    if (!Array.isArray(props.crudConfig.bodyCells)) {
      if (value && props.crudConfig.bodyCells[value]) {
        setHeadCells(props.crudConfig.headCells[value]);
        setBodyCells(props.crudConfig.bodyCells[value]);
      } else {
        setHeadCells(props.crudConfig.headCells['all']);
        setBodyCells(props.crudConfig.bodyCells['all']);
      }
    }
    props.onRefresh && props.onRefresh();
  };

  const invokePreDeleteCheck = (
    checkBeforeDeleteConfig: CheckBeforeDeleteConfig,
    selectedData: any
  ) => {
    var req = checkBeforeDeleteConfig.apiReq;
    req[checkBeforeDeleteConfig.entityKey] = selectedData.id;
    return postAPIWithResponse(checkBeforeDeleteConfig.apiUrl, req);
  };

  const showDeleteConfimationFn = (selectedData) => {
    if (!props.crudConfig.referenceCheck) {
      setOpenConfirmDelete(true);
      return;
    }
    const payload = {
      entityName: selectedData['name'],
      entityType: props.crudConfig.referenceCheck.entityType,
      orgID: selectedData['orgID'],
      zoneID,
    };
    if (!props.crudConfig.referenceCheck.avoidIdInPayload) {
      payload['entityID'] = selectedData['id'];
    }
    postAPI(deleteApiReferencePath, payload, (resp) => {
      if (!resp.error || resp.error === '') {
        const referencesResponse = resp.data.references;
        const referencesKeyList = Object.keys(resp.data.references);
        const referenceObjList = [];
        if (referencesKeyList.length) {
          referencesKeyList.map((reference) => {
            referencesResponse[reference].map((item) => {
              referenceObjList.push({
                name: reference,
                value: item.entityName,
                linkUrl:
                  (props.crudConfig.referenceCheck.referenceList &&
                    props.crudConfig.referenceCheck.referenceList[reference] &&
                    `/${props.crudConfig.referenceCheck.referenceList[reference]}/${item.entityID}`) ||
                  (props.crudConfig.referenceCheck.globalReferenceList &&
                    props.crudConfig.referenceCheck.globalReferenceList[
                      reference
                    ] &&
                    `/${props.crudConfig.referenceCheck.globalReferenceList[reference]}`),
              });
            });
          });
          setReferenceMsg(referenceObjList);
          setShowMessagePopUp(true);
        } else {
          setOpenConfirmDelete(true);
        }
      }
    });
  };

  const selectAndShowDeleteConfirm = async (selectedData: any) => {
    setReferenceMsg([]);
    setSelectedEntityData(selectedData);
    if (props.crudConfig.checkBeforeDelete) {
      await invokePreDeleteCheck(
        props.crudConfig.checkBeforeDelete,
        selectedData
      ).then((resp) => {
        const blockDelete = props.crudConfig.checkBeforeDelete.apiCallBack(
          resp.data
        );
        if (blockDelete && props.crudConfig.checkBeforeDelete.apiErrorText) {
          showNotification(
            enqueueSnackbar,
            selectedData.name +
              ' : ' +
              t(props.crudConfig.checkBeforeDelete.apiErrorText),
            'error'
          );
        } else {
          showDeleteConfimationFn(selectedData);
        }
      });
    } else {
      showDeleteConfimationFn(selectedData);
    }
  };

  const showBulkDeleteConfirm = (): void => {
    let maxDeleteCount = 2000;
    if (selectAll && totalCount > maxDeleteCount) {
      showNotification(
        enqueueSnackbar,
        t(props.crudConfig.resourceName + '_delete_count_err_max_limit', {
          count: maxDeleteCount,
        }),
        'error'
      );
    } else {
      setOpenConfirmBulkDelete(true);
    }
  };

  const updateFilterState = () => {
    if (query) {
      setFiltersApplied(true);
    } else if (filters) {
      var filterFound = false;
      for (const iterator of Object.keys(filters)) {
        if (filters[iterator]) {
          setFiltersApplied(true);
          filterFound = true;
          break;
        }
      }
      if (!filterFound) {
        setFiltersApplied(false);
      }
    } else {
      setFiltersApplied(false);
    }
  };

  const getTotalCount = async () => {
    let pageFilters = {};
    for (let f of filterConfig) {
      if (f.pageFilter === true) {
        pageFilters[f.field] = '1';
      }
    }
    let reqMap = {};
    setReqFilters(pageFilters, reqMap);
    reqMap['orgID'] = auth.user.orgID;
    const resp = await postAPIWithResponseAsync(
      props.crudConfig.apiPrefix + '.count',
      reqMap
    );
    if (!resp.error || resp.error === '') {
      if (resp.count > 0) {
        updateFilterState();
        setTotalCount(resp.count);
      }
    } else {
      showNotification(
        enqueueSnackbar,
        t(props.crudConfig.resourceName + '_count_err_' + resp.error),
        'error'
      );
    }
  };

  const callBulkExport = useCallback(
    (payload) => {
      postAPI(
        props.crudConfig.apiPrefix + '.export',
        payload,
        (resp) => {
          if (!resp.error || resp.error === '') {
            createAndDownloadCSV(resp, 'guest_users.csv', false);

            let msg = t(
              props.crudConfig.resourceName + '_bulk_export_success_msg',
              {
                count:
                  payload['exportAll'] && !rowsSelected.size
                    ? totalCount
                    : rowsSelected.size,
              }
            );
            showNotification(enqueueSnackbar, msg, 'success');
          } else {
            let msg = t(
              props.crudConfig.resourceName + '_bulk_export_error_' + resp.error
            );
            showNotification(enqueueSnackbar, msg, 'error');
          }
        },
        0,
        { 'X-AGNI-ORG-ID': auth.user.orgID }
      );
    },
    [showNotification, t, enqueueSnackbar]
  );

  const bulkExportHandler = useCallback(() => {
    let payload = {
      orgID: auth.user.orgID,
    };
    let keys: string[] = [];
    if (selectAll) {
      let pageFilters = {};
      for (let f of filterConfig) {
        if (f.pageFilter === true) {
          pageFilters[f.field] = '1';
        }
      }
      setReqFilters(pageFilters, payload);
      if (
        payload['query'] ||
        payload['filters']?.length ||
        Object.keys(pageFilters).length
      ) {
        payload['exportAll'] = false;
      } else {
        payload['exportAll'] = true;
      }
    } else {
      rowsSelectedRef.current.forEach((value, _) => {
        for (const iterator of Object.keys(value)) {
          if (iterator === props.crudConfig.bulkExportProps.exportKey) {
            keys.push(value[iterator]);
          }
        }
      });
      payload[props.crudConfig.bulkExportProps.exportPayloadKey] = keys;
      payload['exportAll'] = false;
    }
    callBulkExport(payload);
  }, [filteredData, callBulkExport, selectAll]);

  /** Effect hook to add delete action in card view */
  useEffect(() => {
    let selectActions = props.crudConfig.rowSelectActions;
    if (
      !props.crudConfig.skipRbacCheck &&
      (auth.user.readOnly || props.crudConfig.forceReadonly)
    ) {
      if (props.crudConfig.readonlyActions) {
        setCardActions(props.crudConfig.readonlyActions);
      } else {
        setCardActions([]);
      }
    } else if (props.crudConfig.actions) {
      let actions = props.crudConfig.actions;
      let deleteActionPresent = false;
      let deleteActionPresentInSelect = false;
      for (let action of actions) {
        if (action.caption === 'Delete' || action.caption === 'uninstall') {
          deleteActionPresent = true;
        }
      }
      if (selectActions) {
        for (let action of selectActions) {
          if (action.caption === 'Delete') {
            deleteActionPresentInSelect = true;
          }
        }
      } else {
        selectActions = [];
      }
      if (deleteActionPresent === false && !props.crudConfig.hideDelete) {
        actions.push({
          caption: props.crudConfig.deleteBtnLabel
            ? props.crudConfig.deleteBtnLabel
            : 'Delete',
          callbackFn: selectAndShowDeleteConfirm,
          icon: DeleteTwoToneIcon,
          color: 'error',
        });
        setCardActions(actions);
      }
      if (
        props.crudConfig.multiSelect &&
        deleteActionPresentInSelect === false &&
        !props.crudConfig.hideDelete
      ) {
        selectActions.push({
          caption: props.crudConfig.deleteBtnLabel
            ? props.crudConfig.deleteBtnLabel
            : 'Delete',
          callbackFn: showBulkDeleteConfirm,
          icon: DeleteTwoToneIcon,
          color: 'error',
        });
      }
    }
    if (
      props.crudConfig.multiSelect &&
      props.crudConfig.bulkExportProps &&
      props.crudConfig.bulkExportProps.exportKey
    ) {
      if (!selectActions) {
        selectActions = [];
      }
      selectActions.push({
        caption: 'Export',
        callbackFn: bulkExportHandler,
        icon: FileDownloadIcon,
        color: 'primary',
      });
    }
    setSelectActions(selectActions);
  }, [
    props.crudConfig.actions,
    props.crudConfig.rowSelectActions,
    props.crudConfig.readonlyActions,
    props.crudConfig.hideDelete,
    auth.user,
    bulkExportHandler,
  ]);

  const resetPage = () => {
    setCursor('');
    setPrevCursor('');
  };

  const handleViewOrientation = (
    _event: MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      let tab: string;
      if (!Array.isArray(props.crudConfig.filterConfig)) {
        let currentPrefs = JSON.parse(localStorage.getItem('prefs'));
        if (currentPrefs[props.crudConfig.resourceName]) {
          tab = currentPrefs[props.crudConfig.resourceName]['tab'];
        }
      }
      updatePref('view', newValue, tab);
      setToggleView(newValue);
    }
  };

  const deleteHandler = () => {
    let payload = {
      orgID: selectedEntityData['orgID'],
    };
    payload[props.crudConfig.deleteKeyField] =
      selectedEntityData[props.crudConfig.deleteKeyField];
    postAPI(props.crudConfig.deleteApiPath, payload, (resp) => {
      if (!resp.error || resp.error === '') {
        let msg = props.crudConfig.deleteBtnLabel
          ? t('is ' + props.crudConfig.deleteBtnLabel + 'ed successfully')
          : t('is deleted successfully');
        showNotification(
          enqueueSnackbar,
          props.crudConfig.getDeleteEntityName
            ? props.crudConfig.getDeleteEntityName(selectedEntityData) +
                ' ' +
                msg
            : selectedEntityData[props.crudConfig.nameField] + ' ' + msg,
          'success'
        );
        closeDeletePopup(true);
      } else {
        let msg = selectedEntityData[props.crudConfig.nameField] + ' : ';
        if (
          resp.error === 'entity_referenced_by_other_entities' &&
          props.crudConfig.deleteErrorHandler
        ) {
          msg += props.crudConfig.deleteErrorHandler(
            resp,
            props.crudConfig.resourceName,
            t
          );
        } else {
          msg += t(props.crudConfig.resourceName + '_delete_err_' + resp.error);
        }
        showNotification(enqueueSnackbar, msg, 'error');
      }
    });
  };

  const bulkDeleteHandler = () => {
    let payload = {
      orgID: auth.user.orgID,
    };
    let keys: string[] = [];
    if (selectAll) {
      let pageFilters = {};
      for (let f of filterConfig) {
        if (f.pageFilter === true) {
          pageFilters[f.field] = '1';
        }
      }
      setReqFilters(pageFilters, payload);
      if (
        payload['query'] ||
        payload['filters']?.length ||
        Object.keys(pageFilters).length
      ) {
        payload['deleteAll'] = false;
      } else {
        payload['deleteAll'] = true;
      }
    } else {
      rowsSelected.forEach((value, _) => {
        for (const iterator of Object.keys(value)) {
          if (iterator === props.crudConfig.bulkDeleteProps.deleteKey) {
            keys.push(value[iterator]);
          }
        }
      });
      payload[props.crudConfig.bulkDeleteProps.deletePayloadKey] = keys;
    }
    postAPI(
      props.crudConfig.apiPrefix + '.deleteBulk',
      payload,
      (resp) => {
        setSelectAll(false);
        if (!resp.error || resp.error === '') {
          let msg = t(
            props.crudConfig.resourceName + '_bulk_delete_success_msg',
            {
              count: resp.data.totalCount,
            }
          );
          showNotification(enqueueSnackbar, msg, 'success');
          closeBulkDeletePopup(true);
        } else {
          setDeleteErrorObj(resp.data);
          if (resp.data.totalCount !== resp.data.errCount) {
            closeBulkDeletePopup(true);
          } else {
            closeBulkDeletePopup(false);
          }
        }
      },
      0
    );
  };

  // const bulkExportAllHandler = () => {
  //   let payload = {
  //     orgID: auth.user.orgID,
  //   };
  //   let pageFilters = {};
  //   for (let f of filterConfig) {
  //     if (f.pageFilter === true) {
  //       pageFilters[f.field] = '1';
  //     }
  //   }
  //   setReqFilters(pageFilters, payload);
  //   if (payload['query'] || payload['filters']?.length) {
  //     payload['exportAll'] = false;
  //   } else {
  //     payload['exportAll'] = true;
  //   }
  //   callBulkExport(payload);
  // };

  const clearSelectedData = () => {
    setSelectedEntityData(props.crudConfig.newEntity);
  };

  const closeDeletePopup = (refreshList: boolean) => {
    clearSelectedData();
    setOpenConfirmDelete(false);
    if (refreshList) {
      getListData(true);
    }
  };

  const closeBulkDeletePopup = (refreshList: boolean) => {
    setOpenConfirmBulkDelete(false);
    if (refreshList) {
      rowsSelectedRef.current = null;
      setRowsSelected(new Map<number, any>());
      getListData(true);
    }
  };

  const callbackSelectFilter = (filter) => {
    let tab: string;
    if (!Array.isArray(props.crudConfig.filterConfig)) {
      let currentPrefs = JSON.parse(localStorage.getItem('prefs'));
      if (currentPrefs[props.crudConfig.resourceName]) {
        tab = currentPrefs[props.crudConfig.resourceName]['tab'];
      }
    }
    if (filter.field === 'query') {
      setQuery(filter.value);
      updatePref('query', filter.value, tab);
    } else {
      let value = null;

      if (filter.value !== 'any') {
        value = filter.value;
      }
      let updatedFilters = {
        ...filters,
        [filter.field]: value,
      };
      updatePref('filters', updatedFilters, tab);
      updatePref(filter.field, value, tab);

      setFilters(updatedFilters);
    }

    props.onRefresh && props.onRefresh();
  };

  const sortCallback = (sort: SortOrder) => {
    if (props.crudConfig.fetchDataByChunks) {
    } else {
      const filterData = applyFilters(
        filteredData,
        query,
        filters,
        props.crudConfig.fieldsForContainsFilter,
        sort.sortDirection,
        sort.orderByField,
        props.crudConfig.boolDropdownField
      );
      setFilteredData(filterData);
    }
  };

  const onRefresh = () => {
    getListData(true);
    props.onRefresh && props.onRefresh();
  };

  const selectAllRows = (rows: any[]) => {
    let rs = new Map();
    for (let i = 0; i < rows.length; i++) {
      rs.set(i, rows[i]);
    }
    setRowsSelected(new Map(rs));
    rowsSelectedRef.current = new Map(rs);
  };

  const onRowSelected = (index: number, row: any, checked: boolean): void => {
    setSelectAll(false);
    updateFilterState();
    if (index == -1) {
      if (checked) {
        if (filteredData.length > maxRowsOnSelect) {
          const d = filteredData.slice(0, maxRowsOnSelect);
          selectAllRows(d);
          setShowMaxLimitWarning(true);
        } else {
          selectAllRows(filteredData);
        }
        if (props.crudConfig.fetchDataByChunks) {
          setTotalCount(0);
          getTotalCount();
        } else {
          setTotalCount(filteredData.length);
        }
      } else {
        setRowsSelected(new Map());
        rowsSelectedRef.current = null;
      }
    } else {
      setSelectAll(false);
      setTotalCount(0);
      if (checked) {
        rowsSelected.set(index, row);
      } else {
        rowsSelected.delete(index);
      }
      setRowsSelected(new Map(rowsSelected));
      rowsSelectedRef.current = new Map(rowsSelected);
    }
  };

  const saveDataToLocalStorage = (rowID: number) => {
    const data = {
      cursor,
      data: filteredData,
      rowID,
    };
    localStorage.setItem(
      'prevData_' + props.crudConfig.resourceName,
      JSON.stringify(data)
    );
  };

  const selectAllHandler = () => {
    if (rowsSelected.size !== filteredData.length) {
      selectAllRows(filteredData);
    }
    setSelectAll(true);
  };

  const handleWarningClose = (_) => {
    setShowMaxLimitWarning(false);
  };

  const closeWarning = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleWarningClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const clearSelected = () => {
    setRowsSelected(new Map());
    rowsSelectedRef.current = null;
  };

  const getTextOnSelection = () => {
    let msgKey = '';
    let trans: any;
    let count = 0;
    if (selectAll) {
      if (filtersApplied) {
        msgKey = 'bulk_selected_all_filtered_msg';
      } else {
        msgKey = 'bulk_selected_all_msg';
      }
      count = totalCount;
    } else {
      if (filtersApplied) {
        msgKey = 'bulk_selected_filtered_msg';
      } else {
        msgKey = 'bulk_selected_msg';
      }
      count = rowsSelected.size;
    }
    trans = t(`${props.crudConfig.resourceName}_${msgKey}`, {
      count,
    });

    return <Typography>{trans}</Typography>;
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={1}
      >
        {props.crudConfig.showTabs ? (
          <TabsWrapper
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={
              filters[props.crudConfig.tabFilterField] ||
              props.crudConfig.defaultTabField ||
              'all'
            }
            variant="scrollable"
          >
            {props.crudConfig.tabs.map((tab) => (
              <Tab
                id={'id_tab_' + props.crudConfig.resourceName + '_' + tab.value}
                key={tab.value}
                value={tab.value}
                label={
                  mobileView
                    ? t(tab.shortLabel ? tab.shortLabel : tab.label)
                    : t(tab.label)
                }
              />
            ))}
          </TabsWrapper>
        ) : (
          <span></span>
        )}
        <Box
          display="flex"
          alignItems="center"
          flexDirection={{ xs: 'row' }}
          justifyContent={{ sm: 'space-between' }}
        >
          {rowCount !== undefined && (
            <Chip
              sx={{ mr: 1, fontWeight: 500 }}
              label={t('Count') + ': ' + rowCount}
              variant="outlined"
              size="medium"
            />
          )}
          {!mobileView &&
            props.crudConfig.refreshConfig &&
            props.crudConfig.refreshConfig.showRefresh && (
              <Tooltip title={t('Refresh')} arrow>
                <IconButton
                  sx={{
                    mr: 1,
                    border: '1px solid',
                    borderColor: 'primary.light',
                  }}
                  onClick={onRefresh}
                  color="primary"
                  id={'id_button_refresh'}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          {props.crudConfig.showToggleBtn && mobileView === false && (
            <ToggleButtonGroup
              value={toggleView}
              exclusive
              onChange={handleViewOrientation}
              size="small"
            >
              <ToggleButton
                disableRipple
                value="table_view"
                id={'id_toggleView_table_view'}
                data-testid="id_toggleView_table_view"
              >
                <Tooltip title={t('table_view')} arrow>
                  <TableRowsTwoToneIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton
                disableRipple
                value="card_view"
                id={'id_toggleView_card_view'}
                data-testid="id_toggleView_card_view"
              >
                <Tooltip title={t('card_view')} arrow>
                  <GridViewTwoToneIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>
      </Box>
      <Card>
        {rowsSelected.size > 0 && (
          <Grid
            container
            spacing={mobileView ? 0 : 1}
            sx={{
              pl: 2,
              py: toggleView === 'card_view' ? 1.7 : 2.2,
              ...(theme.palette.mode === 'light'
                ? {
                    backgroundColor: '#FBFBF9',
                  }
                : {
                    backgroundColor: theme.colors.alpha.black[1],
                  }),
            }}
            alignItems="center"
          >
            {toggleView === 'card_view' && (
              <Grid item>
                <Checkbox
                  color="primary"
                  checked={rowsSelected?.size === filteredData.length}
                  indeterminate={
                    rowsSelected?.size > 0 &&
                    rowsSelected.size < filteredData.length
                  }
                  onChange={() => {
                    setRowsSelected(new Map());
                    rowsSelectedRef.current = null;
                  }}
                />
              </Grid>
            )}
            <Grid item>
              <Typography variant="h6">{t('bulk_actions')}:</Typography>
            </Grid>
            {selectActions &&
              selectActions.map((action, index) => {
                return (
                  <Grid item key={index}>
                    {mobileView ? (
                      <Tooltip title={action.caption}>
                        <IconButton
                          color={action.color}
                          onClick={() => action.callbackFn(filters, query)}
                        >
                          <action.icon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <>
                        <Button
                          color={action.color}
                          variant="contained"
                          size="small"
                          onClick={() => action.callbackFn(filters, query)}
                        >
                          {t(action.caption)}
                        </Button>
                      </>
                    )}
                  </Grid>
                );
              })}

            <Grid item sx={{ ml: 'auto', mr: mobileView ? 1 : 3 }}>
              <Stack
                direction="row"
                display="flex"
                alignItems={'center'}
                spacing={1}
              >
                {rowsSelected.size > 0 && (
                  <Stack direction="row" display="flex" alignItems={'center'}>
                    {getTextOnSelection()}
                    {totalCount > limit &&
                      !selectAll &&
                      totalCount > rowsSelected.size && (
                        <Button
                          variant="text"
                          sx={{
                            px: 1,
                            ...(theme.palette.mode === 'dark' && {
                              color: '#3081F7',
                            }),
                            my: 0,
                            py: 0,
                          }}
                          onClick={selectAllHandler}
                        >
                          {filtersApplied
                            ? t(
                                `${props.crudConfig.resourceName}_bulk_select_match_current_filter`,
                                { totalCount }
                              )
                            : t(
                                `${props.crudConfig.resourceName}_bulk_select_all`,
                                { totalCount }
                              )}
                        </Button>
                      )}
                  </Stack>
                )}
                {mobileView ? (
                  <IconButton onClick={clearSelected} size="small">
                    <CancelIcon />
                  </IconButton>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={clearSelected}
                    size="small"
                  >
                    {t('Cancel')}
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        )}
        <Box
          sx={{
            ...(theme.palette.mode === 'light'
              ? { backgroundColor: '#FBFBF9' }
              : { backgroundColor: theme.colors.alpha.black[1] }),
            borderBottom: `${
              theme.palette.mode === 'light'
                ? '1px solid lightgray'
                : `1px solid ${theme.colors.borders[1]}`
            }`,
          }}
        >
          <Stack direction="row" display="flex" alignItems="center">
            {toggleView === 'card_view' &&
              (!auth.user.readOnly || props.crudConfig.showBulkInReadonly) &&
              props.crudConfig.multiSelect &&
              rowsSelected.size === 0 &&
              filteredData?.length > 0 && (
                <Box sx={{ ml: 2 }}>
                  <Checkbox
                    color="primary"
                    onChange={() => {
                      onRowSelected(-1, {}, true);
                    }}
                  />
                </Box>
              )}
            <Filter
              sx={{
                ...(rowsSelected.size > 0 && {
                  display: 'none',
                }),
              }}
              filters={filterConfig}
              callbackFn={callbackSelectFilter}
              count={filteredData?.length || 0}
              noBorder
            ></Filter>
          </Stack>
        </Box>
        {/* table view html */}
        {toggleView === 'table_view' && (
          <InfiniteScrollTable
            rows={filteredData}
            resourceName={props.crudConfig.resourceName}
            primaryKey={props.crudConfig.primaryKey}
            headCells={headCells}
            bodyCells={bodyCells}
            actions={cardActions}
            multiSelect={multiSelect}
            getActions={props.crudConfig.getActions}
            defaultSort={
              props.crudConfig.defaultSort
                ? props.crudConfig.defaultSort
                : 'asc'
            }
            defaultSortCol={
              props.crudConfig.defaultSortCol
                ? props.crudConfig.defaultSortCol
                : 'name'
            }
            disableScroll={
              filteredData &&
              filteredData.length <= (props.crudConfig.disableScrollLimit || 0) // if disableScrollLimit is not passed we should give default value as 0
                ? true
                : false
            }
            noRowsMsgKey={t('No data to display')}
            collapsibleContent={props.crudConfig.collapsibleContent}
            collapsibleContentProvider={
              props.crudConfig.collapsibleContentProvider
            }
            collapsibleDiffProvider={props.crudConfig.collapsibleDiffProvider}
            collapsibleDetailsProvider={
              props.crudConfig.collapsibleDetailsProvider
            }
            fetchDataByChunks={props.crudConfig.fetchDataByChunks}
            dataLoaded={dataLoaded}
            scrollCallback={getNextDataSet}
            sortCallback={sortCallback}
            deleteHandler={selectAndShowDeleteConfirm}
            onRowClick={props.crudConfig.onRowClick}
            onRowSelected={onRowSelected}
            rowsSelected={rowsSelected}
            onRouteChange={
              props.crudConfig.saveDataOnRouteChange && saveDataToLocalStorage
            }
            maxRowOnSelect={maxRowsOnSelect}
          ></InfiniteScrollTable>
        )}

        {/* card view html */}
        {toggleView === 'card_view' && (
          <CardList
            primaryKey={props.crudConfig.primaryKey}
            config={props.crudConfig.cardConfig}
            type={props.crudConfig.cardType}
            data={filteredData}
            noRowsMsgKey={t('No data to display')}
            actions={cardActions}
            getActions={props.crudConfig.getActions}
            dataLoaded={dataLoaded}
            scrollCallback={getNextDataSet}
            fetchDataByChunks={props.crudConfig.fetchDataByChunks}
            deleteHandler={selectAndShowDeleteConfirm}
            onRowClick={props.crudConfig.onRowClick}
            multiSelect={multiSelect}
            onRowSelected={onRowSelected}
            rowsSelected={rowsSelected}
            maxRowOnSelect={maxRowsOnSelect}
            noTopbar={
              !(props.crudConfig.showTabs || props.crudConfig.showToggleBtn)
            }
            onRouteChange={
              props.crudConfig.saveDataOnRouteChange && saveDataToLocalStorage
            }
          />
        )}
      </Card>
      {openConfirmDelete && (
        <DeletePopup
          deleteMsg={
            props.crudConfig.getDeleteMsg
              ? props.crudConfig.getDeleteMsg(selectedEntityData)
              : props.crudConfig.resourceName + '_delete_msg'
          }
          entityName={
            props.crudConfig.getDeleteEntityName
              ? props.crudConfig.getDeleteEntityName(selectedEntityData)
              : selectedEntityData[props.crudConfig.nameField]
          }
          deleteHandler={deleteHandler}
          closeHandler={closeDeletePopup}
          deleteBtnLabel={props.crudConfig.deleteBtnLabel}
        ></DeletePopup>
      )}
      {openConfirmBulkDelete && (
        <DeletePopup
          deleteMsg={
            props.crudConfig.resourceName +
            (selectAll
              ? filtersApplied
                ? '_bulk_delete_filtered_msg'
                : '_bulk_delete_all_msg'
              : '_bulk_delete_msg')
          }
          count={selectAll ? totalCount : rowsSelected.size}
          deleteHandler={bulkDeleteHandler}
          closeHandler={closeBulkDeletePopup}
          deleteBtnLabel={props.crudConfig.deleteBtnLabel}
          showLoader
        ></DeletePopup>
      )}
      {showMessagePopUp && (
        <MessagePopup
          message={`Cannot delete '${
            props.crudConfig.getDeleteEntityName
              ? props.crudConfig.getDeleteEntityName(selectedEntityData)
              : selectedEntityData[props.crudConfig.nameField]
          }'`}
          entityName={
            props.crudConfig.getDeleteEntityName
              ? props.crudConfig.getDeleteEntityName(selectedEntityData)
              : selectedEntityData[props.crudConfig.nameField]
          }
          closeHandler={() => setShowMessagePopUp(false)}
          referenceMsg={referenceMsg}
          iconMapping={props.crudConfig.referenceCheck.entityIconMap}
        />
      )}
      {deleteErrorObj && (
        <DeleteErrorListPopup
          errorResp={deleteErrorObj}
          closeCallback={() => setDeleteErrorObj(undefined)}
          resourceName={props.crudConfig.resourceName}
        />
      )}
      <Snackbar
        open={showMaxLimitWarning}
        autoHideDuration={5000}
        onClose={handleWarningClose}
        message={t('max_row_select', {
          count: maxRowsOnSelect,
        })}
        action={closeWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ mb: 2 }}
      />
    </>
  );
};

export default Results;
