import { Guest, GuestPortalSettings, axiosMockAdapterInstance, getIDFromData, getListResponse, getRandomInt } from "@antara/shared";
import { add, sub } from "date-fns";
import clickthroughPreview from "src/assets/images/clickthrough-preview.png";
import ctbg from "src/assets/images/ct-bg.jpg";
import guestPreview2 from "src/assets/images/guest-2-preview.png";
import splitbg from "src/assets/images/sc-bg-2.jpg";
import splitprev from "src/assets/images/split-preview.png";
import { clients, networks } from "./clients";

const guestPortals: GuestPortalSettings[] = [
  {
    orgID: "E31c063b5-82ce-4950-8daf-bd4db16321b5",
    id: 100000,
    name: "Default",
    authPlugins: [
      {
        name: "Default",
        authType: "clickThrough",
        category: "Guest",
      },
      {
        name: "Default",
        authType: "employee",
        category: "Employee",
      },
      {
        name: "Default",
        authType: "guestBook",
        category: "Guest",
        guestBookDefaultValidity: 8,
        guestBookDefaultValidityUnit: "hour",
        guestBookUserGroupIDs: [],
        guestBookDeviceLimit: 8,
        guestBookSelfReqDefaultUserGroupID: 1,
        guestBookAllowSelfReg: true,
        guestBookSelfRegNeedApproval: true,
        guestBookSelfRegAllowedEmailDomains: ["acme.com", "arista.com"],
        guestBookSelfRegFields: [
          {
            id: 0,
            name: "userName",
            displayName: "Username",
            isMandatory: true,
            canDisplay: true,
            isDefault: true,
            defaultDisplayName: "Username",
          },
          {
            id: 1,
            name: "password",
            displayName: "Password",
            isMandatory: true,
            canDisplay: true,
            isDefault: true,
            defaultDisplayName: "Password",
          },
          {
            id: 2,
            name: "email",
            displayName: "Email",
            isMandatory: true,
            canDisplay: true,
            isDefault: true,
            defaultDisplayName: "Email",
          },
          {
            id: 3,
            name: "name",
            displayName: "Name",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Name",
          },
          {
            id: 4,
            name: "company",
            displayName: "Company",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Company",
          },
          {
            id: 5,
            name: "phone",
            displayName: "Phone",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Phone",
          },
          {
            id: 6,
            name: "sponsor",
            displayName: "Sponsor",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Sponsor",
          },
          {
            id: 7,
            name: "address",
            displayName: "Address",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Address",
          },
          {
            id: 8,
            name: "notes",
            displayName: "Notes",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Notes",
          },
        ],
      },
    ],
    settings: {},
    previewImage: "",
    baseTheme: "default",
  },
  {
    name: "Guest Portal 2",
    previewImage: guestPreview2,
    baseTheme: "default",
    authPlugins: [
      {
        name: "Guest Portal 2",
        category: "Guest",
        authType: "clickThrough",
      },
      {
        name: "Guest Portal 2",
        authType: "guestBook",
        category: "Guest",
        guestBookDefaultValidity: 36,
        guestBookDefaultValidityUnit: "hour",
        guestBookDeviceLimit: 5,
        guestBookUserGroupIDs: [],
        guestBookSelfRegAllowedEmailDomains: [],
        guestBookSelfReqDefaultUserGroupID: 1,
        guestBookAllowSelfReg: true,
        guestBookSelfRegFields: [
          {
            id: 0,
            name: "userName",
            displayName: "Username",
            isMandatory: true,
            canDisplay: true,
            isDefault: true,
            defaultDisplayName: "Username",
          },
          {
            id: 1,
            name: "password",
            displayName: "Password",
            isMandatory: true,
            canDisplay: true,
            isDefault: true,
            defaultDisplayName: "Password",
          },
          {
            id: 2,
            name: "email",
            displayName: "Email",
            isMandatory: true,
            canDisplay: true,
            isDefault: true,
            defaultDisplayName: "Email",
          },
          {
            id: 3,
            name: "name",
            displayName: "Name",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Name",
          },
          {
            id: 4,
            name: "company",
            displayName: "Company",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Company",
          },
          {
            id: 5,
            name: "phone",
            displayName: "Phone",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Phone",
          },
          {
            id: 6,
            name: "sponsor",
            displayName: "Sponsor",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Sponsor",
          },
          {
            id: 7,
            name: "address",
            displayName: "Address",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Address",
          },
          {
            id: 8,
            name: "notes",
            displayName: "Notes",
            isMandatory: false,
            canDisplay: false,
            isDefault: false,
            defaultDisplayName: "Notes",
          },
        ],
      },
    ],
    id: 2,
    settings: {
      page: {
        backgroundColor: "#F5A623",
        name: "page",
        type: "page",
        contents: "",
        label: "Page",
        themeColor: "#800080",
      },
      guest_login_form: {
        name: "guest_login_form",
        contents: "",
        type: "form",
        label: "Guest Login Form",
        category: "Guest",
      },
      guest_login_submit: {
        name: "guest_login_submit",
        textColor: "#FFFFFF",
        contents: "Proceed as Guest",
        type: "button",
        label: "Guest Login Submit Button",
        category: "Guest",
      },
      guest_login_header: {
        name: "guest_login_header",
        contents: "Guest Login",
        type: "label",
        label: "Guest Login Header",
        textColor: "#E75B6C",
        category: "Guest",
      },

      terms: {
        name: "terms",
        type: "terms",
        showAsUrl: true,
        label: "Terms of Use and Privacy Policy",
        termsContents: "https://www.google.com",
      },
      login_toggle: {
        name: "login_toggle",
        contents: "",
        type: "toggle",
        label: "Login Toggle",
      },
    },
  },
  {
    name: "Clickthrough",
    previewImage: clickthroughPreview,
    baseTheme: "default",
    authPlugins: [
      {
        name: "Clickthrough",
        category: "Guest",
        authType: "clickThrough",
      },
    ],
    id: 3,
    settings: {
      page: {
        name: "page",
        type: "page",
        contents: "",
        label: "Page",
        backgroundType: "image",
        backgroundImage: ctbg,
      },
      guest_login_form: {
        name: "guest_login_form",
        contents: "",
        type: "form",
        label: "Guest Login Form",
        category: "Guest",
      },
      guest_login_submit: {
        name: "guest_login_submit",
        textColor: "#FFFFFF",
        contents: "Proceed as Guest",
        type: "button",
        label: "Guest Login Submit Button",
        category: "Guest",
      },
      guest_login_header: {
        name: "guest_login_header",
        contents: "Guest Login",
        type: "label",
        label: "Guest Login Header",
        textColor: "#E75B6C",
        category: "Guest",
      },

      terms: {
        name: "terms",
        type: "terms",
        showAsUrl: true,
        label: "Terms of Use and Privacy Policy",
        termsContents: "https://www.google.com",
      },
      login_toggle: {
        name: "login_toggle",
        contents: "",
        type: "toggle",
        label: "Login Toggle",
      },
    },
  },
  {
    name: "Split Screen",
    previewImage: splitprev,
    baseTheme: "split-screen",
    authPlugins: [
      {
        name: "Split Screen",
        category: "Guest",
        authType: "clickThrough",
      },
    ],
    id: 4,
    settings: {
      page: {
        name: "page",
        type: "page",
        contents: "",
        label: "Page",
        backgroundType: "image",
        backgroundImage: splitbg,
        themeColor: "#356597",
      },
      email: {
        name: "email",
        contents: "Enter email address",
        type: "text",
        label: "Email Textbox",
      },
      guest_login_form: {
        name: "guest_login_form",
        contents: "",
        type: "form",
        label: "Guest Login Form",
        category: "Guest",
        opacity: 0.001,
      },
      guest_login_submit: {
        name: "guest_login_submit",
        textColor: "#FFFFFF",
        contents: "Proceed as Guest",
        type: "button",
        label: "Guest Login Submit Button",
        category: "Guest",
        borderRadius: 0,
      },
      guest_login_header: {
        name: "guest_login_header",
        contents: "Guest Login",
        type: "label",
        label: "Guest Login Header",
        textColor: "#E75B6C",
        category: "Guest",
      },
      terms: {
        name: "terms",
        type: "terms",
        label: "Terms of Use and Privacy Policy",
        termsContents: "Placeholder of Terms of Use",
        mandatory: false,
        termsType: "both",
        privacyContents: "Placeholder of Privacy Policy",
      },
    },
  },
];

export const guests: Guest[] = [
  {
    orgID: "org1",
    userID: "guest-1",
    guestType: "individual",
    loginName: "Guest 1",
    password: "1234poab",
    email: "abc@gmail.com",
    sponsorID: "1",
    sponsorName: "hr@gmail.com",
    status: "enabled",
    portalID: 100000,
    deviceLimit: 5,
    createdAt: new Date(),
    updatedAt: new Date(),
    validFrom: sub(new Date(), { hours: 5 }),
    validTo: add(new Date(), { hours: 25 }),
    name: "ABC Guest",
    company: "ABC",
    phone: "11223344",
    address: "Europe",
    notes: "Visiting for event",
  },
  {
    orgID: "org1",
    userID: "guest-2",
    guestType: "individual",
    loginName: "Madeline",
    password: "12345678",
    pskPassphrase: "passphrase",
    email: "Madeline@gmail.com",
    sponsorID: "2",
    sponsorName: "virat@facility.com",
    status: "pendingApproval",
    deviceLimit: 5,
    createdAt: new Date(),
    updatedAt: new Date(),
    validFrom: sub(new Date(), { hours: 5 }),
    validTo: add(new Date(), { hours: 25 }),
    name: "Madeline Pagac",
    company: "ICC",
    phone: "11223355",
    address: "USA",
    notes: "Visiting for match",
  },
  {
    orgID: "org1",
    userID: "guest-3",
    guestType: "individual",
    loginName: "Guest 3",
    password: "1234abcd",
    email: "Madeline@gmail.com",
    status: "disabled",
    deviceLimit: 5,
    portalID: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
    validFrom: sub(new Date(), { hours: 5 }),
    validTo: add(new Date(), { hours: 25 }),
  },
  {
    orgID: "org1",
    userID: "guest-4",
    guestType: "batch",
    batchName: "Batch 1",
    loginName: "Guest 4",
    password: "12341111",
    email: "guest4@gmail.com",
    status: "enabled",
    deviceLimit: 5,
    portalID: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
    validFrom: sub(new Date(), { hours: 5 }),
    validTo: add(new Date(), { hours: 25 }),
  },
];

export const mockGuests = () => {
  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.get").reply(() => {
    const errorList = [""];
    return [
      200,
      {
        data: guests[getIDFromData(guests, "userID")],
        error: errorList[getRandomInt(0, errorList.length - 1)],
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.add").reply(() => {
    return [
      200,
      {
        error: "",
        data: {
          userID: guests[0].userID,
        },
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.list").reply(() => {
    const errorList = [""];
    return getListResponse(errorList, "users", guests);
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.update").reply(() => {
    return [
      200,
      {
        error: "",
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.delete").reply(() => {
    return [
      200,
      {
        error: "",
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.deleteBulk").reply(() => {
    return [
      200,
      {
        data: { totalCount: 100 },
        error: "",
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.count").reply(() => {
    return [
      200,
      {
        data: { count: 100 },
        error: "",
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.users.import").reply(() => {
    return [
      200,
      {
        error: "",
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.clients.list").reply(() => {
    return getListResponse([""], "clients", clients);
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.clients.delete").reply(() => {
    return [
      200,
      {
        error: "",
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.user.export").reply(() => {
    return [
      200,
      "username,name,email,company,phone,address,notes,password,pskPassphrase,status,deviceLimit,validFrom,validTo\nuser5,user 5,user5@test.com,company 3,112233449811,USA,,rb44hsw2pben,,enabled,0,2024-02-19T06:20:00Z,2024-02-19T14:24:00Z\nuser4,user 4,user4@test.com,company 3,112233445599,Europe,,yrjfysaw91n2,,enabled,0,2024-02-19T06:20:00Z,2024-02-19T14:24:00Z\nuser3,user 3,user3@test.com,company 2,1122334478,Europe,guest,66fa7ux5a99i,,enabled,0,2024-02-19T06:20:00Z,2024-02-19T14:24:00Z\n",
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.portal.list").reply(() => {
    const errorList = [""];
    return getListResponse(errorList, "portals", guestPortals);
  });

  axiosMockAdapterInstance.onPost("/api/me.guestAdmin.network.guestUPSK.list").reply(() => {
    const errorList = [""];
    const err = errorList[getRandomInt(0, errorList.length - 1)];

    if (err !== "") {
      return [200, { error: err }];
    }

    const data = { data: { networks: networks }, error: "" };
    return [200, data];
  });
};
