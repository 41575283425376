import { Logo, appConfig } from "@antara/shared";
import { Box, Card, Container, styled } from "@mui/material";
import { useEffect, useState } from "react";
import LoginForm from "./LoginForm";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 8;
    background-color: ${theme.header.background};
    backdrop-filter: blur(3px);
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
   justify-content: center;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

const SignIn = () => {
  const [loginBG, setLoginBG] = useState<any>();
  useEffect(() => {
    const theme = appConfig.onboardTheme;
    if (theme) {
      setLoginBG(theme.loginBackground);
    }
  }, []);

  return (
    <>
      <HeaderWrapper>
        <Logo showAppNameInMobile />
      </HeaderWrapper>
      <MainContent
        sx={{
          ...(loginBG && {
            backgroundImage: `url(${loginBG})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }),
        }}
      >
        <TopWrapper>
          <Container maxWidth="sm">
            <LoginForm />
          </Container>
        </TopWrapper>
      </MainContent>
    </>
  );
};

export default SignIn;
