import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Transition } from '../../utils/dialogutils';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import { EntityIconMap } from '../../utils';

export const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

export const DialogContentSection = styled('div')(
  () => `
    display: flex;
    flex-direction: column;
    gap: 8;
    margin-bottom: 16px;
    width: 100%;
`
);

export const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

interface MessagePopupProps {
  message: string;
  entityName: string;
  messageHandler?: any;
  closeHandler: any;
  btnLabel?: string;
  cancelLabel?: string;
  referenceMsg?: any;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  iconMapping?: EntityIconMap;
  customMsg?: string;
}

const MessagePopup: FC<MessagePopupProps> = ({
  message,
  entityName,
  messageHandler,
  closeHandler,
  btnLabel,
  cancelLabel,
  referenceMsg,
  color,
  iconMapping,
  customMsg,
}) => {
  const { t }: { t: any } = useTranslation();

  const cancelHandler = () => {
    closeHandler(false);
  };

  // const getEntityIcon = (
  //   name: string
  // ): React.ComponentType<React.SVGProps<SVGSVGElement>> => {
  //   const element: React.ComponentType<React.SVGProps<SVGSVGElement>> =
  //     iconMapping[name];
  //   //return <>{iconMapping[name]}</>;
  //   return element;
  // };

  const referenceKeyList = referenceMsg.splice(0, 5);
  return (
    <>
      <DialogWrapper
        open={true}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeHandler}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            align="left"
            sx={{
              wordBreak: 'break-word',
            }}
            variant="h3"
          >
            <Trans
              t={t}
              i18nKey={message}
              values={{ entity: entityName }}
              tOptions={{ interpolation: { escapeValue: false } }}
            />
          </Typography>
          {closeHandler ? (
            <IconButton
              aria-label="close"
              onClick={closeHandler}
              sx={{
                position: 'absolute',
                right: 8,
                top: 16,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="start"
            flexDirection="column"
            pb={1}
            px={1}
          >
            <DialogContentSection>
              <List sx={{ width: '100%', bgcolor: 'background.paper', py: 0 }}>
                {customMsg ? (
                  <p>{customMsg}</p>
                ) : (
                  <>
                    {referenceMsg.length < 5 ? (
                      <p>
                        {entityName} is being used in the following entities -
                      </p>
                    ) : (
                      <p>
                        More than 5 entities use this {entityName}. Here are
                        some of the entities -{' '}
                      </p>
                    )}
                  </>
                )}
                {referenceKeyList &&
                  referenceKeyList.map((listItem) => (
                    <ListItem
                      key={listItem.name + '_' + listItem.id}
                      secondaryAction={
                        listItem.linkUrl && (
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              window.open(
                                '/agni' + listItem.linkUrl,
                                '_blank',
                                'noopener noreferrer'
                              )
                            }
                          >
                            <LaunchIcon color={color ? color : 'primary'} />
                          </IconButton>
                        )
                      }
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ background: 'transparent' }}>
                          {iconMapping[listItem.name]}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={listItem.value}
                        secondary={t(listItem.name)}
                      />
                    </ListItem>
                  ))}
              </List>
            </DialogContentSection>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '24px',
              }}
            >
              <Button
                variant="outlined"
                size="large"
                sx={{
                  mx: 1,
                }}
                onClick={cancelHandler}
                id="id_button_cancel"
              >
                {cancelLabel ? t(cancelLabel) : t('Close')}
              </Button>
              {messageHandler && (
                <ButtonError
                  onClick={messageHandler}
                  size="large"
                  sx={{
                    mx: 1,
                  }}
                  variant="contained"
                  id="id_button_message"
                >
                  {btnLabel ? t(btnLabel) : t('Done')}
                </ButtonError>
              )}
            </Box>
          </Box>
        </DialogContent>
      </DialogWrapper>
    </>
  );
};

export default MessagePopup;
