export const formatImportCSVError = (error: any, t, type) => {
  const errorList = [];
  error?.forEach((e) => {
    e?.entities?.forEach((g) => {
      if (e.error === 'object_already_exists') {
        errorList.push(
          `'${g.entityName}' ${t(`csv_error_${type}_${e.error}`)}`
        );
      } else {
        errorList.push(`'${g.entityName}' ${t(`csv_error_${e.error}`)}`);
      }
    });
  });
  return errorList;
  //"Line 11 : 'Arista_8E:70:EF' name already exist for another device"
};
