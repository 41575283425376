import { StatusPage, UpsertGuestUser, useAuth } from "@antara/shared";

const UpsertGuest = () => {
  const auth = useAuth();

  return (
    <>
      {auth.user?.isGuestAdminSponsor || auth.user?.isGuestAdminOperator ? (
        <UpsertGuestUser
          apiPrefix="me.guestAdmin.user"
          updateURL={"/" + auth.user.orgID + "/guests/users"}
          isOnboard={true}
          clientListApiURL="me.guestAdmin.user.clients.list"
          clientDeleteApiURL="me.guestAdmin.client.delete"
        ></UpsertGuestUser>
      ) : (
        <StatusPage status="403" hideNav />
      )}
    </>
  );
};

export default UpsertGuest;
