import { LinkText } from "@antara/shared";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Container, styled, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Trans, useTranslation } from "react-i18next";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const SignOut = () => {
  const { t }: { t: any } = useTranslation();
  const homeLink = window.location.href.replace(/signout.*/, "");
  sessionStorage.clear();

  return (
    <>
      <Helmet>
        <title>{t("Signed Out")}</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <CheckCircleOutlineIcon sx={{ height: "8em", width: "8em" }} color="success"></CheckCircleOutlineIcon>

            <Typography
              variant="h2"
              sx={{
                my: 2,
              }}
            >
              <span>{t("signout_success")}</span>
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              <Trans
                i18nKey="signin_again"
                components={{
                  link1: <LinkText to={homeLink} title={t("onboard")} target="_self" />,
                }}
              />
            </Typography>
          </Box>
        </Container>
      </MainContent>
    </>
  );
};

export default SignOut;
