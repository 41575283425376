import { Box, Typography, styled } from '@mui/material';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';

const toolbarOptions = [
  ['bold', 'italic', 'underline'], // toggled buttons
  ['link', 'image'], // blocks  // "blockquote", "code-block", "video"

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  // [{ direction: "rtl" }], // text direction

  //  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }],
  [{ align: [] }],

  ['clean'],
];

const CustomBox = styled(Box)<{ hideborder?: string; showtoolbar?: string }>(
  ({ theme, hideborder, showtoolbar }) => ({
    '.ql-toolbar .ql-stroke': {
      stroke: theme.palette.mode === 'dark' ? '#CCCCCC' : '#3a3541',
    },
    '.ql-picker-options': {
      background:
        theme.palette.mode === 'dark' ? theme.colors.alpha.black[100] : 'white',
    },
    '.ql-toolbar .ql-fill': {
      fill: theme.palette.mode === 'dark' ? '#CCCCCC' : '#3a3541',
    },
    '.ql-container.ql-snow': {
      border: hideborder ? 'none' : '1px solid black',
      borderColor:
        theme.palette.mode === 'dark' ? theme.colors.borders[2] : '#3A35414D',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
      borderTopRightRadius: showtoolbar ? '0px' : '10px',
      borderTopLeftRadius: showtoolbar ? '0px' : '10px',
    },
    '.ql-toolbar.ql-snow': {
      border: hideborder ? 'none' : '1px solid black',
      borderColor:
        theme.palette.mode === 'dark' ? theme.colors.borders[2] : '#3A35414D',
      borderTopRightRadius: '10px',
      borderTopLeftRadius: '10px',
    },
    '.ql-snow .ql-tooltip': {
      background:
        theme.palette.mode === 'dark' ? theme.colors.alpha.black[100] : 'white',
      color: theme.palette.mode === 'dark' ? '#CCCCCC' : '#3a3541',
      input: {
        background:
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.black[100]
            : 'white',
        color: theme.palette.mode === 'dark' ? '#CCCCCC' : '#3a3541',
      },
    },
    '.ql-tooltip': {
      left: 'unset !important',
    },
    '.ql-toolbar.ql-snow .ql-formats': {
      marginRight: '10px',
    },
  })
);

// Helper Functions
const calculateByteSize = (str: string) => {
  return new Blob([str]).size;
};

const bytesToMB = (bytes: number) => {
  return bytes / (1024 * 1024);
};

const RichTextEditor = ({
  viewer,
  text,
  onUpdate,
  hide,
  placeholder,
  limitInMB,
  defaultText,
  errorStr,
  reset,
}: {
  viewer?: boolean;
  text?: any;
  onUpdate?(text: string, error?: boolean, empty?: boolean);
  hide?: boolean;
  placeholder?: string;
  limitInMB?: number;
  defaultText?: any;
  errorStr?: any;
  reset?: boolean;
}) => {
  const editorRef = useRef(null);
  const [quill, setQuill] = useState<any>(null);
  const readOnly = useAuth().user.readOnly || viewer;
  const [imageSizeError, setImageSizeError] = useState<boolean>();
  const { t }: { t: any } = useTranslation();

  useEffect(() => {
    if (quill) {
      quill.setContents(JSON.parse(defaultText));
    }
  }, [defaultText, reset]);

  useEffect(() => {
    // Prevent reinitialization on hot reload
    if (quill) return () => {};
    // @ts-ignore: Unreachable code error
    const quillInstance = new Quill(editorRef.current, {
      theme: 'snow',
      modules: {
        toolbar: readOnly ? false : toolbarOptions,
      },
      placeholder: readOnly ? '' : placeholder,
    });
    if (readOnly) {
      // @ts-ignore: Unreachable code error
      quillInstance.disable();
      // @ts-ignore: Unreachable code error
      quillInstance.setContents(JSON.parse(text));
    } else if (!viewer) {
      // @ts-ignore: Unreachable code error
      quillInstance.on('text-change', () => {
        // @ts-ignore: Unreachable code error
        const content = quillInstance.getContents();
        if (limitInMB > 0) {
          // @ts-ignore: Unreachable code error
          const htmlContent = quillInstance.root.innerHTML;
          const contentSizeInBytes = calculateByteSize(htmlContent);
          const mb = bytesToMB(contentSizeInBytes);
          if (mb > limitInMB) {
            onUpdate(JSON.stringify(content), true);
            setImageSizeError(true);
            return;
          }
        }
        // @ts-ignore: Unreachable code error
        const value = quillInstance.root.innerHTML;
        let isEmpty = false;
        const strippedValue = value.replace(/<(.|\n)*?>/g, '').trim();
        // Validate the field and set the error if content is empty
        if (!strippedValue) {
          isEmpty = true;
        }
        onUpdate(JSON.stringify(content), false, isEmpty);
        setImageSizeError(false);
      });
    }
    setQuill(quillInstance);
    return () => {
      quill?.off('text-change');
    };
  }, [quill, text, readOnly, onUpdate, placeholder]);

  return (
    <CustomBox
      hideborder={viewer ? 'true' : ''}
      showtoolbar={readOnly ? '' : 'true'}
      mb={1}
    >
      <Box
        ref={editorRef}
        sx={{
          minHeight: '100px',
          ...(hide
            ? { visibility: 'hidden', height: '0px' }
            : {
                visibility: 'visible',
                minHeight: viewer ? '60px' : '120px',
              }),
        }}
      ></Box>
      {imageSizeError && (
        <Typography color="error">
          {t('file_size_exceed_rte', {
            count: limitInMB,
          })}
        </Typography>
      )}
      {errorStr && <Typography color="error">{t(errorStr)}</Typography>}
    </CustomBox>
  );
};

export default RichTextEditor;
