import { FC, useState, createContext } from 'react';
import React from 'react';

type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  sidebarName: string;
  setSidebar: (string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

const getSidebar = (): string => {
  let sidebar = localStorage.getItem('sidebar');
  if (!sidebar) {
    sidebar = 'accent_header';
  }
  return sidebar;
};

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [sidebarName, setSidebarName] = useState(getSidebar());
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const setSidebar = (sidebar: string) => {
    localStorage.setItem('sidebar', sidebar);
    setSidebarName(sidebar);
  };
  return (
    <SidebarContext.Provider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        sidebarName,
        setSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
