import { useTheme } from '@mui/material';
import React from 'react';

const LinkText = (props) => {
  const theme = useTheme();
  return (
    <a
      href={props.to || '#'}
      target={props.target || '_blank'}
      title={props.title || ''}
      rel="noopener"
      style={{ color: theme.palette.mode === 'dark' && 'white' }}
    >
      {props.children}
    </a>
  );
};

export default LinkText;
