import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";

import {enUS,zhCN} from "date-fns/locale";

import { TRANSLATIONS_ZH } from "./zh/translations";
import { TRANSLATIONS_EN } from "./en/translations";

// Add new locale in this map when introduced, this is used for localization in DateSelector
export const LOCALE_MAP = {
  en: enUS,
  zh: zhCN,
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      zh: {
        translation: TRANSLATIONS_ZH
      }
    },
    interpolation: {
      format: function (value) {  //}, format, lng) {
        console.log(value, typeof value)
        if (typeof value === "number") return new Intl.NumberFormat().format(value);
        return value;
      }
    },
    keySeparator: false,
    lng: 'en',
    fallbackLng: 'en',
    react: {
      useSuspense: true
    },
  });

export default i18n;