import { GuestUsers, StatusPage, useAuth } from "@antara/shared";

const IdentityGuests = () => {
  const auth = useAuth();

  return (
    <>
      {auth.user?.isGuestAdminSponsor || auth.user?.isGuestAdminOperator ? (
        <GuestUsers apiPrefix="me.guestAdmin.user" isOnboard={true} guestUserURL={"/" + auth.user.orgID + "/guests/users/"}></GuestUsers>
      ) : (
        <StatusPage status="403" hideNav />
      )}
    </>
  );
};

export default IdentityGuests;
