import MyLocationIcon from '@mui/icons-material/MyLocation';
import {
  Button,
  IconButton,
  List,
  ListItemButton,
  ListSubheader,
  Popover,
  useTheme,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipAI } from '../components/TooltipAI';
import { appConfig } from '../config';
import { GlobalContext } from '../contexts/GlobalContext';
import useAuth from '../hooks/useAuth';
import { postAPI } from '../utils/httputils';

export interface ZoneSelectorProps {
  collapsed?: boolean;
  darkBackground?: boolean;
}
export default function ZoneSelector(props: ZoneSelectorProps) {
  let { collapsed, darkBackground } = props;
  const { t }: { t: any } = useTranslation();
  const auth = useAuth();
  const theme = useTheme();
  const { setZoneID } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedZone, setSelectedZone] = useState<any>();
  const [zones, setZones] = useState<any>([]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleListItemClick = (val: any) => {
    setSelectedZone(val);
    setZoneID(val.id, val.name);
    handleClose();
  };

  const getZoneFromLocalStorage = (): number => {
    let zone = localStorage.getItem('zone');
    if (zone) {
      return Number(zone);
    }
    return 0;
  };

  const getZoneList = useCallback(async () => {
    postAPI('config.zone.list', { orgID: auth.user.orgID }, (resp) => {
      if (!resp.error || resp.error === '') {
        let zoneList = resp.data.zones;
        if (zoneList.length > 0) {
          zoneList = [{ id: 0, name: 'global' }].concat(zoneList);
          let zoneID = getZoneFromLocalStorage();
          let zoneSelected = false;
          if (zoneID !== 0) {
            let szones = zoneList.filter((el) => {
              return el.id === zoneID;
            });
            if (szones.length > 0) {
              setZoneID(szones[0].id, szones[0].name);
              setSelectedZone(szones[0]);
              zoneSelected = true;
            }
          }
          if (!zoneSelected) {
            setZoneID(zoneList[0].id, zoneList[0].name);
            setSelectedZone(zoneList[0]);
          }
          setZones(zoneList);
        } else {
          setSelectedZone(undefined);
          localStorage.removeItem('zone');
          localStorage.removeItem('zonename');
        }
      } else {
        console.log('error while retrieving zones from backend');
      }
    });
  }, []);

  useEffect(() => {
    if (appConfig.appname === 'agni') {
      let name = localStorage.getItem('zonename');
      if (name) {
        let id = Number(localStorage.getItem('zone'));
        setZoneID(id, name);
        setSelectedZone({ id, name });
      }
      getZoneList();
    }
  }, []);

  return (
    <>
      {selectedZone && (
        <>
          {collapsed ? (
            <TooltipAI
              disableInteractive
              title={selectedZone.name}
              placement="right"
              arrow
              sx={{ textTransform: 'uppercase' }}
            >
              <IconButton
                id="id_button_selectZone"
                aria-label="zone"
                onClick={handleClick}
                size="small"
                sx={{
                  borderStyle: 'dashed',
                  borderWidth: '1px',
                  justifyContent: 'flex-start',
                  ml: 3,
                  my: 1,
                  ...(darkBackground
                    ? { color: 'white' }
                    : { color: `${theme.colors.primary.main}` }),
                }}
              >
                <MyLocationIcon />
              </IconButton>
            </TooltipAI>
          ) : (
            <Button
              id="id_button_selectZone"
              fullWidth
              variant="text"
              startIcon={<MyLocationIcon />}
              onClick={handleClick}
              size="small"
              sx={{
                textTransform: 'uppercase',
                borderStyle: 'dashed',
                borderWidth: '1px',
                justifyContent: 'flex-start',
                pl: 2,
                mb: 1,
              }}
            >
              {selectedZone.name}
            </Button>
          )}
          {zones && zones.length > 0 && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <List
                sx={{
                  width: `${theme.sidebar.width}`,
                  minWidth: 250,
                }}
                component="nav"
                aria-labelledby="zone-options"
                subheader={
                  <ListSubheader component="div" id="subheader">
                    {t('select_zone')}
                  </ListSubheader>
                }
              >
                {zones.map((zone, index) => (
                  <ListItemButton
                    id={'id_button_zone_' + zone.id}
                    key={index}
                    sx={{ p: 1, textTransform: 'uppercase' }}
                    selected={selectedZone.id === zone.id}
                    onClick={() => handleListItemClick(zone)}
                  >
                    {zone.name}
                  </ListItemButton>
                ))}
              </List>
            </Popover>
          )}
        </>
      )}
    </>
  );
}
