import { axiosMockAdapterInstance, getListResponse, getRandomInt } from "@antara/shared";
import { Device } from "src/models/device";

export const devices: Device[] = [
  {
    id: "1",
    userID: "admin",
    orgID: "mockorg",
    name: "Device-1",
    host: "192.168.0.106",
    port: 2222,
    updatedAt: new Date(),
  },
  {
    id: "2",
    userID: "admin1",
    orgID: "mockorg",
    name: "Device-2",
    host: "10.10.10.2",
    port: 102,
    updatedAt: new Date(),
  },
  {
    id: "3",
    userID: "adminw",
    orgID: "mockorg",
    name: "Device-3",
    host: "10.10.10.3",
    port: 103,
    updatedAt: new Date(),
  },
  {
    id: "4",
    userID: "admin3",
    orgID: "mockorg",
    name: "Device-4",
    host: "10.10.10.4",
    port: 104,
    updatedAt: new Date(),
  },
  {
    id: "5",
    userID: "admin5",
    orgID: "mockorg",
    name: "Device-5",
    host: "10.10.10.5",
    port: 105,
    updatedAt: new Date(),
  },
  {
    id: "11",
    userID: "admin",
    orgID: "mockorg",
    name: "Device-11",
    host: "192.168.0.116",
    port: 2222,
    updatedAt: new Date(),
  },
  {
    id: "12",
    userID: "admin1",
    orgID: "mockorg",
    name: "Device-12",
    host: "10.10.10.12",
    port: 102,
    updatedAt: new Date(),
  },
  {
    id: "13",
    userID: "adminw",
    orgID: "mockorg",
    name: "Device-13",
    host: "10.10.10.13",
    port: 103,
    updatedAt: new Date(),
  },
  {
    id: "14",
    userID: "admin3",
    orgID: "mockorg",
    name: "Device-14",
    host: "10.10.10.14",
    port: 104,
    updatedAt: new Date(),
  },
  {
    id: "15",
    userID: "admin5",
    orgID: "mockorg",
    name: "Device-15",
    host: "10.10.10.15",
    port: 105,
    updatedAt: new Date(),
  },
  {
    id: "21",
    userID: "admin",
    orgID: "mockorg",
    name: "Device-21",
    host: "192.168.0.126",
    port: 2222,
    updatedAt: new Date(),
  },
  {
    id: "22",
    userID: "admin1",
    orgID: "mockorg",
    name: "Device-22",
    host: "10.10.10.22",
    port: 102,
    updatedAt: new Date(),
  },
  {
    id: "23",
    userID: "adminw",
    orgID: "mockorg",
    name: "Device-23",
    host: "10.10.10.23",
    port: 103,
    updatedAt: new Date(),
  },
  {
    id: "24",
    userID: "admin3",
    orgID: "mockorg",
    name: "Device-24",
    host: "10.10.10.24",
    port: 104,
    updatedAt: new Date(),
  },
  {
    id: "25",
    userID: "admin5",
    orgID: "mockorg",
    name: "Device-25",
    host: "10.10.10.25",
    port: 105,
    updatedAt: new Date(),
  },
  {
    id: "31",
    userID: "admin",
    orgID: "mockorg",
    name: "Device-31",
    host: "192.168.0.136",
    port: 2222,
    updatedAt: new Date(),
  },
  {
    id: "32",
    userID: "admin1",
    orgID: "mockorg",
    name: "Device-32",
    host: "10.10.10.32",
    port: 102,
    updatedAt: new Date(),
  },
  {
    id: "33",
    userID: "adminw",
    orgID: "mockorg",
    name: "Device-33",
    host: "10.10.10.33",
    port: 103,
    updatedAt: new Date(),
  },
  {
    id: "34",
    userID: "admin3",
    orgID: "mockorg",
    name: "Device-34",
    host: "10.10.10.34",
    port: 104,
    updatedAt: new Date(),
  },
  {
    id: "35",
    userID: "admin5",
    orgID: "mockorg",
    name: "Device-35",
    host: "10.10.10.35",
    port: 105,
    updatedAt: new Date(),
  },
];

export const mockDevicess = () => {
  axiosMockAdapterInstance.onPost("/api/me.deviceAdmin.device.list").reply(() => {
    const errorList = [""];
    return getListResponse(errorList, "devices", devices);
  });

  axiosMockAdapterInstance.onPost("/api/me.deviceAdmin.device.get").reply(() => {
    const data = {
      data: devices[0],
      error: "",
    };
    return [200, data];
  });

  axiosMockAdapterInstance.onPost("/api/me.deviceAdmin.device.add").reply(() => {
    const data = {
      error: "",
    };
    return [200, data];
  });

  axiosMockAdapterInstance.onPost("/api/me.deviceAdmin.device.update").reply(() => {
    return [
      200,
      {
        error: "",
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.deviceAdmin.device.delete").reply(() => {
    return [
      200,
      {
        error: "",
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.deviceAdmin.device.import").reply(() => {
    return [
      200,
      {
        data: {
          totalCount: 3,
          insertCount: 0,
          updateCount: 0,
          errCount: 0,
          ignoreCount: 3,
        },
        error: "",
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.deviceAdmin.device.deleteBulk").reply(() => {
    const errorList = ["", "internal_error"];
    return [
      200,
      {
        data: { totalCount: 100 },
        error: errorList[getRandomInt(0, errorList.length - 1)],
      },
    ];
  });

  axiosMockAdapterInstance.onPost("/api/me.deviceAdmin.device.count").reply(() => {
    const errorList = ["", "internal_error"];
    return [
      200,
      {
        data: {
          count: 100,
        },
        error: errorList[getRandomInt(0, errorList.length - 1)],
      },
    ];
  });
  axiosMockAdapterInstance.onPost("/api/me.deviceAdmin.token.generate").reply(() => {
    return [
      200,
      {
        data: {
          orgID: "123",
          token: "123",
        },
        error: "",
      },
    ];
  });
};
