import {
  AAITextField,
  Alert,
  CopyText,
  PasswordField,
  Transition,
  getFormattedDateTimeTextShort,
  postAPI,
  showNotification,
  useAuth,
} from "@antara/shared";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isDateGreaterThanCurrent } from "src/utils/deviceutil";

const Results = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [date, setDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [displayToken, setDisplayToken] = useState("");

  const isTokenExist =
    auth.user.deviceAdminTokenExpiresAt.toString() !== "1970-01-01T00:00:00Z" &&
    auth.user.deviceAdminTokenExpiresAt.toString() !== "0001-01-01T00:00:00Z";

  useEffect(() => {
    if (auth.user.deviceAdminTokenExpiresAt && isDateGreaterThanCurrent(auth.user.deviceAdminTokenExpiresAt)) {
      const day = getFormattedDateTimeTextShort(auth.user.deviceAdminTokenExpiresAt);
      setDate(day);
    }
  }, [auth.user.deviceAdminTokenExpiresAt]);

  const checkTokenExpired = () => {
    if (auth.user.deviceAdminTokenExpiresAt && isDateGreaterThanCurrent(auth.user.deviceAdminTokenExpiresAt)) {
      const day = getFormattedDateTimeTextShort(auth.user.deviceAdminTokenExpiresAt);
      setDate(day);
      setOpen(true); //open Modal
    } else {
      generateSSHToken();
    }
  };

  const closeHandler = () => {
    setOpen(false);
  };

  const generateSSHToken = () => {
    const req = {
      orgID: auth.user.orgID,
    };

    postAPI("me.deviceAdmin.token.generate", req, async (resp: any) => {
      if (!resp.error || resp.error === "") {
        showNotification(enqueueSnackbar, t("ssh_token_generate_success"), "success");
        setDisplayToken(resp.data.token);
        const day = getFormattedDateTimeTextShort(resp.data.expiresAt);
        setDate(day);
        await auth.updateUserProps(auth.user.readOnly, "subscription", resp.data.expiresAt); // not setting readonly and subscription in auth only setting expiresAt
      } else {
        showNotification(enqueueSnackbar, t("ssh_token_generate_error"), "error");
      }
      closeHandler();
    });
  };

  return (
    <Card
      sx={{
        pr: 1,
        pl: 1,
        pt: 1,
        mb: 3,
      }}
    >
      <Box sx={{ minHeight: 180 }}>
        <Box py={2} px={{ sm: 0, md: 2 }}>
          <Grid>
            <Typography
              sx={{
                pb: 0,
                pt: 0,
              }}
              variant="subtitle2"
            >
              <Alert severity="info">{t("ssh_helper_text")}</Alert>
            </Typography>
          </Grid>
        </Box>
        <Box sx={{ mb: 1, pb: 1 }}>
          <Grid item xs={12}>
            <AAITextField fullWidth label={t("ssh_user_name")} name="loginName" value={auth.user.loginName} variant="outlined" />
          </Grid>
          {auth.user.deviceAdministrationCredential && (
            <>
              {!displayToken && (
                <>
                  {isTokenExist && (
                    <Box pt={2} pb={1} px={{ sm: 0, md: 2 }} sx={{ display: "flex", gap: "16px" }}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography
                          sx={{
                            pb: 0,
                            pt: 0,
                          }}
                          variant="subtitle2"
                        >
                          <Alert severity="warning">
                            {isTokenExist && date ? (
                              <>
                                {t("ssh_token_exist")} <strong>{date}.</strong> {t("if_required")}
                              </>
                            ) : (
                              <>{t("ssh_token_expired")}</>
                            )}
                          </Alert>
                        </Typography>
                      </Grid>
                    </Box>
                  )}

                  <Box pt={2} pb={1} px={{ sm: 0, md: 2 }} sx={{ display: "flex", gap: "16px" }}>
                    <Grid item xs={12} md={8} lg={10}>
                      <Typography
                        sx={{
                          pb: 0,
                          pt: 0,
                        }}
                        variant="subtitle2"
                      >
                        {!auth.user.readOnly
                          ? isTokenExist && date
                            ? t("ssh_regenerate_token_message")
                            : t("ssh_generate_token_message")
                          : t("ssh_generate_token_readonly_message")}
                      </Typography>
                    </Grid>
                    {!auth.user.readOnly && (
                      <Grid item xs={8} md={4} lg={2} alignItems="center" sx={{ display: "flex", mb: 2 }}>
                        <Button color="primary" type="submit" variant="contained" onClick={checkTokenExpired}>
                          {isTokenExist ? t("Regenerate") : t("Generate")}
                        </Button>
                      </Grid>
                    )}
                  </Box>
                </>
              )}
              {displayToken && (
                <>
                  <Box pt={2} pb={1} px={{ sm: 0, md: 2 }}>
                    <Grid>
                      <Typography
                        sx={{
                          pb: 0,
                          pt: 0,
                        }}
                        variant="subtitle2"
                      >
                        <Alert severity="info">
                          {t("ssh_token_exist")} <strong>{date}.</strong>
                          {t("ssh_token_one_time_message")}
                        </Alert>
                      </Typography>
                    </Grid>
                  </Box>
                  <Box sx={{ px: 2, display: "flex", gap: "16px", justifyContent: "space-between" }}>
                    <Grid item xs={12} md={8} lg={10} pt="1em">
                      <PasswordField label={t("ssh_password")} value={displayToken} />
                    </Grid>
                    <Grid item xs={8} md={4} lg={2} alignItems="center" pt={2} sx={{ display: "flex", mb: 1, justifyContent: "center" }}>
                      <CopyText text={displayToken} copyTxt={t("Copy")} copiedTxt={t("Copied")} height="1em" useBtn />
                    </Grid>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={closeHandler} TransitionComponent={Transition}>
        <DialogTitle
          sx={{
            p: 3,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t("updating_token")}
          </Typography>
        </DialogTitle>
        <Box px={2}>
          <Typography variant="body1" gutterBottom sx={{ pl: "1em" }}>
            {t("update_token_message_part1")}
            <strong>{date}.</strong>
            {t("update_token_message_part2")}
          </Typography>
        </Box>
        <DialogActions
          sx={{
            p: 2,
          }}
        >
          <Button color="secondary" onClick={closeHandler} id="id_button_cancel">
            {t("Cancel")}
          </Button>
          <Button variant="contained" onClick={generateSSHToken} id="id_button_confirm">
            {t("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Results;
