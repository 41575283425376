import { Typography, createSvgIcon } from '@mui/material';
import { add } from 'date-fns';
import { t } from 'i18next';
import React from 'react';
import { CardOverviewProps } from '../../components/Card/CardOverview';
import { CardField } from '../../components/Card/model';
import { FilterConfig, FilterConfigs } from '../../components/Filter';
import { CsvColumn } from '../../components/ImportCsv';
import Label from '../../components/Label';
import { BodyCell, HeadCell } from '../../components/Table/model';
import { Guest, GuestClient, GuestStatus } from '../../models/guest';
import { getFormattedRegularDate } from '../../utils/dateutils';
import { formatMacKeyColumn, getDisplayMac } from '../../utils/macutil';
import {
  emphasizedFieldWrapper,
  subtitleStyleWrapper,
} from '../../utils/styleutils';

export const ClientIcon = createSvgIcon(
  <svg
    className="svg-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M179.2 716.8l665.6 0c42.3424 0 76.8-34.4576 76.8-76.8l0-409.6c0-42.3424-34.4576-76.8-76.8-76.8l-665.6 0c-42.3424 0-76.8 34.4576-76.8 76.8l0 409.6c0 42.3424 34.4576 76.8 76.8 76.8zM153.6 230.4c0-14.1312 11.4688-25.6 25.6-25.6l665.6 0c14.1312 0 25.6 11.4688 25.6 25.6l0 409.6c0 14.1312-11.4688 25.6-25.6 25.6l-665.6 0c-14.1312 0-25.6-11.4688-25.6-25.6l0-409.6z" />
    <path d="M998.4 768l-972.8 0c-14.1312 0-25.6 11.4688-25.6 25.6l0 51.2c0 42.3424 34.4576 76.8 76.8 76.8l870.4 0c42.3424 0 76.8-34.4576 76.8-76.8l0-51.2c0-14.1312-11.4688-25.6-25.6-25.6zM947.2 870.4l-870.4 0c-14.1312 0-25.6-11.4688-25.6-25.6l0-25.6 921.6 0 0 25.6c0 14.1312-11.4688 25.6-25.6 25.6z" />
  </svg>,
  'Client'
);

// Used in the drop-down for Status selection in main guests
const statusList = [
  { label: 'Any', value: 'any' },
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
  { label: 'Pending', value: 'pendingApproval' },
];

const statusListUPSK = [
  { label: 'Any', value: 'any' },
  { label: 'Enabled', value: 'enabled' },
  { label: 'Disabled', value: 'disabled' },
];

// Create a Label component for Status, used in multiple places
export const getGuestStatusLabel = (guestStatus: GuestStatus): JSX.Element => {
  const map = {
    enabled: {
      text: t('Enabled'),
      color: 'success',
    },
    disabled: {
      text: t('Disabled'),
      color: 'error',
    },
    pendingApproval: {
      text: t('Pending'),
      color: 'warning',
    },
  };

  const { text, color }: any = map[guestStatus];

  return <Label color={color}>{text}</Label>;
};

export const getTypeLabel = (portalID, cardView = false): JSX.Element => {
  const map = {
    portal: {
      text: t('id_guests_portal_user'),
      color: 'info',
    },
    upsk: {
      text: t('id_guests_upsk_user'),
      color: 'purple',
    },
  };

  const { text, color }: any =
    map[portalID && portalID !== '-' ? 'portal' : 'upsk'];

  return (
    <Label sx={{ mt: cardView ? 1 : 0 }} color={color}>
      {text}
    </Label>
  );
};

export const getTypeLabelForCard = (portalID): JSX.Element => {
  return getTypeLabel(portalID, true);
};

// const getGuestTypeLabel = (guestType: GuestType): JSX.Element => {
//   const map = {
//     individual: {
//       text: t('Individual'),
//       color: 'primary',
//     },
//     batch: {
//       text: t('Batch'),
//       color: 'purple',
//     },
//   };

//   const { text, color }: any = map[guestType];

//   return <Label color={color}>{text}</Label>;
// };

type ClientStatus = 'enabled' | 'disabled' | 'unknown';

const getClientStatusLabel = (clientStatus: ClientStatus): JSX.Element => {
  if (clientStatus === 'unknown') {
    return <Typography></Typography>;
  }

  const map = {
    enabled: {
      text: t('Enabled'),
      color: 'success',
    },
    disabled: {
      text: t('Disabled'),
      color: 'error',
    },
  };

  const { text, color }: any = map[clientStatus];

  return <Label color={color}>{text}</Label>;
};

export const getDeviceLimitReadOnlyLabel = (value): JSX.Element => {
  if (value) {
    return <>{value}</>;
  }
  return <Typography>{t('id_guests_not_applicable')}</Typography>;
};

export const GuestDefaults: Guest = {
  orgID: '',
  loginName: '',
  email: '',
  status: 'enabled',
  deviceLimit: 0,
  portalID: 0,
  validFrom: new Date(),
  validTo: add(new Date(), { hours: 8 }), // 8 hours
  name: '',
  company: '',
  phone: '',
  address: '',
  notes: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const GuestClientDefault: GuestClient = {
  orgID: '',
  zoneID: 0,
  clientID: '',
  mac: '',
  username: '',
  userID: '',
  pskPassphrase: '',
  deviceType: '',
  description: '',
  status: 'enabled',
  createdAt: new Date(),
  updatedAt: new Date(),
  validFrom: new Date(),
  validTo: add(new Date(), { hours: 8 }),
};

export const GuestUserTabs = [
  {
    value: 'all',
    label: 'all_users',
  },
  {
    value: 'password',
    label: 'portal',
  },
  {
    value: 'upsk',
    label: 'UPSK',
  },
];

export const guestUserCardOverviewFields: CardField[] = [
  {
    fieldName: 'loginName',
    fieldDecoratorFn: emphasizedFieldWrapper,
  },
  {
    fieldName: 'email',
    fieldDecoratorFn: subtitleStyleWrapper,
  },
  {
    fieldName: 'portalID',
    fieldDecoratorFn: getTypeLabelForCard,
  },
];

export const guestUserCardConfig: CardOverviewProps = {
  avatarField: 'loginName',
  statusField: 'status',
  statusFieldDecoratorFn: getGuestStatusLabel,
  cardFields: guestUserCardOverviewFields,
};

export const getGuestUserFilterConfig = (isUPSK: boolean): FilterConfig[] => [
  {
    filterType: 'text',
    field: 'query',
    label: 'id_guests_search_desc',
    xs: 8,
    md: 10,
  },
  {
    filterType: 'select',
    field: 'status',
    xs: 4,
    md: 2,
    dropdownOptions: isUPSK ? statusListUPSK : statusList,
    label: 'Status',
  },
  {
    filterType: 'select',
    field: 'userType',
    xs: 4,
    md: 2,
    hide: true,
    pageFilter: true,
  },
];

export const operatorGuestUserFilterConfig = (
  userID,
  isUPSK: boolean
): FilterConfig[] => {
  return [
    {
      filterType: 'select',
      field: 'sponsorID',
      xs: 12,
      md: 3,
      dropdownOptions: [
        { label: 'My Guests', value: userID },
        { label: 'All Guests', value: 'any' },
      ],
      label: 'id_guests_filter_user_type',
      initVal: userID,
      pageFilter: true,
    },
    {
      filterType: 'text',
      field: 'query',
      label: 'id_guests_search_desc',
      xs: 8,
      md: 7,
    },
    {
      filterType: 'select',
      field: 'status',
      xs: 4,
      md: 2,
      dropdownOptions: isUPSK ? statusListUPSK : statusList,
      label: 'Status',
    },
    {
      filterType: 'select',
      field: 'userType',
      xs: 4,
      md: 2,
      hide: true,
      pageFilter: true,
    },
  ];
};

export const onboardGuestSponsorHeadCells: HeadCell[] = [
  {
    id: 'loginName',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_username'),
    allowSort: false,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_email'),
    allowSort: false,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_status'),
    allowSort: false,
  },
  {
    id: 'validFrom',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_activation_date'),
    allowSort: false,
  },
  {
    id: 'validTo',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_expiration_date'),
    allowSort: false,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    allowSort: false,
    centerAlign: true,
  },
];

export const onboardGuestSponsorBodyCells: BodyCell[] = [
  {
    id: 'loginName',
    bold: true,
  },
  {
    id: 'email',
  },
  {
    id: 'status',
    labelFn: getGuestStatusLabel,
  },
  {
    id: 'validFrom',
    labelFn: getFormattedRegularDate,
  },
  {
    id: 'validTo',
    labelFn: getFormattedRegularDate,
  },
];

export const guestUserHeadCells: HeadCell[] = [
  {
    id: 'loginName',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_username'),
    allowSort: false,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_email'),
    allowSort: false,
  },
  {
    id: 'sponsorName',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_sponsor'),
    allowSort: false,
  },
  {
    id: 'portalID',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_user_type'),
    allowSort: false,
  },
  // {
  //   id: "guestType",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Type",
  //   allowSort: false,
  // },
  // {
  //   id: "batchName",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Batch Name",
  //   allowSort: false,
  // },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_status'),
    allowSort: false,
  },
  {
    id: 'validFrom',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_activation_date'),
    allowSort: false,
  },
  {
    id: 'validTo',
    numeric: false,
    disablePadding: false,
    label: t('id_guests_expiration_date'),
    allowSort: false,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    allowSort: false,
    centerAlign: true,
  },
];

export const guestUserCsvColumns: CsvColumn[] = [
  {
    displayName: 'username',
    type: 'string',
    checkEmptyValue: true,
    uniqueValue: true,
  },
  {
    displayName: 'email',
    type: 'email',
    checkEmptyValue: true,
    uniqueValue: true,
  },
  {
    displayName: 'name',
    type: 'string',
  },
  {
    displayName: 'company',
    type: 'string',
  },
  {
    displayName: 'phone',
    type: 'string',
  },
  {
    displayName: 'address',
    type: 'string',
  },
  {
    displayName: 'notes',
    type: 'string',
  },
];

export const guestUserSampleData = [
  {
    username: 'user1',
    email: 'user1@test.com',
    name: 'user 1',
    company: 'company 1',
    phone: '1122334455',
    address: 'Europe',
    notes: '',
  },
  {
    username: 'user2',
    email: 'user2@test.com',
    name: 'user 2',
    company: 'company 1',
    phone: '1122334466',
    address: 'Europe',
    notes: '',
  },
  {
    username: 'user3',
    email: 'user3@test.com',
    name: 'user 3',
    company: 'company 2',
    phone: '1122334478',
    address: 'Europe',
    notes: 'guest',
  },
  {
    username: 'user4',
    email: 'user4@test.com',
    name: 'user 4',
    company: 'company 3',
    phone: '112233445599',
    address: 'Europe',
    notes: '',
  },
  {
    username: 'user5',
    email: 'user5@test.com',
    name: 'user 5',
    company: 'company 3',
    phone: '112233449811',
    address: 'USA',
    notes: '',
  },
];

// Definition of table header -  order matters
export const guestUserClientsHeadCells: HeadCell[] = [
  {
    id: 'mac',
    numeric: false,
    disablePadding: false,
    label: 'mac_address',
    allowSort: false,
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    allowSort: false,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    allowSort: false,
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: '',
    allowSort: false,
    centerAlign: true,
  },
];

export const guestUserClientsBodyCells: BodyCell[] = [
  {
    id: 'mac',
    bold: true,
    labelFn: formatMacKeyColumn,
  },
  {
    id: 'description',
  },
  {
    id: 'status',
    labelFn: getClientStatusLabel,
  },
];

export const getGuestUserClientsFilterConfig = (userID) => {
  let fcData: FilterConfig[];
  fcData = [
    {
      filterType: 'text',
      field: 'userID',
      label: 'id_guest_user_clients_search_desc',
      xs: 8,
      md: 10,
      initVal: userID,
      hide: true,
      pageFilter: true,
    },
    {
      filterType: 'text',
      field: 'query',
      label: 'id_guest_user_clients_search_desc',
      xs: 8,
      md: 9,
    },
    {
      filterType: 'select',
      field: 'status',
      xs: 4,
      md: 3,
      dropdownOptions: statusListUPSK,
      label: 'Status',
    },
  ];
  return fcData;
};

export const getOperatorGuestUserFilterConfig = (userID): FilterConfigs => {
  return {
    all: operatorGuestUserFilterConfig(userID, false),
    password: operatorGuestUserFilterConfig(userID, false),
    upsk: operatorGuestUserFilterConfig(userID, true),
  };
};

export const guestUserFilterConfig: FilterConfigs = {
  all: getGuestUserFilterConfig(false),
  password: getGuestUserFilterConfig(false),
  upsk: getGuestUserFilterConfig(true),
};

export const getGuestClientName = (guestClient: GuestClient): string => {
  return getDisplayMac(guestClient.mac);
};

export const getGuestClientDeleteMsg = (): string => {
  return 'id_guest_user_client_list_delete_msg';
};
