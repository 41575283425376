import { Button, IconButton, Tooltip } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAuth from '../../hooks/useAuth';

export interface PageAction {
  callbackHandler: any;
  caption?: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  variant?: 'text' | 'contained' | 'outlined';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  iconOnly?: boolean;
  shortCaption?: string;
  showForReadonlyUser?: boolean;
}

export default function PageAction(props: PageAction) {
  const { t }: { t: any } = useTranslation();
  const { mobileView } = useContext(GlobalContext);
  const readonly = useAuth().user.readOnly;
  const showAction = () => {
    return !readonly || (readonly && props.showForReadonlyUser);
  };
  return (
    <>
      {showAction() && (
        <>
          {props.iconOnly && !mobileView ? (
            <Tooltip title={t(props.caption)}>
              <IconButton
                sx={{
                  ml: 1,
                  border: '1px solid',
                  borderColor: 'primary.light',
                }}
                onClick={props.callbackHandler}
                color={props.color ? props.color : 'primary'}
                id={'id_page_button_' + props.caption}
              >
                <props.icon />
              </IconButton>
            </Tooltip>
          ) : mobileView ? (
            <>
              {props.icon ? (
                <Tooltip title={t(props.caption)}>
                  <IconButton
                    sx={{
                      ml: 1,
                      border: '1px solid',
                      borderColor: 'primary.light',
                    }}
                    size="small"
                    onClick={props.callbackHandler}
                    color={props.color ? props.color : 'primary'}
                    id={'id_page_button_' + props.caption}
                  >
                    <props.icon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Button
                  sx={{
                    ml: 1,
                  }}
                  onClick={props.callbackHandler}
                  variant={props.variant ? props.variant : 'contained'}
                  color={props.color ? props.color : 'primary'}
                  id={'id_page_button_' + props.caption}
                  size="small"
                >
                  {t(props.shortCaption ? props.shortCaption : props.caption)}
                </Button>
              )}
            </>
          ) : (
            <Button
              sx={{
                ml: 1,
              }}
              onClick={props.callbackHandler}
              variant={props.variant ? props.variant : 'contained'}
              startIcon={<props.icon fontSize="small" />}
              color={props.color ? props.color : 'primary'}
              id={'id_page_button_' + props.caption}
            >
              {t(props.caption)}
            </Button>
          )}
        </>
      )}
    </>
  );
}
