import { FC, ReactNode, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Box, useTheme } from '@mui/material';
import React from 'react';

interface ScrollbarProps {
  className?: string;
  children?: ReactNode;
  scrollToBottom?: boolean;
  enableScrollBottom?: boolean;
  onScrolledToBottom?();
  onScrollToElementByID?(action: 'up' | 'down');
  scrollElementID?: string;
  disableHorizontalScrolling?: boolean;
  disableScroll?: boolean;
  forceNotifyBottomScroll?: boolean; // Send onScrolledToBottom event if content is smaller than container height
}

const Scrollbar: FC<ScrollbarProps> = ({
  className,
  children,
  scrollToBottom,
  enableScrollBottom,
  onScrolledToBottom,
  onScrollToElementByID,
  scrollElementID,
  disableHorizontalScrolling,
  disableScroll,
  forceNotifyBottomScroll,
  ...rest
}) => {
  const theme = useTheme();
  const scrollRef = useRef<any>(null);
  const elementReachedTop = useRef<boolean>();

  useEffect(() => {
    if (scrollRef && scrollRef.current && enableScrollBottom) {
      scrollRef.current.scrollToBottom();
    }
  }, [scrollToBottom, enableScrollBottom]);

  useEffect(() => {
    if (forceNotifyBottomScroll) {
      const target = scrollRef.current;
      if (target) {
        // Check if the scrollHeight of the container exceeds its clientHeight
        const hasVerticalScrollbar =
          target.getScrollHeight() > target.getClientHeight();
        if (!hasVerticalScrollbar && onScrolledToBottom) {
          onScrolledToBottom();
        }
      }
    }
  }, [scrollRef.current, forceNotifyBottomScroll]);

  const handleScroll = () => {
    const target = scrollRef.current;
    if (onScrollToElementByID) {
      const header = document.getElementById(scrollElementID);
      const diff =
        header.getBoundingClientRect().bottom -
        target.container.getBoundingClientRect().top;
      if (!elementReachedTop.current && diff < 0) {
        elementReachedTop.current = true;
        onScrollToElementByID('down');
      } else if (elementReachedTop.current && diff > 0) {
        elementReachedTop.current = false;
        onScrollToElementByID('up');
      }
    }
    if (onScrolledToBottom) {
      let currentScroll = target.getClientHeight() + target.getScrollTop();
      if (
        currentScroll + 1 > target.getScrollHeight() ||
        currentScroll > target.getScrollHeight()
      ) {
        onScrolledToBottom();
      }
    }
  };

  return (
    <>
      {!disableScroll ? (
        <Scrollbars
          ref={scrollRef}
          autoHide
          onScroll={
            onScrollToElementByID || onScrolledToBottom ? handleScroll : null
          }
          renderThumbVertical={() => {
            return (
              <Box
                sx={{
                  width: 5,
                  background: `${
                    theme.palette.mode === 'light'
                      ? theme.colors.alpha.black[10]
                      : theme.colors.alpha.white[10]
                  }`,
                  borderRadius: `${theme.general.borderRadiusLg}`,
                  transition: `${theme.transitions.create(['background'])}`,

                  '&:hover': {
                    background: `${
                      theme.palette.mode === 'light'
                        ? theme.colors.alpha.black[30]
                        : theme.colors.alpha.white[30]
                    }`,
                  },
                }}
              />
            );
          }}
          {...rest}
        >
          <Box
            sx={{ ...(disableHorizontalScrolling && { overflowX: 'hidden' }) }}
          >
            {children}
          </Box>
        </Scrollbars>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Scrollbar;
