import { Alert as A, AlertProps, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { important } from '../../utils/styleutils';

const Alert: FC<AlertProps> = ({
  severity = 'info',
  children,
  sx,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <A
      severity={severity}
      {...rest}
      sx={{
        ...sx,
        color:
          theme.palette.mode === 'light'
            ? theme.colors.alpha.black[100]
            : '-moz-initial',
        '& .MuiAlert-icon': {
          color:
            theme.palette.mode === 'light'
              ? important(theme.colors[severity].dark)
              : '-moz-initial',
        },
        border: `1px solid ${theme.colors[severity].dark}`,
      }}
    >
      {children}
    </A>
  );
};

export default Alert;
