import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export interface CardTreeProps {
  title: string;
  data: Array<any>;
}

export default function CardTree({ title, data }: CardTreeProps) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        py={1.5}
      >
        <Typography variant="h5" fontWeight={600} sx={{ pl: 1, pt: 0.5 }}>
          {t(title)}
        </Typography>
      </Box>
      <Divider />
      {data && data.length > 0 ? (
        <List dense sx={{ pl: 1, pt: 1 }}>
          {data.map((item, i) => {
            return (
              <ListItem key={i}>
                <Stack direction="row" spacing={1}>
                  {item.error ? (
                    <Tooltip title={t('Action failed')} arrow>
                      <ErrorOutlineIcon color="error" />
                    </Tooltip>
                  ) : (
                    <CheckCircleOutlineIcon
                      fontSize="small"
                      sx={{
                        color:
                          theme.palette.mode === 'light'
                            ? theme.colors.success.light
                            : 'rgb(124 158 128)',
                      }}
                    />
                  )}
                  <ListItemText key={i}>
                    <Typography fontWeight={500}>{t(item.name)}</Typography>
                    {item.values.map((subItem, j) => {
                      return (
                        <Fragment key={j}>
                          <Box ml={1}>
                            <Chip
                              variant="outlined"
                              size="small"
                              sx={{
                                px: 1,
                                my: 0.5,
                                mx: 0.5,
                                background:
                                  theme.palette.mode === 'light'
                                    ? '#FBFBF9'
                                    : `${theme.colors.alpha.black[50]}`,
                                borderRadius: 0.5,
                              }}
                              label={subItem.key + ' = ' + subItem.value}
                            ></Chip>
                          </Box>
                        </Fragment>
                      );
                    })}
                  </ListItemText>
                </Stack>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Box display={'flex'} justifyContent={'center'} p={2}>
          <Typography fontWeight={500} fontSize={15}>
            {t(data ? 'Not available' : 'Loading...')}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
