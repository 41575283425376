import { GlobalContext, useAuth } from "@antara/shared";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ComputerIcon from "@mui/icons-material/Computer";
import DevicesIcon from "@mui/icons-material/Devices";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import PasswordIcon from "@mui/icons-material/Password";
import SettingsIcon from "@mui/icons-material/Settings";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import Authenticated from "src/components/Authenticated";
import LicenseWrapper from "src/components/License/LicenseWrapper";
import AccentHeaderLayout from "./AccentHeaderLayout";
import { MenuItems } from "./base";

function LayoutWrapper() {
  const { id } = useParams();
  const auth = useAuth();
  const landingPage = "/" + id + "/clients";
  const [menuLoaded, setMenuLoaded] = useState(false);
  const { mobileView } = useContext(GlobalContext);

  const [menuItems, setMenuItems] = useState<MenuItems[]>([
    {
      id: 1,
      items: [
        {
          name: "manage_clients",
          link: "/" + id + "/clients",
          icon: DevicesIcon,
        },
      ],
    },
    {
      id: 2,
      items: [
        {
          name: "register_client",
          link: "/" + id + "/addclient",
          icon: AppRegistrationIcon,
          hideForReadonlyUser: true,
        },
      ],
    },
    {
      id: 3,
      items: [
        {
          name: "wifi_passphrase",
          link: "/" + id + "/passphrase",
          icon: PasswordIcon,
        },
      ],
    },
  ]);

  useEffect(() => {
    if (auth.user?.deviceAdministrationAllowed && !mobileView) {
      const isDeviceAdminIndex = menuItems.findIndex((e) => e.heading === "device_admin");
      //  if we update auth.user duplicate device_admin was getting push. so restricting it if once pushed
      if (isDeviceAdminIndex === -1) {
        menuItems.push({
          id: 4,
          heading: "device_admin",
          items: [
            {
              name: "Devices",
              link: "/" + id + "/administration/devices",
              icon: ComputerIcon,
            },
            {
              name: "Setup",
              link: "/" + id + "/administration/setup",
              icon: SettingsIcon,
            },
            {
              name: "ssh_menu_item",
              link: "/" + id + "/administration/ssh",
              icon: PasswordIcon,
            },
          ],
        });
      }
    }
    if (auth.user?.isGuestAdminSponsor || auth.user?.isGuestAdminOperator) {
      const isGuestIndex = menuItems.findIndex((e) => e.heading === "guests");
      //  if we update auth.user duplicate guest_users was getting push. so restricting it if once pushed
      if (isGuestIndex === -1) {
        menuItems.push({
          id: 5,
          heading: "guests",
          items: [
            {
              name: "guests_users",
              link: "/" + id + "/guests/users",
              icon: FollowTheSignsIcon,
            },
          ],
        });
      }
    }
    setMenuItems(menuItems);
    setMenuLoaded(true);
  }, [auth.user, id, menuItems, mobileView]);

  return (
    <Authenticated>
      <LicenseWrapper>{menuLoaded && <AccentHeaderLayout menu={menuItems} landingURL={landingPage} />}</LicenseWrapper>
    </Authenticated>
  );
}

export default LayoutWrapper;
