import { useContext } from "react";

import { Box, Divider, Drawer, styled, useTheme } from "@mui/material";

import { SidebarContext, Scrollbar, Logo, SidebarMenu } from "@antara/shared";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 7;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(1)};
        display: flex;
        align-items: center;
        justify-content: center;
`
);

function Sidebar(props) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: "none",
            lg: "inline-block",
          },
          position: "fixed",
          left: 0,
          top: 0,
        }}
      >
        <Scrollbar>
          {/* <TopSection>
            <SidebarTopSection />
          </TopSection> */}
          <SidebarMenu menuItems={props.menuItems} />
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor="left"
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper>
          <Scrollbar>
            <TopSection>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "100%",
                }}
              >
                <Logo landingURL={props.landingURL} sidebar />
              </Box>
            </TopSection>
            <Divider />
            <SidebarMenu menuItems={props.menuItems} />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
