import { StatusPage, SuspenseLoader } from "@antara/shared";
import { Suspense, lazy } from "react";
import { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import Authenticated from "./components/Authenticated";
import LicenseWrapper from "./components/License/LicenseWrapper";
import LayoutWrapper from "./layouts";
import Devices from "./pages/deviceadmin/devices";
import ConnectDevice from "./pages/deviceadmin/devices/ConnectDevice";
import UpsertDevice from "./pages/deviceadmin/devices/UpsertDevice";
import Setup from "./pages/deviceadmin/setup";
import SshCredentials from "./pages/deviceadmin/sshcredentials";
import ViewPassphrase from "./pages/entry/passphrase";
import IdentityGuests from "./pages/guests/users";
import UpsertGuest from "./pages/guests/users/UpsertGuest";
import SignIn from "./pages/signin";
import SignOut from "./pages/signout";
import OnboardStatusPage from "./pages/status";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Register = Loader(lazy(() => import("./pages/register")));
const Clients = Loader(lazy(() => import("./pages/clients")));
const AddClient = Loader(lazy(() => import("./pages/addclient")));
const Passphrase = Loader(lazy(() => import("./pages/passphrase")));
const ResetPassword = Loader(lazy(() => import("./pages/resetpassword")));

const appRoutes = [
  {
    path: "clients",
    element: <Clients />,
  },
  {
    path: "addclient",
    element: <AddClient />,
  },
  {
    path: "passphrase",
    element: <Passphrase />,
  },
  {
    path: "resetpassword",
    element: <ResetPassword />,
  },
  {
    path: "administration",
    children: [
      { path: "", element: <Navigate to="devices" replace /> },
      { path: "devices", element: <Devices /> },
      { path: "devices/connect/:deviceID", element: <ConnectDevice /> },
      { path: "devices/:deviceID", element: <UpsertDevice /> },
      { path: "setup", element: <Setup /> },
      { path: "ssh", element: <SshCredentials /> },
    ],
  },
  {
    path: "guests",
    children: [
      { path: "", element: <Navigate to="users" replace /> },
      { path: "users", element: <IdentityGuests /> },
      { path: "users/:id", element: <UpsertGuest /> },
    ],
  },
  {
    path: "*",
    element: <StatusPage status="404" homeURLFromParam />,
  },
];

const routes: RouteObject[] = [
  {
    path: ":id/404",
    element: <StatusPage status="404" homeURLFromParam />,
  },
  {
    path: ":id/403",
    element: <StatusPage status="403" homeURLFromParam />,
  },
  {
    path: ":id/status/503",
    element: <StatusPage status="503" homeURLFromParam />,
  },
  {
    path: ":id/signout",
    element: <SignOut />,
  },
  {
    path: "",
    element: <OnboardStatusPage />,
  },
  {
    path: ":id",
    element: <SignIn />,
  },
  {
    path: ":id/network/:networkid",
    element: <SignIn />,
  },
  {
    path: ":id/register",
    element: (
      <Authenticated>
        <LicenseWrapper>
          <Register />
        </LicenseWrapper>
      </Authenticated>
    ),
  },
  {
    path: ":id/passphrase/view",
    element: (
      <Authenticated>
        <LicenseWrapper>
          <ViewPassphrase />
        </LicenseWrapper>
      </Authenticated>
    ),
  },
  {
    path: ":id/*",
    element: <LayoutWrapper></LayoutWrapper>,
    children: appRoutes,
  },
];

export default routes;
