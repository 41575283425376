import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Card,
  CardHeader,
  DialogActions,
  Divider,
  Grid,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { PageAction } from '../../../components/PageTitle/PageAction';
import { CardAction } from '../../../components/common/model';
import { GuestClient } from '../../../models/guest';
import CrudTemplate from '../../../templates/CrudTemplate';
import { CrudConfig } from '../../../templates/CrudTemplate/CrudConfig';
import {
  ClientIcon,
  GuestClientDefault,
  getGuestClientDeleteMsg,
  getGuestClientName,
  getGuestUserClientsFilterConfig,
  guestUserClientsBodyCells,
  guestUserClientsHeadCells,
} from '../GuestConfig';

const GuestClientList = ({
  userID,
  forceUpdate,
  cancelHandler,
  showClients,
  toggleClients,
  isOnboard,
  listApiURL,
  deleteApiURL,
  URLPrefix,
}: {
  userID: string;
  forceUpdate: boolean;
  cancelHandler: any;
  showClients: boolean;
  toggleClients: any;
  isOnboard: boolean;
  listApiURL?: string;
  deleteApiURL?: string;
  URLPrefix?: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let filterConfig = getGuestUserClientsFilterConfig(userID);
  const pageActions: PageAction[] = [];

  const showClientDetails = (selectedData: GuestClient): void => {
    navigate(
      URLPrefix +
        'clients/' +
        selectedData.clientID +
        '?from=' +
        URLPrefix +
        'users/' +
        selectedData.userID
    );
  };

  const cardActions: CardAction[] = isOnboard
    ? []
    : [
        {
          caption: 'Update',
          callbackFn: showClientDetails,
          icon: EditIcon,
          color: 'primary',
        },
      ];

  const guestClientsConfig: CrudConfig = {
    resourceName: 'id_guest_user_client_list',
    primaryKey: 'clientID',
    newEntity: GuestClientDefault,

    listApiPath: listApiURL,
    listApiResponseHeader: 'clients',
    deleteApiPath: deleteApiURL,
    onRowClick: isOnboard ? null : showClientDetails,

    nameField: 'mac',
    deleteKeyField: 'clientID',
    fieldsForContainsFilter: ['mac', 'description'],
    getDeleteEntityName: getGuestClientName,
    getDeleteMsg: getGuestClientDeleteMsg,

    pageHeading: 'Clients',
    pageSubheading: 'id_guest_clients_desc',
    pageActions: pageActions,
    pageIcon: ClientIcon,

    filterConfig: filterConfig,
    disableFilterCaching: true,
    disableScrollLimit: 10,
    headCells: guestUserClientsHeadCells,
    bodyCells: guestUserClientsBodyCells,
    actions: cardActions,
    cardType: 'overview',

    fetchDataByChunks: true,
    showCustomHeader: true,
  };

  return (
    <Box mb={1} display="flex">
      <Grid container direction="row" alignItems="stretch" spacing={1}>
        <Grid item xs={12}>
          <Card
            sx={{
              m: 2,
              mt: 1,
            }}
          >
            <CardHeader
              title={t('id_guests_user_clients')}
              action={
                <Button
                  id="id_button_show_clients_toggle"
                  size="small"
                  variant="outlined"
                  onClick={toggleClients}
                >
                  {showClients ? t('hide_clients') : t('show_clients')}
                </Button>
              }
            />
            {showClients && (
              <>
                <Divider sx={{ mb: 0 }} />

                <CrudTemplate
                  {...guestClientsConfig}
                  forceUpdate={forceUpdate}
                />
                <DialogActions sx={{ mr: 2, mt: 1 }}>
                  <Button
                    sx={{
                      ml: 1,
                    }}
                    onClick={cancelHandler}
                    variant="contained"
                    startIcon={<ArrowBackIcon fontSize="small" />}
                    color="primary"
                  >
                    {t('Back')}
                  </Button>
                </DialogActions>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuestClientList;
