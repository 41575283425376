import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DateTimePicker from '@mui/lab/DateTimePicker';
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import format from 'date-fns/format';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateTimeFromSelector } from '../../utils/dateutils';

interface TimeRangeOption {
  label: string;
  pluralLabel: string;
}

const timeRangeOptions: TimeRangeOption[] = [
  {
    label: 'Minute',
    pluralLabel: 'Minutes',
  },
  {
    label: 'Hour',
    pluralLabel: 'Hours',
  },
  {
    label: 'Day',
    pluralLabel: 'Days',
  },
];

export interface DateTimeRangeProps {
  callbackFn: Function;
  initVal?: { fromVal: string; toVal?: string }; // format: 5-m, 1-h, 1-d for custom both will have date values
  //eg initVal={{ fromVal: "2023-04-09T00:00:00.000+05:30", toVal: "2023-04-14T12:51:33.336+05:30" }}
}

export default function DateTimeRange(props: DateTimeRangeProps) {
  const { t }: { t: any } = useTranslation();
  const [openCustom, setOpenCustom] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(2);
  const [selectedTimeRange, setSelectedTimeRange] = useState('Last 1 Day');
  const [timeRange, setTimeRange] = useState('Last 1 Day');
  const [timeInput, setTimeInput] = useState(['5', '1', '1']);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [fromTimeCustom, setFromTimeCustom] = useState(new Date());
  const [toTimeCustom, setToTimeCustom] = useState(new Date());
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const isLoaded = useRef<boolean>(false);

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      if (props.initVal) {
        if (!props.initVal.toVal) {
          const input = props.initVal.fromVal.split('-');
          const intTime = parseInt(input[0]);
          const t = input[1];
          let index = 0;
          if (t === 'h') {
            index = 1;
          } else if (t === 'd') {
            index = 2;
          }
          setSelectedIndex(index);
          setTimeInput((prev) => {
            prev[index] = intTime.toString();
            return prev;
          });
          const label =
            intTime === 1
              ? timeRangeOptions[index].label
              : timeRangeOptions[index].pluralLabel;
          setSelectedTimeRange('Last ' + intTime.toString() + ' ' + label);
          setTimeRange('Last ' + intTime.toString() + ' ' + label);
          const toTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
          const fromTime = getDateTimeFromSelector(
            intTime.toString() + '-' + t
          );
          props.callbackFn(fromTime, toTime);
        } else {
          setSelectedTimeRange('Custom');
          setTimeRange('Custom');
          setSelectedIndex(3);
          setOpenCustom(true);
          setFromTimeCustom(new Date(props.initVal.fromVal));
          setToTimeCustom(new Date(props.initVal.toVal));
          props.callbackFn(props.initVal.fromVal, props.initVal.toVal);
        }
      } else {
        const t = 'd';
        const toTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
        const fromTime = getDateTimeFromSelector(
          timeInput[2].toString() + '-' + t
        );
        props.callbackFn(fromTime, toTime);
      }
    }
  }, [props]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleTimeChange = (fieldName: string, newValue: Date | null) => {
    setSelectedIndex(3);
    setSelectedTimeRange('Custom');
    if (fieldName === 'fromTime') {
      setFromTimeCustom(newValue);
    } else {
      setToTimeCustom(newValue);
    }
  };

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
    if (index !== 3) {
      setOpenCustom(false);
    }
    const label =
      timeInput[index] === '1'
        ? timeRangeOptions[index].label
        : timeRangeOptions[index].pluralLabel;
    setSelectedTimeRange('Last ' + timeInput[index].toString() + ' ' + label);
  };

  const selectHandler = () => {
    setOpenCustom(!openCustom);
  };

  const applyHandler = (apply: boolean) => {
    if (apply) {
      setTimeRange(selectedTimeRange);
      let fromTime: string, toTime: string;
      if (selectedIndex <= 2) {
        let t = 'min';
        if (selectedIndex === 1) {
          t = 'h';
        } else if (selectedIndex === 2) {
          t = 'd';
        }
        toTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
        fromTime = getDateTimeFromSelector(
          timeInput[selectedIndex].toString() + '-' + t
        );
      } else {
        fromTime = format(fromTimeCustom, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
        toTime = format(toTimeCustom, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      }
      props.callbackFn(fromTime, toTime);
    }
    handleClose();
  };

  const handleTimeInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    let currentInputs = Object.assign([], timeInput);
    currentInputs[selectedIndex] = event.target.value;
    setTimeInput(currentInputs);
    const label =
      currentInputs[selectedIndex] === '1'
        ? timeRangeOptions[selectedIndex].label
        : timeRangeOptions[selectedIndex].pluralLabel;
    setSelectedTimeRange(
      'Last ' + currentInputs[selectedIndex].toString() + ' ' + label
    );
    event.persist();
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        size="small"
      >
        {t(timeRange)}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <List
          sx={{
            width: '100%',
            minWidth: 250,
            bgcolor: 'background.paper',
          }}
          component="nav"
          aria-labelledby="time-range-options"
          subheader={
            <ListSubheader component="div" id="subheader">
              {t('Select time range')}
            </ListSubheader>
          }
        >
          {timeRangeOptions.map((val, index) => (
            <ListItemButton
              key={index}
              sx={{ p: 0.5, pl: 2 }}
              selected={selectedIndex === index}
              onClick={() => handleListItemClick(index)}
            >
              <TextField
                sx={{ width: '50px' }}
                margin="none"
                size="small"
                value={timeInput[index]}
                id="outlined-basic"
                variant="outlined"
                onChange={handleTimeInputChange}
              />
              <Box sx={{ pl: 1 }}>
                {t(timeInput[index] === '1' ? val.label : val.pluralLabel)}
              </Box>
            </ListItemButton>
          ))}
          <ListItemButton
            onClick={selectHandler}
            selected={selectedIndex === 3}
          >
            <ListItemText primary="Custom" />
            {openCustom ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openCustom} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 1 }} display="flex" flexDirection="column" gap={2}>
              <DateTimePicker
                label="From"
                value={fromTimeCustom}
                onChange={(newVal) => handleTimeChange('fromTime', newVal)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DateTimePicker
                label="To"
                value={toTimeCustom}
                onChange={(newVal) => handleTimeChange('toTime', newVal)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </Collapse>
        </List>
        <Box
          sx={{
            p: 1,
            textAlign: 'right',
          }}
        >
          <Button
            type="submit"
            variant="outlined"
            size="small"
            onClick={() => applyHandler(false)}
            sx={{ mr: 1 }}
          >
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            onClick={() => applyHandler(true)}
          >
            {t('Apply')}
          </Button>
        </Box>
      </Popover>
    </div>
  );
}
