import { Grid, Slider, Typography } from '@mui/material';
import React from 'react';
import useAuth from '../../hooks/useAuth';

function AAISlider({
  value,
  min,
  max,
  step,
  onChange,
  label,
}: {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange(newVal: number);
  label: string;
}) {
  const readOnly = useAuth().user.readOnly;
  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} sm={6} sx={{ ml: 1 }}>
        <Typography variant="subtitle1">{label}</Typography>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Slider
          aria-label="opacity"
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={(_, newVal) => onChange(newVal as number)}
          disabled={readOnly}
        />
      </Grid>
    </Grid>
  );
}

export default AAISlider;
