import { Order } from '../model';

export const applyFilters = (
  rows: any[],
  query: string,
  filters: Map<string, string> | any,
  columnsForContainsFilter: string[],
  order: Order,
  orderBy: string,
  boolField?: string
): any[] => {
  if (!rows) {
    return [];
  }
  return rows
    .filter((row) => {
      let matches = true;

      if (query) {
        let containsQuery = false;

        // Entites that use local filters don't include MACs
        // query = query.replaceAll(':', '');

        columnsForContainsFilter.forEach((property) => {
          if (
            row[property] &&
            row[property].toLowerCase().includes(query.toLowerCase())
          ) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          matches = false;
        }
      }

      Object.keys(filters).forEach((key) => {
        const value = filters[key];

        if (key === boolField) {
          if (value === 'true' && !row[key]) {
            matches = false;
          }
          if (value === 'false' && row[key]) {
            matches = false;
          }
        } else {
          if (value && row[key] !== value) {
            matches = false;
          }
        }
      });

      return matches;
    })
    .sort(function (a, b) {
      var test = a[orderBy] <= b[orderBy];
      if (order === 'asc') {
        return test ? -1 : 1;
      }

      return test ? 1 : -1;
    });
};

export const applyPagination = (
  rows: any[],
  page: number,
  limit: number,
  infinite?: boolean
): any[] => {
  if (infinite) {
    return rows;
  }
  return rows.slice(page * limit, page * limit + limit);
};
