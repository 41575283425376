import { GlobalContext, PageAction, PageTitle, StatusPage, postAPI, showNotification, useAuth } from "@antara/shared";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CachedIcon from "@mui/icons-material/Cached";
import ComputerIcon from "@mui/icons-material/Computer";
import Grid from "@mui/material/Grid";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { Device } from "src/models/device";
import { DeviceDefaults } from "./Config";
import TerminalComponent from "./components/TerminalComponent";

const ConnectDevice = () => {
  const { t } = useTranslation();
  const { mobileView } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { id, deviceID } = useParams();
  const auth = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState<Device>(DeviceDefaults);
  const [reconnect, setReconnect] = useState(false);

  const onBack = () => {
    navigate("/" + id + "/administration/devices");
  };

  const onReconnect = () => {
    // Check for valid session
    const req = {
      orgID: auth.user.orgID,
      id: deviceID,
      zoneID: 0,
    };
    postAPI("me.deviceAdmin.device.get", req, (resp) => {
      if (!resp.error || resp.error === "") {
        setReconnect((reconnect) => !reconnect);
      }
    });
  };

  const pageActions: PageAction[] = [
    {
      caption: "Reconnect",
      icon: CachedIcon,
      callbackHandler: onReconnect,
      showForReadonlyUser: true,
    },
    {
      caption: "Back",
      icon: ArrowBackIcon,
      callbackHandler: onBack,
      showForReadonlyUser: true,
    },
  ];

  const getDeviceDetails = useCallback(() => {
    const req = {
      orgID: auth.user.orgID,
      id: deviceID,
      zoneID: 0,
    };

    postAPI("me.deviceAdmin.device.get", req, (resp) => {
      if (!resp.error || resp.error === "") {
        setDeviceDetails(resp.data);
        setShow(true);
      } else {
        showNotification(enqueueSnackbar, t("id_device_get_err_" + resp.error), "error");
      }
    });
  }, [auth.user.orgID, enqueueSnackbar, deviceID, t]);

  const showError = useCallback(() => {
    showNotification(enqueueSnackbar, t("id_device_err_app_unavailable"), "error");
  }, [enqueueSnackbar, t]);

  useEffect(() => {
    getDeviceDetails();
  }, [getDeviceDetails]);

  return (
    <>
      {auth.user.deviceAdministrationAllowed && !mobileView ? (
        <>
          <Helmet>
            <title>{t("connect_device")}</title>
          </Helmet>
          <PageTitle
            heading={t("connect_device") + " - " + deviceDetails.host}
            icon={ComputerIcon}
            subHeading={t("connect_device_ssh")}
            actions={pageActions}
          ></PageTitle>
          <Grid
            sx={{
              px: 4,
              height: "80%",
            }}
            container
            direction="row"
            alignItems="stretch"
            spacing={4}
          >
            {show && (
              <Grid item xs={12} md={12} lg={12}>
                <TerminalComponent device={deviceDetails} reconnect={reconnect} errorHandler={showError}></TerminalComponent>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <StatusPage status="403" hideNav />
      )}
    </>
  );
};

export default ConnectDevice;
