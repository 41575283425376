import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AlertColor,
  alpha,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useAuth from '../../../hooks/useAuth';
import { isUserReadonly } from '../../../utils/rbacutils';
import { getTruncatedNameByTextBold } from '../../../utils/stringutils';
import Alert from '../../Alert';
import { CardAction } from '../../common/model';
import { CardField, CardProps } from '../model';

export interface CardKeyValueField extends CardField {
  fieldCaption?: string;
  icon?: any;
}

export interface CardKeyValueProps extends CardProps {
  nameField?: string;
  statusUpdateAction?: any;
  statusActionShowOppositeVal?: boolean;
  cardFields?: CardKeyValueField[]; // default CardFields
  getCardFields?: any; // It is a optional function that is called when cardFields need to change as per some conditions
  maxCardFields?: number;
  moreInfoFn?: any;
  title?: string;
  size?: string;
  hover?: boolean;
  showCollapse?: boolean;
  helpText?: string;
  helpTextVariant?: AlertColor;
  compact?: boolean;
  onRouteChange?(index: number): void;
}

const CardWrapper = styled(Card)(
  ({ theme }) => `
  border-color: ${
    theme.palette.mode === 'light'
      ? theme.colors.alpha.black[30]
      : theme.colors.borders[1]
  };
  border-style: solid;
  border-width: 1px;

  &.conditional:hover {
    border-color: ${
      theme.palette.mode === 'light'
        ? theme.colors.primary.light
        : theme.colors.borders[2]
    };
    border-style: solid;
    border-width: 1px;
  }

  &.conditionalOnRowClick:hover {
  background-color: ${
    theme.palette.mode === 'light' ? '#F8F9FA' : theme.colors.alpha.black[30]
  };
  }
  `
);

const SwitchSuccess = styled(Switch)(
  ({ theme }) => `
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track,
      .MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb {
        background: ${theme.colors.success.main};
      }
      
      .MuiSwitch-colorPrimary.Mui-checked {
        color: ${theme.colors.success.main};
      }
      
      .MuiSwitch-colorPrimary.Mui-checked:hover {
        background-color: ${alpha(theme.colors.success.main, 0.1)};
      }
  `
);

const getTruncatedName = (name: string): JSX.Element => {
  const displayEle = (name, noWrap = false) => {
    return (
      <Typography
        noWrap={noWrap}
        sx={{
          maxWidth: '50%',
        }}
      >
        {name || '-'}
      </Typography>
    );
  };
  if (name && name.length > 10) {
    return <Tooltip title={name}>{displayEle(name, true)}</Tooltip>;
  }
  return displayEle(name);
};

const CardFieldContent = ({ data, cardField }) => {
  if (!data[cardField.fieldName]) {
    return <>{'-'}</>;
  }
  return (
    <>
      {cardField.fieldDecoratorFn ? (
        cardField.fieldDecoratorFn(data[cardField.fieldName])
      ) : (
        <>{data[cardField.fieldName]}</>
      )}
    </>
  );
};

const getToLink = (cardField, props) => {
  let url = cardField.fieldLinkURL;
  if (props.onRouteChange) {
    props.onRouteChange(props.id);
  }
  if (cardField.fieldLinkURLParam) {
    url += props.data[cardField.fieldLinkURLParam];
  }
  if (cardField.fieldSourceURLFn) {
    url += '?from=' + cardField.fieldSourceURLFn(props.data);
    if (props.fieldLinkURLContext) {
      url += props.fieldLinkURLContext;
    }
  }
  return url;
};

const CardKeyValue: FC<CardKeyValueProps> = (props) => {
  const { t }: { t: any } = useTranslation();
  const auth = useAuth();
  const readonlyUser = isUserReadonly(auth.user.role);
  const [status, setStatus] = useState(props.data[props.statusField]);
  const [open, setOpen] = useState(false);
  const [cardFields, setCardFields] = useState(props.cardFields);
  const size = props.size === 'large' ? 6 : props.size === 'xlarge' ? 12 : 4;
  const theme = useTheme();
  const [actions, setActions] = useState<CardAction[]>();
  const navigate = useNavigate();

  useEffect(() => {
    if (readonlyUser && props.readonlyActions) {
      setActions(props.readonlyActions);
    } else {
      setActions(props.cardActions);
    }
  }, [readonlyUser, props.readonlyActions, props.cardActions]);

  useEffect(() => {
    setStatus(props.data['disabled']);
  }, [props.data]);

  useEffect(() => {
    if (props.getCardFields) {
      setCardFields(props.getCardFields(props.data));
    }
  }, [props.cardFields, props.getCardFields, props.data]);

  const onStatusUpdate = () => {
    setStatus(!status);
    props.statusUpdateAction(props.data, !status);
  };

  const onToggleCollapse = () => {
    setOpen(!open);
  };

  const rowClickHandler = () => {
    if (props.onRowClick) {
      if (props.onRouteChange) {
        props.onRouteChange(props.id);
      }
      props.onRowClick(props.data);
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={props.size === 'xlarge' ? 12 : 6}
      lg={size}
      id={props.id && 'row_' + props.id}
    >
      <CardWrapper
        className={clsx({
          conditional: props['hover'] !== undefined ? props['hover'] : true,
          conditionalOnRowClick: props.onRowClick ? true : false,
        })}
        sx={{
          ...(props.onRowClick ? { cursor: 'pointer' } : { cursor: 'auto' }),
          ...(props.selected && {
            background: `${theme.colors.secondary.lighter}`,
          }),
          ...(props.compact && {
            borderWidth: 0,
          }),
        }}
        onClick={rowClickHandler}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? `${theme.colors.alpha.black[30]}`
                : `${theme.colors.alpha.black[5]}`,
            minHeight: props.compact ? '41px' : '49px',
          }}
          onClick={(event) => {
            onToggleCollapse();
            event.stopPropagation();
          }}
          id={
            props.primaryKey
              ? 'id_button_row_' + props.primaryKey + '_toggle_collapse'
              : 'id_button_row_toggle_collapse'
          }
        >
          {props.title ? (
            <Typography variant="h5" lineHeight={1}>
              {t(props.title)}
            </Typography>
          ) : (
            <>
              {props.nameFieldDecoratorFn
                ? props.nameFieldDecoratorFn(props.data[props.nameField])
                : getTruncatedNameByTextBold(props.data[props.nameField])}
            </>
          )}
          {props.statusField &&
            props.statusFieldDecoratorFn(props.data[props.statusField])}
          {props.showCollapse && (
            <IconButton color="primary">
              {!open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          )}
        </Box>
        {(!props.showCollapse || open) && (
          <>
            <Divider />
            <Box
              sx={{
                px: 0,
              }}
            >
              {cardFields &&
                cardFields.map((cardField, i) => {
                  return (
                    <List
                      disablePadding
                      key={i}
                      sx={{
                        minHeight: '49px',
                      }}
                      className={clsx({
                        conditionalOnRowClick: props.onRowClick ? true : false,
                      })}
                    >
                      <ListItem sx={{ px: 3, minHeight: '48px' }}>
                        {cardField.icon && (
                          <ListItemAvatar
                            sx={{
                              minWidth: 36,
                            }}
                          >
                            <cardField.icon />
                          </ListItemAvatar>
                        )}
                        <ListItemText primary={t(cardField.fieldCaption)} />
                        {cardField.fieldLinkFn || cardField.fieldLinkURL ? (
                          <>
                            {cardField.fieldLinkFn && (
                              <Link
                                component="button"
                                onClick={(event) => {
                                  cardField.fieldLinkFn(props.data);
                                  event.stopPropagation();
                                }}
                                id={
                                  props.primaryKey
                                    ? 'id_button_row_' +
                                      props.data[props.primaryKey] +
                                      '_link'
                                    : 'id_button_row_' + i + '_link'
                                }
                              >
                                <CardFieldContent
                                  data={props.data}
                                  cardField={cardField}
                                ></CardFieldContent>
                              </Link>
                            )}

                            {cardField.fieldLinkURL &&
                            props.data[cardField.fieldName] ? (
                              <Link
                                component="button"
                                sx={{ fontSize: '15px' }}
                                onClick={(event) => {
                                  navigate(getToLink(cardField, props));
                                  event.stopPropagation();
                                }}
                                id={
                                  props.primaryKey
                                    ? 'id_button_row_' +
                                      props.data[props.primaryKey] +
                                      '_link'
                                    : 'id_button_row_' + i + '_link'
                                }
                              >
                                <CardFieldContent
                                  data={props.data}
                                  cardField={cardField}
                                ></CardFieldContent>
                              </Link>
                            ) : (
                              <CardFieldContent
                                data={props.data}
                                cardField={cardField}
                              ></CardFieldContent>
                            )}
                          </>
                        ) : props.size !== 'xlarge' ? (
                          // Truncate the data only if not xlarge
                          getTruncatedName(
                            cardField.fieldDecoratorFn
                              ? cardField.fieldDecoratorFn(
                                  props.data[cardField.fieldName]
                                )
                              : props.data[cardField.fieldName]
                          )
                        ) : cardField.fieldDecoratorFn ? (
                          <Box
                            sx={{
                              maxWidth: ['60%', '80%'],
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {cardField.fieldDecoratorFn(
                              props.data[cardField.fieldName]
                            )}
                          </Box>
                        ) : (
                          props.data[cardField.fieldName]
                        )}
                      </ListItem>
                      {cardFields[i + 1] && (
                        <Divider
                          sx={{
                            mx: 2,
                          }}
                        />
                      )}
                    </List>
                  );
                })}
              {cardFields &&
                props.maxCardFields &&
                props.maxCardFields > cardFields.length &&
                Array(props.maxCardFields - cardFields.length)
                  .fill(1)
                  .map(() => (
                    <List
                      disablePadding
                      key={Math.random()}
                      sx={{
                        height: '49px',
                      }}
                      className={clsx({
                        conditionalOnRowClick: props.onRowClick ? true : false,
                      })}
                    >
                      {/* <ListItem sx={{ py: 1, px: 3 }}>
                          <ListItemText primary="" />
                          &nbsp;
                        </ListItem> */}
                    </List>
                  ))}
              {!cardFields && (
                <List
                  disablePadding
                  sx={{
                    background: `${
                      theme.palette.mode === 'light'
                        ? theme.colors.alpha.white[100]
                        : theme.colors.alpha.black[1]
                    }`,
                  }}
                >
                  {Object.entries(props.data).map(([key, value], i) => {
                    return (
                      <Fragment key={i}>
                        {Array.isArray(value) ? (
                          <>
                            {value.map((v, j) => {
                              return (
                                <Fragment key={j}>
                                  <ListItem sx={{ py: 1, px: 3 }}>
                                    <ListItemText primary={t(key)} />
                                    {getTruncatedName(v)}
                                  </ListItem>
                                  {j + 1 < Object.keys(value).length && (
                                    <Divider
                                      sx={{
                                        mx: 2,
                                      }}
                                    />
                                  )}
                                </Fragment>
                              );
                            })}
                          </>
                        ) : (
                          <ListItem sx={{ py: 1, px: 3 }} key={key}>
                            <ListItemText primary={key} />
                            {getTruncatedName(value)}
                          </ListItem>
                        )}
                        {i + 1 < Object.keys(props.data).length && (
                          <Divider
                            sx={{
                              mx: 2,
                            }}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </List>
              )}
            </Box>
            {props.helpText && (
              <Box py={1} px={{ sm: 0, md: 2 }}>
                <Grid>
                  <Typography
                    sx={{
                      pb: 0,
                      pt: 0,
                    }}
                    variant="subtitle2"
                  >
                    <Alert
                      severity={
                        props.helpTextVariant ? props.helpTextVariant : 'info'
                      }
                    >
                      {props.helpText}
                    </Alert>
                  </Typography>
                </Grid>
              </Box>
            )}
            {(actions || props.moreInfoFn || props.multiSelect) && (
              <>
                <Divider />
                <Grid
                  container
                  onClick={(e) => e.stopPropagation()}
                  alignItems="center"
                  p={1}
                  sx={{
                    background:
                      theme.palette.mode === 'dark'
                        ? `${theme.colors.alpha.black[30]}`
                        : `${theme.colors.alpha.black[5]}`,
                  }}
                >
                  <Grid item>
                    {props.moreInfoFn && (
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={(event) => {
                          props.moreInfoFn(props.data);
                          event.stopPropagation();
                        }}
                        id="id_button_more_info"
                      >
                        {t('Details')}
                      </Button>
                    )}
                    {props.multiSelect && (
                      <Checkbox
                        color="primary"
                        checked={props.selected}
                        onChange={(_, checked) => {
                          props.onRowSelected(
                            props.id - 1,
                            props.data,
                            checked
                          );
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item sx={{ ml: 'auto' }}>
                    {actions && (
                      <Box display="flex" alignItems="center">
                        {actions.map((action, i) => {
                          return (
                            <Tooltip key={i} title={t(action.caption)} arrow>
                              <IconButton
                                onClick={(event) => {
                                  if (!action.disabled) {
                                    if (action.type === 'delete') {
                                      props.deleteHandler(props.data);
                                    } else {
                                      if (
                                        action.changeURL &&
                                        props.onRouteChange
                                      ) {
                                        props.onRouteChange(props.id);
                                      }
                                      action.callbackFn(props.data);
                                    }
                                  }
                                  event.stopPropagation();
                                }}
                                color={action.color}
                                sx={{
                                  ...(action.disabled && {
                                    cursor: 'default',
                                    '&:hover': {
                                      background: 'none',
                                    },
                                    color:
                                      theme.palette.mode === 'light'
                                        ? theme.colors.alpha.black[30]
                                        : theme.colors.alpha.white[30],
                                  }),
                                }}
                                //    disabled={action.disabled}
                                id={
                                  props.primaryKey
                                    ? 'id_button_row_' +
                                      props.data[props.primaryKey] +
                                      '_' +
                                      action.caption
                                    : 'id_button_row_' +
                                      i +
                                      '_' +
                                      action.caption
                                }
                                data-testid={
                                  props.primaryKey
                                    ? 'id_button_row_' +
                                      props.data[props.primaryKey] +
                                      '_' +
                                      action.caption
                                    : 'id_button_row_' +
                                      i +
                                      '_' +
                                      action.caption
                                }
                              >
                                <action.icon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          );
                        })}
                        {props.statusUpdateAction && (
                          <>
                            <Divider
                              sx={{
                                mx: 2,
                              }}
                              orientation="vertical"
                              flexItem
                            />
                            <SwitchSuccess
                              checked={
                                props.statusActionShowOppositeVal
                                  ? !status
                                  : status
                              }
                              onChange={(event) => {
                                onStatusUpdate();
                                event.stopPropagation();
                              }}
                              color="primary"
                              name="status"
                              id="id_switch_row_status"
                            />
                          </>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </CardWrapper>
    </Grid>
  );
};

export default CardKeyValue;
