export const getFirstLetter = (text: string): string => {
  if (text) {
    return text.charAt(0).toUpperCase();
  }
  return '';
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getCompanyName = (): string => {
  // const theme = localStorage.getItem('appTheme') || 'AristaLightTheme';
  //if (theme === 'AristaLightTheme') {
  return 'Arista';
  // }
  // return 'AntaraAI';
};

export interface ReferenceCheckConfig {
  /**
   * type of entity for reference check
   */
  entityType: string;

  /**
   * mapping of reference entities to their launch url
   */
  referenceList: any;

  /**
   * mapping of reference entities that are global (no ID) to their launch url
   */
  globalReferenceList: any;

  /**
   * mapping of reference entities to their icon
   */
  entityIconMap: EntityIconMap;

  /**
   * Avoid sending ID in request payload
   */
  avoidIdInPayload?: boolean;
}

export interface CheckBeforeDeleteConfig {
  /**
   * apiUrl to pass url fo for PreReference check
   */
  apiUrl: string;
  /**
   * req object of PreReference url
   */
  apiReq: any;
  /**
   * call back function take response as argument return boolean
   */
  apiCallBack: (response: any) => boolean;
  /**
   * error text to show in toastr
   */
  apiErrorText: string;
  /**
   * key to be used in request payload to pass the selected id
   */
  entityKey: string;
}
export interface EntityIconMap {
  [key: string]: JSX.Element;
}
