import {
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  styled,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { getFirstLetter } from '../../../utils';
import { CardProps } from '../model';

export interface ImageCardProps extends CardProps {
  imageField: string;
  nameField: string;
  size?: string;
  defaultImage?: string;
}

const CardWrapper = styled(Card)(
  ({ theme }) => `
    border-color: ${
      theme.palette.mode === 'light'
        ? theme.colors.alpha.black[30]
        : theme.colors.borders[1]
    };
    border-style: solid;
    border-width: 1px;
  
    &.conditional:hover {
        border-color: ${theme.colors.primary.light};
        border-style: solid;
        border-width: 1px;
      }
    
    &.conditionalOnRowClick:hover {
       background-color: ${
         theme.palette.mode === 'light'
           ? '#F8F9FA'
           : theme.colors.alpha.black[70]
       };
    }
    `
);

const ImageCard: FC<ImageCardProps> = (props) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const size = props.size === 'large' ? 6 : props.size === 'xlarge' ? 12 : 3;
  return (
    <Grid item xs={12} sm={props.size === 'xlarge' ? 12 : 6} md={4} lg={size}>
      <CardWrapper
        className={clsx({
          conditional: true,
          conditionalOnRowClick: props.onRowClick ? true : false,
        })}
        sx={{
          ...(props.onRowClick ? { cursor: 'pointer' } : { cursor: 'auto' }),
        }}
        onClick={() =>
          props.onRowClick ? props.onRowClick(props.data) : undefined
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {props.data[props.imageField] || props.defaultImage ? (
            <img
              width="100%"
              height="100%"
              style={{
                background: 'white',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                opacity: theme.palette.mode === 'dark' && 0.7,
              }}
              alt={t(props.data[props.nameField])}
              src={props.data[props.imageField] || props.defaultImage}
            />
          ) : (
            <Avatar
              sx={{
                background: (theme: Theme) => theme.palette.primary.light,
                maxWidth: 36,
                maxHeight: 36,
              }}
              variant="rounded"
            >
              {getFirstLetter(props.data[props.nameField])}
            </Avatar>
          )}
        </Box>
        <Divider />
        <Box
          display="flex"
          p={1}
          // justifyContent="right"
        >
          <Typography fontWeight={500}>
            {props.data[props.nameField]}
          </Typography>
          <Box sx={{ ml: 'auto' }}>
            {props.cardActions &&
              props.cardActions.map((action, i) => {
                return (
                  <Fragment key={i}>
                    {((action.cardActionCondition &&
                      action.cardActionCondition(props.data)) ||
                      !action.cardActionCondition) && (
                      <Tooltip key={i} title={t(action.caption)} arrow>
                        <IconButton
                          onClick={(event) => {
                            if (!action.disabled) {
                              if (action.type === 'delete') {
                                props.deleteHandler(props.data);
                              } else {
                                action.callbackFn(props.data);
                              }
                            }
                            event.stopPropagation();
                          }}
                          color={action.color}
                          sx={{
                            padding: '5.25px',
                            ...(action.disabled && {
                              cursor: 'default',
                              '&:hover': {
                                background: 'none',
                              },
                              color:
                                theme.palette.mode === 'light'
                                  ? theme.colors.alpha.black[30]
                                  : theme.colors.alpha.white[30],
                            }),
                          }}
                          id={
                            props.primaryKey
                              ? 'id_button_row_' +
                                props.data[props.primaryKey] +
                                '_' +
                                action.caption
                              : 'id_button_row_' + i + '_' + action.caption
                          }
                        >
                          <action.icon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Fragment>
                );
              })}
          </Box>
        </Box>
      </CardWrapper>
    </Grid>
  );
};

export default ImageCard;
