import { AlertColor, Link } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAuth from '../../hooks/useAuth';
import { getLicenseAlertInfo } from '../../utils/licenseutils';
import Alert from '../Alert';

function TopbarAlert({
  alertText,
  severity,
  closeHandler,
  linkMsg,
  handleLinkClick,
}: {
  alertText?: string;
  severity?: AlertColor;
  closeHandler?: Function;
  linkMsg?: string;
  handleLinkClick?: any;
}) {
  const { t } = useTranslation();
  const licenseInfo = useAuth().user?.licenseInfo;
  const { setTitleHeight } = useContext(GlobalContext);
  const [msg, setMsg] = useState<string>();
  const [severityLevel, setSeverityLevel] = useState<
    'error' | 'warning' | 'info' | 'success'
  >(severity || 'warning');
  const [showMsg, setShowMsg] = useState(false);

  useEffect(() => {
    if (licenseInfo && licenseInfo.type !== 'base') {
      const alertInfo = getLicenseAlertInfo(licenseInfo, t);
      if (alertInfo.msg) {
        setMsg(alertInfo.msg);
        setSeverityLevel(alertInfo.severity);
        setShowMsg(true);
        setTitleHeight(221);
        return;
      }
    }
    if (alertText) {
      setMsg(alertText);
      setShowMsg(true);
      setTitleHeight(221);
    }
  }, [licenseInfo]);

  return (
    <>
      {showMsg && (
        <Alert
          severity={severityLevel}
          sx={{ m: 1 }}
          onClose={() => {
            setShowMsg(false);
            setTitleHeight(170);
            if (closeHandler) {
              closeHandler();
            }
          }}
        >
          {t(msg)}
          {linkMsg && (
            <Trans
              i18nKey={linkMsg}
              components={{
                link1: (
                  <Link
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={handleLinkClick}
                  />
                ),
              }}
            />
          )}
        </Alert>
      )}
    </>
  );
}

export default TopbarAlert;
