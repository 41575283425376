import {
  AAIRadioGroup,
  AAITextField,
  GlobalContext,
  ImportCsv,
  PageAction,
  PageTitle,
  Scrollbar,
  StatusPage,
  isIPV4Valid,
  isIPV6Valid,
  postAPI,
  showNotification,
  uploadFile,
  useAuth,
} from "@antara/shared";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CircularProgress, DialogActions, Divider, FormControlLabel, Radio, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Device } from "src/models/device";
import * as Yup from "yup";
import { DeviceDefaults, csvColumns, sampleData } from "./Config";

const UpsertDevice = () => {
  const { t } = useTranslation();
  const { mobileView, titleHeight } = useContext(GlobalContext);

  const { id, deviceID } = useParams();
  const action = deviceID === "add" ? "add" : "update";
  const [actionType, setActionType] = useState<string>("add");
  const navigate = useNavigate();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [deviceDetails, setDeviceDetails] = useState<Device>(DeviceDefaults);
  const initialValues = deviceDetails;

  const cancelHandler = () => {
    navigate("/" + id + "/administration/devices");
  };

  const pageActions: PageAction[] = [
    {
      caption: "Back",
      icon: ArrowBackIcon,
      callbackHandler: cancelHandler,
      showForReadonlyUser: true,
    },
  ];

  // Import Flow
  const [file, setFile] = useState<any>();
  const [importError, setImportError] = useState<string>();
  const [importErrorMsg, setImportErrorMsg] = useState<string>();
  const [deviceCount, setDeviceCount] = useState<number>();
  const [enableImport, setEnableImport] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  const fileHandler = (errFound: boolean, file: any, count: number) => {
    setEnableImport(!errFound);
    if (!errFound) {
      setFile(file);
      setDeviceCount(count);
    }
  };

  const importHandler = (values, resetForm) => {
    setLoading(true);
    setImportError(undefined);
    setImportErrorMsg(undefined);

    let formData = new FormData();
    formData.append("file", file);

    uploadFile("me.deviceAdmin.device.import", formData, auth.user.orgID, (resp: any) => {
      if (!resp.error || resp.error === "") {
        resetForm({ values, dirty: false });

        const upsertCount = resp.data.insertCount + resp.data.updateCount;
        if (upsertCount === deviceCount) {
          showNotification(enqueueSnackbar, deviceCount + " " + t("device_import_success_msg"), "success");
        } else if (resp.data.ignoreCount === deviceCount) {
          showNotification(enqueueSnackbar, deviceCount + " " + t("device_import_ignore_msg"), "success");
        } else {
          showNotification(enqueueSnackbar, upsertCount + " " + t("device_import_partial_success_msg"), "success");
        }
        setLoading(false);
        cancelHandler();
      } else {
        setImportError(resp.error);

        if (resp.data?.totalCount === resp.data?.errCount) {
          setImportErrorMsg(t("device_import_error_none_imported"));
        } else {
          const diff = resp.data.totalCount - resp.data.errCount;
          setImportErrorMsg(diff + t("device_import_error_some_imported"));
        }
        //showNotification(enqueueSnackbar, t("Import failed"), "error");
        setLoading(false);
      }
    });
  };
  // Add or Update Flow

  const validateForm = Yup.object().shape({
    name: Yup.string().trim().max(255).required(t("err_name_reqd")),
    host: Yup.string()
      .trim()
      .max(255)
      .required(t("err_host_reqd"))
      .test("ip-valid-check", function (value) {
        const { path, createError } = this;
        return (
          isIPV4Valid(value) ||
          isIPV6Valid(value) ||
          createError({
            path,
            message: t("Host must be a valid IP Address"),
          })
        );
      }),
    port: Yup.number()
      .label(t("Port"))
      .integer()
      .typeError(t("err_port_integer"))
      .test("integer_check", function (value) {
        const { path, createError } = this;
        return (
          Number.isInteger(value) ||
          createError({
            path,
            message: t("err_port_integer"),
          })
        );
      }),
  });

  const onPortChange = (port) => {
    if (port && Number.isInteger(parseInt(port))) {
      setDeviceDetails((prevDetails) => ({
        ...prevDetails,
        port: parseInt(port),
      }));
    }
  };

  const handleSubmitFormik = async (values, { setErrors, setStatus, setSubmitting, setFieldTouched }) => {
    // trim whitespaces for some fields
    values["name"] = values["name"].trim();
    values["host"] = values["host"].trim();

    let payload = {
      orgID: auth.user.orgID,
      id: deviceID,
      name: values["name"],
      host: values["host"],
      port: deviceDetails.port,
    };

    // make api call here
    postAPI(action === "add" ? "me.deviceAdmin.device.add" : "me.deviceAdmin.device.update", payload, (resp) => {
      setSubmitting(false);
      if (!resp.error || resp.error === "") {
        setStatus({ success: true });

        let msg = values["name"] + " : ";
        msg += action === "add" ? t("device_add_success") : t("device_update_success");

        showNotification(enqueueSnackbar, msg, "success");

        cancelHandler();
      } else {
        let msg = values["name"] + " : ";
        msg += action === "add" ? t("id_device_add_err_" + resp.error) : t("id_device_update_err_" + resp.error);

        showNotification(enqueueSnackbar, msg, "error");

        setFieldTouched("name", true, true);
        setStatus({ success: true });
        setErrors({ submit: resp.error });
      }
    });
  };

  const getDeviceDetails = useCallback(() => {
    const req = {
      orgID: auth.user.orgID,
      id: deviceID,
      zoneID: 0,
    };

    postAPI("me.deviceAdmin.device.get", req, (resp) => {
      if (!resp.error || resp.error === "") {
        setDeviceDetails(resp.data);
      } else {
        showNotification(enqueueSnackbar, t("id_device_get_err_" + resp.error), "error");
      }
    });
  }, [auth.user.orgID, enqueueSnackbar, deviceID, t]);

  useEffect(() => {
    if (action !== "add") {
      getDeviceDetails();
    }
  }, [getDeviceDetails, action]);

  return (
    <>
      {auth.user.deviceAdministrationAllowed && !mobileView ? (
        <>
          <Helmet>
            <title>{action === "add" ? t("add_device") : auth.user.readOnly ? t("device_details") : t("update_device")}</title>
          </Helmet>
          <PageTitle
            heading={action === "add" ? t("add_device") : auth.user.readOnly ? t("device_details") : t("update_device")}
            icon={AppRegistrationIcon}
            subHeading={
              action === "add"
                ? t("add_device_subheading")
                : auth.user.readOnly
                ? t("device_subheading_readonly")
                : t("update_device_subheading")
            }
            actions={pageActions}
          ></PageTitle>
          <Box sx={{ height: `calc(100vh - ${titleHeight}px)` }}>
            <Scrollbar>
              <Formik initialValues={initialValues} validationSchema={validateForm} onSubmit={handleSubmitFormik} enableReinitialize>
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                  resetForm,
                }): JSX.Element => (
                  <form noValidate onSubmit={handleSubmit}>
                    <Box display="flex">
                      <Grid container direction="row" alignItems="stretch" spacing={1}>
                        <Grid item xs={12} sm={10} lg={8}>
                          <Card
                            sx={{
                              m: 2,
                              mb: 2,
                              pt: 1,
                            }}
                          >
                            {action === "add" && (
                              <AAIRadioGroup
                                label={t("choose_action")}
                                id="id_choose_action"
                                row
                                aria-labelledby="actionType"
                                name="actionType"
                                value={actionType}
                                onChange={(e) => {
                                  setFieldValue("dirty", true);
                                  setActionType(e.target.value);
                                }}
                                sx={{ display: "flex", gap: "20px" }}
                              >
                                <FormControlLabel
                                  id="id_choose_action_option_add"
                                  key={"add"}
                                  value={"add"}
                                  control={<Radio />}
                                  label={t("Add")}
                                />
                                <FormControlLabel
                                  id="id_choose_action_option_import"
                                  key={"import"}
                                  value={"import"}
                                  control={<Radio />}
                                  label={t("Import")}
                                />
                              </AAIRadioGroup>
                            )}

                            {actionType === "add" && (
                              <>
                                <AAITextField
                                  id="id_name_text"
                                  error={Boolean(touched.name && errors.name)}
                                  fullWidth
                                  helperText={touched.name && errors.name}
                                  label={t("Name")}
                                  name="name"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setDeviceDetails((prevDetails) => ({
                                      ...prevDetails,
                                      name: e.target.value,
                                    }));
                                  }}
                                  value={values.name}
                                  variant="outlined"
                                  type="text"
                                />

                                <AAITextField
                                  id="id_host_text"
                                  fullWidth
                                  label={t("Host")}
                                  name="host"
                                  value={values.host}
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setDeviceDetails((prevDetails) => ({
                                      ...prevDetails,
                                      host: e.target.value,
                                    }));
                                  }}
                                  error={Boolean(touched.host && errors.host)}
                                  helperText={touched.host && errors.host}
                                />

                                <AAITextField
                                  sx={{ mb: 1 }}
                                  id="id_port_number"
                                  error={Boolean(touched.port && errors.port)}
                                  fullWidth
                                  helperText={touched.port && errors.port}
                                  label={t("Port")}
                                  name="port"
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    onPortChange(e.target.value);
                                  }}
                                  value={values.port}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  autoComplete="false"
                                />
                              </>
                            )}

                            {actionType === "import" && (
                              <Box sx={{ m: 2 }}>
                                <ImportCsv
                                  columns={csvColumns}
                                  fileImportedCallback={fileHandler}
                                  //reset={resetCsv}
                                  sampleData={sampleData}
                                  resourceName="devices"
                                  fileBrowseCallback={() => {
                                    setImportError(undefined);
                                    setImportErrorMsg(undefined);
                                  }}
                                />
                                {importError && (
                                  <Box sx={{ mt: 2 }}>
                                    <Typography sx={{ mb: 1 }} color={"error"} variant="h5">
                                      {importErrorMsg}
                                    </Typography>
                                    <AAITextField
                                      id="id_clients_import_errors"
                                      fullWidth
                                      name="importError"
                                      value={importError}
                                      variant="outlined"
                                      multiline
                                      minRows={10}
                                      readonly
                                      compact
                                    />
                                  </Box>
                                )}
                              </Box>
                            )}

                            {!auth.user.readOnly && (
                              <>
                                <Divider />
                                <DialogActions sx={{ mr: 2, my: 0 }}>
                                  {actionType === "import" ? (
                                    <>
                                      <Button id="id_button_cancel" color="secondary" onClick={cancelHandler} disabled={loading}>
                                        {t("Cancel")}
                                      </Button>
                                      <LoadingButton
                                        id="id_button_import"
                                        loading={loading}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => importHandler(values, resetForm)}
                                        disabled={!enableImport} // !values["clientGroupID"] ||
                                      >
                                        {t("Import")}
                                      </LoadingButton>
                                    </>
                                  ) : (
                                    <>
                                      <Button id="id_button_cancel" color="secondary" onClick={cancelHandler}>
                                        {t("Cancel")}
                                      </Button>
                                      <Button
                                        id="id_button_add"
                                        type="submit"
                                        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                                        disabled={Boolean(errors.submit) || isSubmitting}
                                        variant="contained"
                                      >
                                        {action === "add" ? t("Add") : t("Update")}
                                      </Button>
                                    </>
                                  )}
                                </DialogActions>
                              </>
                            )}
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                )}
              </Formik>
            </Scrollbar>
          </Box>
        </>
      ) : (
        <StatusPage status="403" hideNav />
      )}
    </>
  );
};

export default UpsertDevice;
