export const TRANSLATIONS_EN = {
  onboard: "Self-Service Portal",
  Navigation: "Navigation",
  Languages: "Languages",
  signout: "Sign Out",
  copy_org_id: "Copy Organization ID",
  Browse: "Browse",
  Confirm: "Confirm",
  Scan: "Scan",
  table_view: "Table View",
  card_view: "Card View",

  // Errors
  invalid_input_clientMAC: "Enter the correct Client MAC address.",
  onboard_not_allowed_for_user_group: "You are not authorized to onboard.",
  login_captive_portal_not_allowed_on_network: "User is not allowed on this network",
  captive_portal_not_allowed_on_network: "User is not allowed on this network",
  user_domain_does_not_match_org_domain: "The user domain does not match the organization's domain",
  client_quarantined: "This device is quarantined due to non-compliance with the organization's policy",
  invalid_jwt_claim_attr_value: "Another user has logged in from this browser.",
  invalid_input_idToken: "SSO login failed.",
  app_not_ready: "The application is not ready. Retry after some time.",

  // Success
  login_success: "You have successfully logged in!",

  // Signin
  signin: "Sign In",
  signup: "Sign Up",
  userID_or_email: "UserID or Email",
  Name: "Name",
  Email: "Email",
  Password: "Password",
  update_password: "Update Password",
  old_password: "Old Password",
  new_password: "New Password",
  userID_email_reqd: "UserID or Email is required",
  pass_reqd: "Password is required",
  pass_min_err: "Password must be at least 8 characters",
  pass_max_err: "Password cannot have more than 150 characters",
  email_valid_err: "Provide a valid email address",
  email_reqd: "Email is required",
  new_pass_min_err: "The new password must be at least 8 characters",
  new_pass_max_err: "The new password cannot have more than 150 characters.",
  pass_change_success: "Password changed successfully",
  change_pass: "Change Password",
  old_pass_reqd: "Old password is required",
  new_pass_reqd: "New password is required",
  login_user_password_mismatch: "Invalid user or password",
  login_user_not_found_idp_lookup_failed: "Invalid user or password",
  login_user_not_found_login_name_is_not_email: "Invalid user or password",
  login_internal_error: "Login failed, retry.",
  login_user_not_enabled: "You are not allowed to signin. Contact your administrator for further information.",
  contact_admin: "Contact your administrator for details.",
  user_signup_failed_internal_error: "User sign-in failed, retry.",
  user_signup_failed_user_already_exists: "User Sign-in failed; the user already exists.",
  login_user_not_found_internal_error: "Login failed, invalid user or password. Retry.",
  login_user_not_found_invalid_user_name_or_password: "Login failed, invalid user or password.",
  login_invalid_user_name_or_password: "Login failed, invalid user or password.",
  login_undefined: "Login failed, invalid user or password.",
  password_reset_internal_error: "Password reset failed; retry.",
  network_fetch_internal_error: "Fetching Network failed; retry.",
  network_fetch_network_not_found: "Network not found.",
  network_fetch_object_not_found: "Fetching network failed; retry.",
  network_fetch_onboard_not_allowed_on_network: "Onboarding of users is not permitted on this network.",
  network_fetch_invalid_prelogin_session: "Invalid prelogin session.",
  password_reset_user_new_password_cannot_be_same_as_old_password: "New password cannot be same as old password.",
  password_reset_user_incorrect_old_password: "Incorrect old password,",
  terms_of_use_prefix: "By signing in, you accept the",
  terms_of_use: "Terms of Use",
  license_expired_msg: "Access to this portal is not available currently.",

  // Register
  register_client: "Register Client",
  register_device: "Register Device",
  mac_address: "MAC Address",
  Description: "Description",
  Register: "Register",
  Passphrase: "Passphrase",
  Copy: "Copy",
  Copied: "Copied",
  Continue: "Continue",
  Download: "Download",
  launch_app: "Launch App",
  network_ssid: "Network SSID",
  Proceed: "Proceed",
  Done: "Done",
  register_step1_header: "Provide the following details to register your client device.",
  register_step2_header: "Your client is registered with the following passphrase. Configure your client with this passphrase.",
  register_step3_header: "Follow the steps to connect your client device",
  register_step3_help1: "1. Click the <strong>Proceed</strong> button. You will be disconnected from the network.",
  register_step3_help2: "2. Click <strong>{{ssid}}</strong> in Wi-Fi networks list to connect again.",
  register_step3_help3: "3. When prompted, enter the new passphrase you copied.",
  register_step3_help4: "4. Alternatively, you can also forget or delete <strong>{{ssid}}</strong> from Wi-Fi networks list first.",
  register_step3_help5: "5. After deleting, reconnect to <strong>{{ssid}}</strong> with the new passphrase.",
  register_email_text: "You will receive an email with the new passphrase.",
  qr_code_title: "Scan this code to connect your clients",
  register_step2_cert_header: "Your client is registered. To connect your client:",
  register_step2_cert_help1: "1. Click the <strong>Download</strong> button to proceed.",
  register_step2_cert_help2: "2. A network profile is downloaded to your device.",
  register_step2_cert_help3: "3. Go to the <strong>Settings</strong> application.",
  register_step2_cert_help4: "4. Click <strong>Profile Downloaded</strong>",
  register_step2_cert_help5: "5. Click <strong>Install</strong> to install the downloaded profile.",
  register_step2_cert_help6: "6. Now you can connect to the secure network.",
  register_step2_cert_help1_android: "1. Click <strong>Launch App</strong> button to proceed.",
  register_step2_cert_help2_android: "2. If prompted, install Android application and launch it.",
  register_step2_cert_help3_android: "3. Follow the instructions in the application to connect.",
  register_step2_cert_help1_windows: "1. Click the <strong>Download</strong> button to proceed.",
  register_step2_cert_help2_windows: "2. An executable file will be downloaded to your system.",
  register_step2_cert_help3_windows: "3. Double-click the file to launch the AGNI Onboard app.",
  register_step2_cert_help4_windows: "4. This app configures the device to connect to a secure network.",
  register_step2_cert_help5_windows: "5. When prompted, click <strong>Yes</strong> to install the certificates.",
  register_step2_cert_help6_windows: "6. Now you can connect to the secure network.",
  register_step2_cert_help1_linux: "1. Click the <strong>Download</strong> button to proceed.",
  register_step2_cert_help2_linux: "2. An executable script file will be downloaded to your system.",
  register_step2_cert_help3_linux: "3. Run the script to configure the device and connect to the secure network.",
  register_step2_cert_help4_linux: "4. This app will configure the device to connect to the secure network.",
  register_step2_cert_help1_chromeos: "1. Install <link1><strong>CloudVision AGNI</strong></link1> on your Chromebook.",
  register_step2_cert_help2_chromeos: "2. After app is installated, Go to the bottom right, select the time.",
  register_step2_cert_help3_chromeos: "3. Select Settings ⚙ ",
  register_step2_cert_help4_chromeos:
    "4. In the <strong>“Apps”</strong> section, Select <strong>Manage your apps</strong> > CloudVision AGNI",
  register_step2_cert_help5_chromeos:
    "5. Under <strong>Opening Supported Links </strong>, Select <strong>Open in CloudVision AGNI</strong>.",
  register_step2_cert_help6_chromeos: "6. Click <strong>Launch App</strong> button to proceed.",

  client_owner_mismatch: "Client already registered.",
  client_onboard_err_empty_org_id: "Onboarding failed, organization does not exist.",
  client_onboard_err_empty_client_mac: "Enter client MAC address.",
  client_onboard_err_empty_email: "Enter email address.",
  client_onboard_err_oneof_userid_or_email_required: "Enter email address.",
  client_onboard_err_user_does_not_exist: "Onboarding failed; user does not exist.",
  client_onboard_err_duplicate_client: "Duplicate client.",
  client_onboard_err_user_client_mismatch: "Onboarding failed; client does not belong to user.",
  client_onboard_err_internal_error: "Onboarding failed; retry.",
  client_onboard_err_invalid_input_mac: "Enter MAC address.",
  client_onboard_err_invalid_input_orgID: "Customer account does not exist.",
  client_onboard_err_upsk_max_personal_clients_exceeded: "You have exceeded the maximum number of allowed UPSK personal clients.",
  client_onboard_err_upsk_max_personal_clients_reached: "You have reached the maximum number of allowed UPSK personal clients.",
  client_onboard_err_eap_max_personal_clients_exceeded: "You have exceeded the maximum number of allowed EAP-TLS clients.",
  client_onboard_err_eap_max_personal_clients_reached: "You have reached the maximum number of allowed EAP-TLS clients.",
  client_onboard_err_invalid_input_networkID: "Add failed; at least one UPSK network with Onboarding enabled must be configured.",
  client_disconnect_err_empty_org_id: "Disconnect failed, the organization does not exist.",
  client_disconnect_err_empty_client_id: "Disconnect failed, the client does not exist.",
  client_disconnect_err_empty_user_id: "Disconnect failed, the user does not exist.",
  client_disconnect_err_user_client_mismatch: "Disconnect failed; the client does not belong to the user.",
  client_disconnect_err_internal_error: "Disconnect failed; retry.",
  client_onboard_err_object_already_exists: "Client already exists.",
  client_onboard_success: "Client added successfully.",

  // clients
  manage_clients: "Manage Clients",
  manage_clients_desc: "Manage the list of clients",
  client_delete_success: "Client deleted successfully",
  update_client: "Update Client",
  Status: "Status",
  Update: "Update",
  Clients: "Clients",
  Count: "Count",
  Enabled: "Enabled",
  Disabled: "Disabled",

  client_get_err: "Error fetching client details",
  client_desc_update: "Provide the following details to update the selected client.",
  client_delete_err_internal_error: "Delete failed; retry.",
  client_delete_err_empty_user_id: "Delete failed; the user does not exist.",
  client_delete_err_client_does_not_exist: "Delete failed; client does not exist.",
  client_delete_err_client_id: "Delete failed; client does not exist.",
  client_delete_err_empty_org_id: "Delete failed; organization does not exist.",
  client_list_err_empty_org_id: "Fetching client details failed; the organization does not exist.",
  client_list_err_empty_user_id: "Fetching client details failed; the user does not exist.",
  client_list_err_client_does_not_exist: "Fetching client details failed; the client does not exist.",
  client_list_err_internal_error: "Fetching client details failed; retry.",
  client_update_err_empty_org_id: "The update failed; the organization does not exist.",
  client_update_err_empty_client_id: "The update failed; the client does not exist.",
  client_update_err_invalid_client_status: "Update failed; client status is invalid.",
  client_update_err_empty_user_id: "The update failed; the user does not exist.",
  client_update_err_internal_error: "Update client failed; retry.",
  client_update_err_client_does_not_exist: "The update failed; and the client does not exist.",
  client_update_err_invalid_input_orgID: "Customer account does not exist.",
  client_update_err_invalid_input_clientID: "Update failed, client does not exist.",
  client_update_err_invalid_input_status: "Update failed; the client status is invalid.",
  client_err_description_reqd: "Enter the description.",
  clients_delete_msg_mac: "Are you sure you want to delete Client with MAC address '{{entity}}'?",
  clients_delete_msg_no_mac: "Are you sure you want to delete Client with description '{{entity}}'?",
  clients_search_desc: "Search by MAC address or description...",
  status: "Status",
  Delete: "Delete",
  owner_user: "Owner (User)",
  update_time: "Update Time",
  client_update_success: "Client updated successfully",
  client_passphrase_expired: "Your personal passphrase has expired. Go <link1>here</link1> to regenerate.",

  // view passphrase
  clients_link: "Click <link1>here</link1> to manage clients.",
  passphrase_view_subtitle: "This is your personal passphrase. Use this passphrase to connect your clients to this network.",
  personal_passphrase: "Personal Passphrase",
  wireless_network: "Wireless Network",

  // passphrase
  wifi_passphrase: "Wi-Fi Passphrase",
  view_wifi_passphrase: "View Wi-Fi passphrase",
  client_group: "Client Group",
  passphrase_updated: "The passphrase is updated.",
  passphrase_subtitle: "This is your passphrase. Click Regenerate to create a new passphrase if needed.",
  passphrase_info: "The new passphrase is generated. Click Update to use this passphrase for any future registrations.",
  group_passphrase_subtitle: "This passphrase is for all clients in the selected Client Group.",
  Regenerate: "Regenerate",
  Cancel: "Cancel",
  passphrase_err_empty_org_id: "Generating passphrase failed; the organization does not exist.",
  passphrase_err_empty_email: "Generating passphrase failed; empty email address.",
  passphrase_err_empty_user_id: "Generating passphrase failed; user does not exist.",
  passphrase_err_empty_psk_passphrase: "Update failed; empty passphrase",
  passphrase_err_user_does_not_exist: "Update failed; user does not exist.",
  passphrase_err_internal_error: "Update failed; retry.",
  passphrase_err_invalid_input_pskPassphrase: "Update failed; empty passphrase",
  passphrase_err_invalid_input_orgID: "The customer account does not exist.",
  passphrase_generate_err_empty_user_id: "Generating passphrase failed; the user does not exist.",
  passphrase_generate_err_internal_error: "Generating a new passphrase failed; retry.",
  passphrase_qr_code_title: "Scan this code to connect your clients",
  user_network_err_internal_error: "Fetching network list failed.",
  passphrase_expired_only: "Your passphrase has expired.",
  passphrase_expired: "Your passphrase has expired. Click Regenerate to create a new passphrase.",
  passphrase_expire_on_1: "Your passphrase expires on ",
  passphrase_expire_on_2: "Click on Regenerate to create a new passphrase.",
  passphrase_expired_regenerate: "Go <link1>here</link1> to regenerate.",
  passphrase_expired_on_1: "Your passphrase expired on ",
  passphrase_expired_on_2: "Click Regenerate to create a new passphrase.",

  // add client
  register_cliend_desc: "Register a new client for network access",
  allowed_wireless_networks: "Allowed Wireless Networks",
  scan_barcode: "Scan Barcode",
  not_available: "Not Available",
  error_scan_laptopview: "Error scanning file: Upload a valid and clear image with just the bar code.",
  error_scan_mobileview: "Error scanning file: Zoom and capture an image with just the bar code.",
  alert_info_scan_mobileview: "Zoom in and capture an image with just the bar code.",
  alert_info_scan_laptopview: "Choose the bar code image file",
  error_invalid_image: "Upload a valid image file in jpg | jpeg | png | gif format.",
  register_subtitle_success:
    "For wireless access, your client is registered using the following passphrase: Configure your client with this passphrase.",
  wired_register_subtitle_success: "Your client is registered for wired access.",
  register_wired_msg: "This client can also connect to wired networks.",
  register_wired_only_msg: "This client can connect to wired networks only.",
  register_new_client: "Provide the following details to register a new client",
  mac_reqd: "Enter MAC address",
  mac_invalid: "Invalid MAC address",
  mac_placeholder: "Example: 00:2a:5e:00:53:af",
  description_reqd: "Enter the description.",
  view_clients: "View Clients",
  client_onboard_err_user_cannot_manage_client_group: "This user cannot manage client group.",
  client_onboard_err_client_group_not_allowed_to_access_network: "This client group is not allowed to access the selected network.",
  clientgroup_network_err_user_cannot_manage_client_group: "This user cannot manage client group.",
  clientgroup_network_err_internal_error: "Fetching network list failed.",
  clientgroup_list_err_internal_error: "Fetching client groups failed.",

  // signout
  signout_success: "You have successfully signed out.",
  signin_again: "Click <link1>here</link1> to sign in again.",

  internal_error: "Unable to serve the request, Try again later.",

  // Device Administration
  // Device Administration -> Devices
  device_admin: "Device Administration",
  Devices: "Devices",
  Setup: "Setup",
  apple_mac: "Apple MacBook",
  msft_win: "Microsoft Windows",
  devices_subheading: "Manage the list of devices",
  Add: "Add",
  Back: "Back",
  Connect: "Connect",
  Host: "Host",
  Port: "Port",
  id_devices_search_desc: "Search by name or host...",
  id_devices_delete_msg: "Are you sure you want to delete '{{entity}}' device ?",
  update_device: "Update Device",
  add_device: "Add Device",
  connect_device: "Connect Device",
  connect_device_ssh: "Connect Device to SSH Session",
  add_device_subheading: "Provide the following details to add a new device.",
  update_device_subheading: "Provide the following details to update the selected device.",
  max_row_select: "You can delete up to {{count}} rows at a time.",
  delete_all: "Delete All",
  bulk_actions: "Bulk Actions",
  id_devices_clients_bulk_delete_msg: "Are you sure you want to delete the selected devices?",
  id_devices_bulk_delete_msg_other: "Are you sure you want to delete {{count}} devices?",
  id_devices_bulk_delete_all_msg: "There is only one device. Are you sure you want to delete it?",
  id_devices_bulk_delete_all_msg_other: "There are {{count}} devices. Are you sure you want to delete all of them?",
  id_devices_bulk_delete_filtered_msg: "One device matches the current filter applied. Are you sure you want to delete it?",
  id_devices_bulk_delete_filtered_msg_other:
    "{{count}} devices match the current filter applied. Are you sure you want to delete all of them?",
  id_devices_bulk_delete_success_msg: "One device is deleted successfully",
  id_devices_bulk_delete_success_msg_other: "{{count}} devices are deleted successfully. Are you sure you want to delete this device?",
  id_devices_bulk_delete_msg: "Are you sure want to delete this device?",
  id_devices_bulk_delete_err: "Unable to delete the selected client",
  id_devices_bulk_delete_err_other: "Unable to delete {{errCount}} out of {{count}} clients",
  id_devices_bulk_delete_err_first_ten: "{{count}} clients are not deleted as they are active. Displaying the first 10 below:",
  id_devices_bulk_delete_internal_err: "{{count}} clients are not deleted due to an internal error.",

  // bulk
  id_devices_bulk_selected_filtered_msg: "One device that match current filter is selected.",
  id_devices_bulk_selected_filtered_msg_other: "{{count}} devices that match current filter are selected.",
  id_devices_bulk_selected_all_filtered_msg: "All {{count}} devices that match current filter are selected.",
  id_devices_bulk_selected_all_msg: "All {{count}} devices are selected.",
  id_devices_bulk_selected_msg: "One device is selected.",
  id_devices_bulk_selected_msg_other: "{{count}} devices are selected.",
  id_devices_bulk_select_match_current_filter: "Select all {{totalCount}} devices that match the current filter.",
  id_devices_bulk_select_all: "Select all {{totalCount}} devices.",
  id_devices_delete_count_err_max_limit: "A maximum of {{count}} devices can be deleted at a time.",
  id_devices_count_err_internal_error: "Unable to fetch device count, retry",

  //csv import
  add_or_import: "Add or Import",
  Import: "Import",
  reimport_csv_msg: "Reimport the file after fixing the errors.",
  csv_error_msg: "There are errors in the uploaded file. Reimport the file after fixing.",
  csv_error_many: "There are errors in the uploaded file, displaying the first ten errors:",
  csv_error_guest_admin_not_operator: "Guest added by another Sponsor.",
  client_import_success_msg: "Client(s) imported successfully.",
  device_import_success_msg: "Device(s) imported successfully.",
  device_import_ignore_msg: "Device(s) ignored. No changes.",
  device_import_partial_success_msg: "Device(s) are partially imported. Rest are ignored.",
  device_import_error_none_imported: "No devices imported. There are errors during import.",
  device_import_error_some_imported: " devices imported. Some devices have errors during import.",
  csv_entry_duplicate_error: "Line {{index}}: '{{val}}' is repeated as {{displayName}}.",
  Sample: "Sample",
  Columns: "Columns",
  csv_entry_missing_error: "Line {{index}}: {{displayName}} missing.",
  csv_entry_invalid_error: "Line {{index}}: '{{val}}' is invalid as {{displayName}}.",
  csv_extra_data_error: "Line {{index}}: Extra column.",
  import_max_row_exceeded: "A maximum of {{limit}} records can be imported per CSV file.",
  upload_csv_file: "Upload CSV file",
  choose_action: "Choose Action",

  // Device Administration -> Upsert Device
  err_name_reqd: "Name is required",
  err_host_reqd: "Host is required",
  err_port_reqd: "Port is required",
  err_port_integer: "The port must be an integer.",
  device_add_success: "The device was added successfully.",
  device_update_success: "The device was updated successfully.",
  id_device_add_err_internal_error: "Add device failed; retry.",
  id_device_update_err_internal_error: "Update device failed; retry.",
  id_device_get_err_internal_error: "Delete failed; retry.",
  id_device_add_err_object_already_exists: "A device with the same name already exists.",
  id_device_err_app_unavailable: "Connect failed. DeviceAdmin app is not running",

  // Setup
  setup_subheading: "Setup the DeviceAdmin application to SSH to your devices.",
  device_os: "Your Client Device OS",
  setup_desc: "Follow the given steps to install the DeviceAdmin application:",
  setup_step_1: "1. Download the DeviceAdmin application for your client.",
  setup_step_2: "2. Install the application and allow it to run in the background.",
  setup_step_3: "3. When prompted, give permissions for the application to accept incoming connections.",
  setup_step_4: "4. In the Self-Service Portal, add the Access Device in the Devices UI.",
  setup_step_5: "5. Click the 'Connect' icon for the device to launch the SSH session.",
  ssh_title: "Device Login Credentials",
  ssh_title_subtitle: "View device login credentials ",
  ssh_user_name: "Login Username",
  ssh_helper_text: "Use device login credentials in external terminals to access devices securely.",
  ssh_password: "Login Password",
  ssh_password_helper_text: "This is a temporary password that will expire once this web session expires.",
  ssh_menu_item: "Login Credentials",

  // SSH Passphrase section
  user_onboard_to_network_check_failed: "This user is not allowed to onboard to this network.",
  ssh_token_generate_success: "Device Login Password generated successfully",
  ssh_token_generate_error: "Failed to generate Device Login Password",
  updating_token: "Regenerate device login password",
  ssh_generate_token_message: "Click to generate a device login password.",
  ssh_regenerate_token_message: "Click to regenerate a new device login password.",
  Generate: "Generate",
  ssh_token_one_time_message: " Copy and Save the generated password; it will not be displayed later.",
  update_token_message_part1: "You have a valid device login password that expires at ",
  update_token_message_part2:
    " A new passphrase will overwrite the previous device login password. Are you sure you want to regenerate a new device login password?",
  ssh_token_exist: "Your device login password will expire on ",
  if_required: "Regenerate if required.",
  ssh_token_expired: "Your device login password has expired.",

  // OTP based login
  verify_otp: "Verify one-time password",
  otp_login: "Use one-time password",
  sso_login: "SSO Login",
  enter_otp: "Enter one-time password",
  OTP: "One-time password",
  resend_otp_success: "One-time password has been resent.",
  otp_send_error: "Unable to generate the one-time password; retry.",
  resend_otp: "Resend one-time password",
  submit_btn: "Submit",
  login_invalid_otp: "Invalid one-time password",
  resend_otp_seconds: "Resend one-time password in few seconds",
  otp_sent_info: "Check your email for one-time password.",

  // Guests
  guests: "Guests",
  guests_users: "Users",
  all_users: "All users",
  guest_operator: "Guest Operator",
  guest_sponsor: "Guest Sponsor",
  // Config -> Guests
  id_guests_users: "Guest Users",
  id_guests_update: "Update Guest User",
  id_guests_desc: "Manage the list of Guest Users",
  id_guests_search_desc: "Search by Username, Email, Approver, Name or Company ...",
  id_guests_add_import: "Add Guest or Import Guests",
  id_guests_add_import_short: "Add or Import ",
  // Config -> Guests -> List
  id_guests_onboard_list_err_internal_error: "Unable to fetch the list of guests, retry.",
  id_guests_onboard_list_err_invalid_input_orgID: "Customer account does not exist.",
  // Config -> Guests -> Delete
  id_guests_onboard_delete_msg: "Are you sure you want to delete Guest with username '{{entity}}'?",
  id_guests_onboard_delete_err_invalid_input_orgID: "Customer account does not exist.",
  id_guests_onboard_delete_err_invalid_input_userID: "Selected guest does not exist",
  id_guests_onboard_delete_err_invalid_input_loginName: "Selected guest does not exist",
  id_guests_onboard_delete_err_internal_error: "Delete failed; retry",
  id_guests_onboard_bulk_delete_msg: "Are you sure you want to delete selected Guest?",
  id_guests_onboard_bulk_delete_msg_other: "Are you sure you want to delete {{count}} Guests?",
  id_guests_onboard_bulk_delete_all_msg: "There is one Guest. Are you sure you want to delete it?",
  id_guests_onboard_bulk_delete_all_msg_other: "There are {{count}} Guests. Are you sure you want to delete all of them?",
  id_guests_onboard_bulk_delete_filtered_msg: "One Guest matches the current filter applied. Are you sure you want to delete it?",
  id_guests_onboard_bulk_delete_filtered_msg_other:
    "{{count}} Guests match the current filter applied. Are you sure you want to delete all of them?",
  id_guests_onboard_bulk_delete_success_msg: "One Guest is deleted successfully",
  id_guests_onboard_bulk_delete_success_msg_other: "{{count}} Guests are deleted successfully",
  id_guests_onboard_bulk_delete_err: "Unable to delete the selected Guest",
  id_guests_onboard_bulk_delete_err_other: "Unable to delete {{errCount}} out of {{count}} Guests",
  id_guests_onboard_bulk_delete_err_first_ten: "{{count}} Guests are not deleted as they are active. Displaying first 10 below:",
  id_guests_onboard_bulk_delete_internal_err: "{{count}} Guests are not deleted due to an internal error.",

  // Config -> Guests -> Upsert
  id_guests_add_or_import_guests: "Add or Import Guests",
  id_guests_upsert_desc_add: "Provide the following details to add a new guest user or upload a file to import guest users.",
  id_guests_err_username_reqd: "Username is required.",
  id_guests_err_email_reqd: "Email is required.",
  id_guests_err_email_invalid: "Provide a valid email.",
  id_guests_device_limit_err_reqd: "Device limit is required",
  id_guests_get_err_internal_error: "Unable to fetch guest user, retry.",
  id_guests_portal_list_err_internal_error: "Unable to fetch guest portals.",
  id_guests_upsert_action_add: "Add",
  id_guests_upsert_action_update: "Update",
  id_guests_no_users_import_success_msg: "No guest users were imported",
  id_guests_import_success_msg: "Guest user(s) imported successfully.",
  id_guests_import_error_none_imported: "No guest users imported. There are errors during import.",
  id_guests_import_error_some_imported: " Guest users imported. Some guest users have errors during import.",
  id_guests_user_update_success: "Guest user updated successfully",
  id_guests_user_add_success: "Guest user added successfully",
  id_guests_upsert_action_add_email: "Add and Email",
  id_guests_upsert_action_update_email: "Update and Email",
  id_guests_import_action_email: "Import and Email",
  id_guests_import_action: "Import",

  // bulk
  id_guests_onboard_bulk_selected_filtered_msg: "One Guest that match current filter is selected.",
  id_guests_onboard_bulk_selected_filtered_msg_other: "{{count}} Guests that match current filter are selected.",
  id_guests_onboard_bulk_selected_all_filtered_msg: "All {{count}} Guests that match current filter are selected.",
  id_guests_onboard_bulk_selected_all_msg: "All {{count}} Guests are selected.",
  id_guests_onboard_bulk_selected_msg: "One Guest is selected.",
  id_guests_onboard_bulk_selected_msg_other: "{{count}} Guests are selected.",
  id_guests_onboard_bulk_select_match_current_filter: "Select all {{totalCount}} Guests that match the current filter.",
  id_guests_onboard_bulk_select_all: "Select all {{totalCount}} Guests.",
  id_guests_onboard_delete_count_err_max_limit: "A maximum of {{count}} guest users can be deleted anytime.",

  // Add
  id_guests_valid_from: "Valid From",
  id_guests_valid_to: "Valid To",
  id_guests_device_limit: "Device Limit",
  id_guests_add_err_object_already_exists: "A guest user with the same username/email already exists.",
  id_guests_add_err_internal_error: "Add failed; retry",
  id_guests_add_err_invalid_input_loginName: "Enter a valid username.",
  id_guests_add_err_invalid_input_email: "Enter a valid email.",
  id_guests_add_err_invalid_input_orgID: "The customer account does not exist.",
  id_guests_add_err_invalid_input_password: "Enter a valid password.",
  id_guests_add_err_invalid_input_zoneID: "The zone does not exist.",
  // Update
  id_guests_update_err_internal_error: "Update failed; retry",
  id_guests_details: "Details of selected guest",
  id_guests_update_err_object_already_exists: "A guest user with the same username/email already exists.",
  id_guests_update_err_invalid_input_loginName: "Enter a valid username.",
  id_guests_update_err_invalid_invalid_input_email: "Enter a valid email.",
  id_guests_update_err_invalid_input_orgID: "The customer account does not exist.",
  id_guests_update_err_invalid_input_password: "Enter a valid password.",
  id_guests_update_err_guest_user_does_not_exist: "Guest user does not exist",
  id_guests_user_clients: "Guest User Clients",
  id_guests_upsert_desc_update: "View guest user details and update the selected guest user",
  id_guests_activation_date: "Activation Date",
  id_guests_expiration_date: "Expiration Date",
  id_guests_sponsor: "Approver",
  id_guests_validity: "Validity",
  id_guests_created_at: "Created At",
  id_guests_valid_until: "Valid until",
  id_guests_devices: "Devices",
  id_guests_device: "Device",
  id_guests_portal_helptext: "Changing the portal updates the validity and device limit.",
  id_guests_invalid_date: "Invalid Date",
  id_guests_past_date_error: "Date must not be in past",
  id_guests_validTo_lt_validFrom: "Valid To should be greater than Valid From",
  id_guests_validity_error_lt_1hr: "Validity should be greater than 1 hour",
  id_guests_validity_error_gt_1yr: "Validity should be less than 1 year",
  id_guests_validFrom_gt_validTo: "Valid From should be greater than Valid To",
  id_guests_validity_error_gt_48hrs: "Validity cannot be more than 48 hours",
  translation_days: "Days",
  translation_day: "Day",
  translation_hours: "Hours",
  translation_hour: "Hour",
  translation_minutes: "Minutes",
  translation_minute: "Minute",
  id_guests_additional_guest_information: "Additional guest user information",
  id_guests_username: "Username",
  id_guests_password: "Password",
  id_guests_email: "Email",
  id_guests_name: "Name",
  id_guests_status: "Status",
  id_guests_company: "Company",
  id_guests_phone: "Phone #",
  id_guests_address: "Address",
  id_guests_notes: "Notes",
  id_guests_name_max_len_error: "The name should have at most 150 characters.",
  id_guests_company_max_len_error: "The company name should have at most 150 characters.",
  id_guests_phone_max_len_error: "Phone should have at most 15 digits.",
  id_guests_address_max_len_error: "The address should have at most 300 characters.",
  id_guests_notes_max_len_error: "Notes should have at most 150 characters.",
  id_guests_action_add: "Add",
  id_guests_action_import: "Import",
  id_guests_portal: "Portal",
  id_guests_passphrase: "Passphrase",
  id_guests_not_applicable: "No Limit",
  portal: "Portal",
  show_clients: "Show Clients",
  hide_clients: "Hide Clients",
  id_guests_portal_not_configured: "Guest portal with Guestbook is not configured",
  id_guests_action_add_portal_user: "Add portal user",
  id_guests_action_add_upsk_user: "Add UPSK user",
  export_all: "Export All",
  id_guest_user_clients_search_desc: "Search by MAC address...",
  id_guests_guestUPSK_network_upsk_guest_not_found: "The guest UPSK network is not configured.",
  id_guests_onboard_bulk_export_success_msg: "Guest users exported successfully",
  id_guests_onboard_bulk_export_error_internal_error: "Unable to export guest users; retry.",
  id_guests_passphrase_qr_code_title: "Network QR code for this user",
  id_guests_filter_user_type: "Type",
  id_guests_onboard_count_err_internal_error: "Failed to fetch guest users count",
  id_guests_users_delete_msg: "Are you sure you want to delete Guest User '{{entity}}'?",
  id_guests_upsk_user_msg: "This is a UPSK based guest user.",
  id_guests_user_type: "Type",
  id_guests_portal_user: "Portal",
  id_guests_upsk_user: "UPSK",
  id_guest_user_client_list_delete_msg: "Are you sure you want to delete the Guest Client with the MAC address: {{entity}}",

  captive_portal_not_allowed: "The user is not allowed on this network.",
  collapse_sidebar: "Collapse Sidebar",
  leave_page: "Unsaved Changes",
  unsaved_warning_msg: "Your changes may not be saved. Are you sure you want to leave?",
  stay: "Stay",
  leave: "Leave",
  group_upsk_disabled: "Group UPSK is disabled for the selected Client Group.",
  show_details: "Show Details",
  passphrase_subtitle_readonly: "This is your personal passphrase.",

  client_desc_readonly: "Details of selected client",
  device_details: "Device Details",
  device_subheading_readonly: "Details of selected device",
  ssh_generate_token_readonly_message: "Device login password cannot be generated for read-only user.",
  register_new_client_readonly: "New client cannot be added for read-only user.",
  client_update_err_user_self_service_portal_write_not_allowed: "Client cannot be updated for read-only user.",
  client_delete_err_user_self_service_portal_write_not_allowed: "Client cannot be deleted for read-only user.",
  passphrase_generate_err_user_self_service_portal_write_not_allowed: "Passphrase cannot be generated for read-only user.",
  client_onboard_err_user_self_service_portal_write_not_allowed: "New client cannot be registered for read-only user.",
  user_info_error_internal_error: "Unable to fetch user, retry.",
  id_guests_onboard_list_err_guest_admin_not_allowed: "You don't have permission to access this page.",
  id_guests_get_err_guest_admin_not_allowed: "You don't have permission to access this page.",
  id_guests_add_err_guest_admin_not_allowed: "You don't have permission to access this page.",
  id_guests_update_err_guest_admin_not_allowed: "You don't have permission to access this page.",
  id_devices_list_err_device_admin_not_allowed: "You don't have permission to access this page.",
  id_device_get_err_device_admin_not_allowed: "You don't have permission to access this page.",
  id_device_add_err_device_admin_not_allowed: "You don't have permission to access this page.",
  id_device_update_err_device_admin_not_allowed: "You don't have permission to access this page.",

  invalid_authz_code: "Invalid Authorization Code",
};
