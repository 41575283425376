import {
  alpha,
  Avatar,
  Box,
  Card,
  darken,
  Divider,
  Grid,
  IconButton,
  styled,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFirstLetter } from '../../../utils';
import { CardProps } from '../model';

export interface CardLogoProps extends CardProps {
  logoField: string;
  nameField: string;
  categoryField: string;
  backgroundColorField: string;
  size?: string;
}

const CardWrapper = styled(Card)(
  ({ theme }) => `
  border-color: ${
    theme.palette.mode === 'light'
      ? theme.colors.alpha.black[30]
      : theme.colors.borders[1]
  };
  border-style: solid;
  border-width: 1px;

  &.conditional:hover {
    border-color: ${theme.colors.primary.light};
    border-style: solid;
    border-width: 1px;
  }
  
  &.conditionalOnRowClick:hover {
     background-color: ${
       theme.palette.mode === 'light' ? '#F8F9FA' : theme.colors.alpha.black[70]
     };
  }
  `
);

const CardLogo: FC<CardLogoProps> = (props) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const size = props.size === 'large' ? 6 : props.size === 'xlarge' ? 12 : 3;

  return (
    <Grid item xs={12} sm={props.size === 'xlarge' ? 12 : 6} md={4} lg={size}>
      <CardWrapper
        className={clsx({
          conditional: true,
          conditionalOnRowClick: props.onRowClick ? true : false,
        })}
        sx={{
          ...(props.onRowClick ? { cursor: 'pointer' } : { cursor: 'auto' }),
        }}
        onClick={() =>
          props.onRowClick ? props.onRowClick(props.data) : undefined
        }
      >
        <Box
          py={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: props.data[props.backgroundColorField]
              ? theme.palette.mode === 'light'
                ? props.data[props.backgroundColorField]
                : darken(props.data[props.backgroundColorField], 0.5)
              : '#FFFFFF',
          }}
        >
          {props.data[props.logoField] ? (
            <img
              height={theme.spacing(10)}
              style={{
                background: 'white',
                borderRadius: 10,
                opacity: theme.palette.mode === 'dark' && 0.7,
                boxShadow: `0 0.180rem .3rem ${alpha(
                  theme.colors.alpha.black[100],
                  0.3
                )}, 0 .326rem 3rem ${alpha(
                  theme.colors.alpha.black[100],
                  0.2
                )}`,
              }}
              alt={t(props.data[props.nameField])}
              src={`data:image/png;base64,${props.data[props.logoField]}`}
            />
          ) : (
            <Avatar
              sx={{
                background: (theme: Theme) => theme.palette.primary.light,
                maxWidth: 36,
                maxHeight: 36,
              }}
              variant="rounded"
            >
              {getFirstLetter(props.data[props.nameField])}
            </Avatar>
          )}
        </Box>
        <Divider />
        <Box
          pt={1}
          pb={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            gutterBottom
            noWrap
            variant="h4"
            sx={{
              fontSize: `${theme.typography.pxToRem(17)}`,
            }}
          >
            {t(props.data[props.nameField])}
          </Typography>
          <Typography noWrap variant="subtitle2">
            {t(props.data[props.categoryField])}
          </Typography>
        </Box>
        <Divider />
        <Box
          display="flex"
          p={1}
          // justifyContent="right"
          sx={{ background: `${theme.colors.alpha.black[5]}` }}
        >
          <Box>
            {props.statusField && props.statusFieldDecoratorFn
              ? props.statusFieldDecoratorFn(props.data[props.statusField])
              : props.data[props.statusField]}
          </Box>
          <Box sx={{ ml: 'auto' }}>
            {props.cardActions.map((action, i) => {
              return (
                <React.Fragment key={i}>
                  {((action.cardActionCondition &&
                    action.cardActionCondition(props.data)) ||
                    !action.cardActionCondition) && (
                    <Tooltip key={i} title={t(action.caption)} arrow>
                      <IconButton
                        onClick={(event) => {
                          action.callbackFn(props.data);
                          event.stopPropagation();
                        }}
                        color={action.color}
                        sx={{ padding: '5.25px' }}
                        id={
                          props.primaryKey
                            ? 'id_button_row_' +
                              props.data[props.primaryKey] +
                              '_' +
                              action.caption
                            : 'id_button_row_' + i + '_' + action.caption
                        }
                      >
                        <action.icon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
      </CardWrapper>
    </Grid>
  );
};

export default CardLogo;
