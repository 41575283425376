import { Box, useTheme } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { SidebarLayoutProps } from "../base";
import Header from "./Header";
import Sidebar from "./Sidebar";

const AccentHeaderLayout: FC<SidebarLayoutProps> = ({
  menu = [],
  landingURL = "",
}) => {
  const theme = useTheme();

  return (
    <>
      <Header landingURL={landingURL} />
      <Sidebar menuItems={menu} landingURL={landingURL} />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
          pt: `${theme.header.height}`,
          [theme.breakpoints.up("lg")]: {
            pl: `${theme.sidebar.width}`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box flexGrow={1}>
            <Outlet />
          </Box>
        </Box>
        {/* <ThemeSettings /> */}
      </Box>
    </>
  );
};

export default AccentHeaderLayout;
