import { Typography } from '@mui/material';
import React from 'react';
import { getDisplayMac } from './macutil';
import { getTruncatedNameByWidth } from './stringutils';

export function important<T>(value: T): T {
  return (value + ' !important') as any;
}

export const subtitleStyleWrapper = (field: string): JSX.Element => {
  if (!field) {
    return <Typography>&nbsp;</Typography>;
  }
  return <Typography variant="subtitle2">{field}</Typography>;
};

export const subtitleMacAddressStyleWrapper = (field: string): JSX.Element => {
  if (!field) {
    return <Typography>-</Typography>;
  }
  return subtitleStyleWrapper(getDisplayMac(field));
};

export const subtitleLinkMacAddressStyleWrapper = (field: string): string => {
  if (!field) {
    return '';
  }
  return getDisplayMac(field);
};

export const emphasizedFieldWrapper = (field: string): JSX.Element => {
  if (!field) {
    return <Typography>&nbsp;</Typography>;
  }
  return getTruncatedNameByWidth(field, 30, 400, 'h6', {
    pt: 1,
    pb: 1,
  });
};

export const emphasizedTitleFieldWrapper = (field: string): JSX.Element => {
  if (!field) {
    return <Typography>&nbsp;</Typography>;
  }
  return getTruncatedNameByWidth(field, 30, 200, 'h6', {
    pt: 1,
    pb: 1,
  });
};
