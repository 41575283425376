import { RichTextEditor, appConfig } from "@antara/shared";
import { Box, Divider } from "@mui/material";
import { useEffect, useState } from "react";

function CustomMessageViewer({ showAlways }: { showAlways?: boolean }) {
  const [successMessage, setSuccessMessage] = useState<any>();
  const [displayMsgWithPassphrase, setDisplayMsgWithPassphrase] = useState(false);

  useEffect(() => {
    const theme = appConfig.onboardTheme;
    if (theme) {
      if (theme.successMessage) {
        setSuccessMessage(theme.successMessage);
        setDisplayMsgWithPassphrase(theme.displayMsgWithPassphrase);
      }
    }
  }, []);

  return (
    <>
      {(showAlways || displayMsgWithPassphrase) && successMessage && (
        <>
          <Divider />
          <Box sx={{ mx: 1 }}>
            <RichTextEditor text={successMessage} viewer />
          </Box>
        </>
      )}
    </>
  );
}

export default CustomMessageViewer;
