import { axiosMockAdapterInstance, getRandomInt } from "@antara/shared";

export const mockRegister = () => {
  // Mock for me.client.onboard is in adddevice mock file

  axiosMockAdapterInstance.onPost("/api/me.client.disconnect").reply(() => {
    const errorList = [""];

    return [200, { error: errorList[getRandomInt(0, errorList.length - 1)] }];
  });
};
