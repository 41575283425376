import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Transition } from '../../utils/dialogutils';

export const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

export const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

export const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

interface DeletePopupProps {
  deleteMsg: string;
  entityName?: string;
  deleteHandler: any;
  closeHandler: any;
  deleteBtnLabel?: string;
  referenceMsg?: string[];
  count?: number;
  showLoader?: boolean;
}

const DeletePopup: FC<DeletePopupProps> = ({
  deleteMsg,
  entityName,
  deleteHandler,
  closeHandler,
  deleteBtnLabel,
  referenceMsg,
  count,
  showLoader,
}) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const cancelHandler = () => {
    closeHandler(false);
  };

  return (
    <>
      <DialogWrapper
        open={true}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={cancelHandler}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          py={3}
          px={1}
          sx={{
            ...(theme.palette.mode === 'dark' && {
              border: '1px solid ' + theme.colors.borders[1],
              borderRadius: 1,
            }),
          }}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 2,
              px: 4,
              wordBreak: 'break-word',
            }}
            variant="h3"
          >
            <Trans
              t={t}
              i18nKey={deleteMsg}
              values={entityName ? { entity: entityName } : { count }}
              tOptions={{ interpolation: { escapeValue: false } }}
            />
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              marginBottom: '16px',
            }}
          >
            {referenceMsg &&
              referenceMsg.map((item) => (
                <Alert severity="error">{item}</Alert>
              ))}
          </div>
          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1,
              }}
              onClick={cancelHandler}
              id="id_button_cancel"
              disabled={showLoader && loading}
            >
              {t('Cancel')}
            </Button>
            <LoadingButton
              size="large"
              sx={{
                mx: 1,
              }}
              variant="contained"
              id="id_button_delete"
              loading={loading}
              onClick={() => {
                if (showLoader) {
                  setLoading(true);
                }
                deleteHandler();
              }}
              color="error"
              disabled={showLoader && loading}
            >
              {deleteBtnLabel ? t(deleteBtnLabel) : t('Delete')}
            </LoadingButton>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

export default DeletePopup;
