import { BodyCell, CardKeyValueField, CardKeyValueProps, CsvColumn, FilterConfig, HeadCell, getFormattedRegularDate } from "@antara/shared";

import { Device } from "src/models/device";

export const DeviceDefaults: Device = {
  id: "",
  orgID: "",
  userID: "",
  name: "",
  host: "",
  port: 22,
  updatedAt: new Date(),
};

export const filterConfig: FilterConfig[] = [
  {
    filterType: "text",
    field: "query",
    label: "id_devices_search_desc",
    xs: 10,
    md: 10,
  },
];

export const cardFields: CardKeyValueField[] = [
  {
    fieldName: "host",
    fieldCaption: "Host",
  },
  {
    fieldName: "port",
    fieldCaption: "Port",
  },
];

export const cardConfig: CardKeyValueProps = {
  nameField: "name",
  cardFields: cardFields,
  maxCardFields: 2,
};

// Definition of table header -  order matters
export const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    allowSort: false,
  },
  {
    id: "host",
    numeric: false,
    disablePadding: false,
    label: "Host",
    allowSort: false,
  },
  {
    id: "port",
    numeric: false,
    disablePadding: false,
    label: "Port",
    allowSort: false,
  },
  {
    id: "updateAt",
    numeric: false,
    disablePadding: false,
    label: "update_time",
    allowSort: false,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    allowSort: false,
    centerAlign: true,
  },
];

// Definition of each record column - order matters
export const bodyCells: BodyCell[] = [
  {
    id: "name",
    bold: true,
    labelFn: null,
  },
  {
    id: "host",
    labelFn: null,
  },
  {
    id: "port",
    labelFn: null,
  },
  {
    id: "updatedAt",
    labelFn: getFormattedRegularDate,
  },
];

// -- Import Flow --

export const csvColumns: CsvColumn[] = [
  {
    displayName: "name",
    type: "string",
    checkEmptyValue: true,
    uniqueValue: true,
  },
  {
    displayName: "host",
    type: "ip",
    checkEmptyValue: true,
  },
  {
    displayName: "port",
    type: "number",
    checkEmptyValue: true,
  },
];

export const sampleData = [
  {
    name: "Switch-1",
    host: "192.168.1.1",
    port: "22",
  },
  {
    name: "Router-1",
    host: "172.16.5.1",
    port: "22",
  },
  {
    name: "Switch-2",
    host: "192.168.2.2",
    port: "22",
  },
];
