import { Alert, CopyText, PasswordField, SsidQrCode, useAuth } from "@antara/shared";
import WifiIcon from "@mui/icons-material/Wifi";
import { Box, Button, Card, Chip, Divider, Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomMessageViewer from "src/components/CustomMessageViewer";

const Results = ({ networks }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [pskNetworks, setPskNetworks] = useState([]);
  const clientsLink = window.location.href.replace(/passphrase.*/, "clients");

  useEffect(() => {
    setPskNetworks(networks);
  }, [networks]);

  return (
    <>
      <Card
        sx={{
          mb: 2,
        }}
      >
        {pskNetworks.length > 0 && (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} pl={2}>
              <Typography variant="h5" lineHeight={1}>
                {t("passphrase_qr_code_title")}
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                width: 1,
                my: 2,
                textAlign: "center",
              }}
            >
              <SsidQrCode authentication={"WPA"} ssidName={pskNetworks[0].ssid} password={auth.user.pskPassphrase}></SsidQrCode>
            </Box>
            <Divider />
          </>
        )}

        <Box sx={{ minHeight: 180 }}>
          <Box sx={{ mb: 1, p: 1 }}>
            {pskNetworks.length > 0 && (
              <Grid
                container
                spacing={2}
                sx={{
                  pl: "1em",
                  pb: 2,
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography variant="subtitle2">{t("wireless_network")}:</Typography>
                </Grid>
                <Grid item>
                  {/* {pskNetworks.map((pskNetwork) => ( */}
                  <Chip
                    icon={<WifiIcon />}
                    color="primary"
                    variant="outlined"
                    key={pskNetworks[0].id}
                    label={pskNetworks[0].ssid}
                    sx={{ mr: 1 }}
                  ></Chip>
                  {/* ))} */}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2} sx={{ pl: "1em", pt: 1 }}>
              <Grid item xs={12} md={8} lg={8}>
                <PasswordField label={t("Passphrase")} value={auth.user.pskPassphrase}></PasswordField>
              </Grid>
              <Grid item xs={4} md={4} lg={4} alignItems="center" sx={{ display: "flex", mt: "0.5em" }}>
                <CopyText text={auth.user.pskPassphrase} copyTxt={t("Copy")} copiedTxt={t("Copied")} height="1em" useBtn />
              </Grid>
            </Grid>
            <Typography
              sx={{
                pt: 2,
                pl: "1em",
              }}
              variant="subtitle2"
            >
              <Alert severity="info">{t("passphrase_view_subtitle")}</Alert>
            </Typography>
          </Box>
          <CustomMessageViewer />
          <Box
            sx={{
              py: 2,
              px: 1,
              textAlign: "right",
            }}
          >
            <Button
              id="id_button_manageClients"
              sx={{
                mr: 1,
              }}
              color="primary"
              type="submit"
              variant="contained"
              component={Link}
              href={clientsLink}
              target="_self"
              rel="noopener"
            >
              {t("manage_clients")}
            </Button>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default Results;
