import { CardAction, CrudConfig, CrudTemplate, GlobalContext, PageAction, StatusPage, appConfig, useAuth } from "@antara/shared";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import ComputerIcon from "@mui/icons-material/Computer";
import EditIcon from "@mui/icons-material/Edit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { DeviceDefaults, bodyCells, cardConfig, filterConfig, headCells } from "./Config";

const Devices = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useAuth();
  const { mobileView } = useContext(GlobalContext);

  const addDevice = () => {
    navigate("/" + id + "/administration/devices/add");
  };

  const navigateDevices = (selectedData) => {
    if (appConfig.basename && appConfig.basename !== "/") {
      window.open(
        window.location.origin + appConfig.basename + id + "/administration/devices/connect/" + selectedData.id,
        "_blank",
        "noopener noreferrer"
      );
    } else {
      window.open(
        window.location.origin + "/" + id + "/administration/devices/connect/" + selectedData.id,
        "_blank",
        "noopener noreferrer"
      );
    }
  };

  const connectDevice = async (selectedData) => {
    if (navigator.userAgent.indexOf("Chrome") !== -1) {
      navigateDevices(selectedData);
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      const url =
        "http://localhost:3333/ssh/host/" +
        selectedData.host +
        "?host=" +
        selectedData.host +
        "&port=" +
        selectedData.port +
        "&user=" +
        auth.user.loginName +
        "&password=" +
        auth.user.deviceAdministrationCredential;

      window.open(url, "_blank", "noopener noreferrer");
    } else {
      navigateDevices(selectedData);
    }
  };

  const updateDevice = (selectedData) => {
    navigate("/" + id + "/administration/devices/" + selectedData.id);
  };

  const pageActions: PageAction[] = [
    {
      caption: "add_or_import",
      icon: AddTwoToneIcon,
      callbackHandler: addDevice,
    },
  ];

  const cardActions: CardAction[] = [
    {
      caption: "Connect",
      callbackFn: connectDevice,
      icon: PlayArrowIcon,
      color: "success",
      changeURL: true,
    },
    {
      caption: "Update",
      callbackFn: updateDevice,
      icon: EditIcon,
      color: "primary",
      changeURL: true,
    },
  ];

  const readonlyActions: CardAction[] = [
    {
      caption: "Connect",
      callbackFn: connectDevice,
      icon: PlayArrowIcon,
      color: "success",
      changeURL: true,
    },
    {
      caption: "show_details",
      callbackFn: updateDevice,
      icon: VisibilityIcon,
      color: "primary",
    },
  ];

  const deviceConfig: CrudConfig = {
    resourceName: "id_devices",
    primaryKey: "name",
    newEntity: DeviceDefaults,

    listApiPath: "me.deviceAdmin.device.list",
    listApiResponseHeader: "devices",
    deleteApiPath: "me.deviceAdmin.device.delete",
    // onRowClick: showSelectedUserPopup,

    nameField: "name",
    deleteKeyField: "id",
    fieldsForContainsFilter: ["name", "host"],

    pageHeading: "Devices",
    pageSubheading: "devices_subheading",
    pageActions: pageActions,
    pageIcon: ComputerIcon,

    showToggleBtn: true,
    filterConfig: filterConfig,
    headCells: headCells,
    bodyCells: bodyCells,
    actions: cardActions,
    cardType: "keyvalue",
    cardConfig: cardConfig,
    saveDataOnRouteChange: true,
    readonlyActions,

    fetchDataByChunks: true,
    multiSelect: true,
    bulkDeleteProps: {
      deletePayloadKey: "deviceIDList",
      deleteKey: "id",
    },
    apiPrefix: "me.deviceAdmin.device",
  };

  return (
    <>
      {auth.user.deviceAdministrationAllowed && !mobileView ? (
        <CrudTemplate {...deviceConfig} forceUpdate={false} />
      ) : (
        <StatusPage status="403" hideNav />
      )}
    </>
  );
};

export default Devices;
