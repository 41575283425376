import { axiosMockAdapterInstance, getRandomInt } from "@antara/shared";

export const mockAddClient = () => {
  axiosMockAdapterInstance.onPost("/api/me.client.onboard").reply(() => {
    const errorList = ["", "duplicate_user_client", "internal_error", "upsk_max_personal_clients_exceeded"];
    const err = errorList[getRandomInt(0, errorList.length - 1)];
    const networkProfileURLList = ["https://www.google.com"];
    const deviceTypeList = ["Computer:Mac OS X:Mac", "Mobile:Android:Android", "Computer:Windows"];

    if (err !== "") {
      return [200, { error: err }];
    }

    const data = {
      data: {
        clientID: "This-is-my-cliendID",
        pskPassphrase: "onboardpsk",
        deviceType: deviceTypeList[getRandomInt(0, 2)],
        networkProfileURL: networkProfileURLList[getRandomInt(0, networkProfileURLList.length - 1)],
      },
      error: "",
    };
    return [200, data];
  });

  axiosMockAdapterInstance.onPost("/api/me.client.add").reply(() => {
    const data = {
      data: {
        clientID: "This-is-my-cliendID",
        orgID: "mockOrg",
      },
      error: "",
    };
    return [200, data];
  });
};
