import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
import UnsavedChangePrompt from '../UnsavedChangePrompt';

export const FormikDirtyFormPrompt = ({
  callback,
  initCallback,
}: {
  callback?(dirty: boolean): void;
  initCallback?(): void;
}) => {
  const { dirty } = useFormikContext();
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const loaded = useRef(false);
  useEffect(() => {
    setShowDialog(dirty);
    if (callback) {
      callback(dirty);
    }
  }, [dirty, callback]);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      if (initCallback) {
        initCallback();
      }
    }
  }, [initCallback]);

  return (
    <UnsavedChangePrompt
      showDialog={showPrompt}
      confirmNavigation={confirmNavigation}
      cancelNavigation={cancelNavigation}
    />
  );
};
