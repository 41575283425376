import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {
  alpha,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  lighten,
  List,
  ListItem,
  Popover,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../contexts/GlobalContext';
import useAuth from '../../hooks/useAuth';
import { getTruncatedNameByWidth } from '../../utils/stringutils';
import { DateSelector, DateSelectorConfig } from '../DateSelector';
import DeleteActions, { DeleteActionProps } from './DeleteAction';
import PageActions, { PageAction } from './PageAction';
import Label from '../Label';

const AvatarPageTitle = styled(Avatar)<{ mobileview?: string }>(
  ({ theme, mobileview }) => `
        width: ${mobileview === 'true' ? theme.spacing(4) : theme.spacing(6)};
        height: ${mobileview === 'true' ? theme.spacing(4) : theme.spacing(6)};
        color: ${
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[70]
            : theme.colors.primary.main
        };
        margin-right: ${theme.spacing(2)};
        background: ${
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[10]
            : theme.colors.alpha.white[50]
        };
        box-shadow: ${
          theme.palette.mode === 'dark'
            ? '0 1px 0 ' +
              alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
              ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
            : '0px 2px 4px -3px ' +
              alpha(theme.colors.alpha.black[100], 0.4) +
              ', 0px 5px 16px -4px ' +
              alpha(theme.colors.alpha.black[100], 0.2)
        };
  `
);

const PageTitleWrapper = styled(Box)(
  ({ theme }) => `
  padding: ${theme.spacing(1)} ${theme.spacing(2)};
`
);

interface PageTitleProps {
  heading: string;
  shortHeading?: string;
  subHeading: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconAsBase64?: string;
  actions?: PageAction[];
  deleteAction?: DeleteActionProps;
  dateSelector?: DateSelectorConfig;
  skipTitleTruncate?: boolean;
  label?: string;
  /**
   * Actions to be shown under More Actions i.e. ⋮
   */
  secondaryActions?: PageAction[];
  /**
   * Width of title. Default is 500.
   */
  titleWidth?: number;
  hideBottomBorder?: boolean;
  showBoxShadow?: boolean;
}

function PageTitle(props: PageTitleProps) {
  const { t }: { t: any } = useTranslation();
  const { mobileView } = useContext(GlobalContext);
  const auth = useAuth();
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'more-actions' : undefined;
  const theme = useTheme();

  const showMore = (): boolean => {
    if (
      !auth.user.readOnly &&
      ((props.deleteAction &&
        props.deleteAction.deleteUrl &&
        (!props.deleteAction.enableDelete ||
          (props.deleteAction.enableDelete &&
            props.deleteAction.enableDelete(props.deleteAction.type)))) ||
        (props.secondaryActions && props.secondaryActions.length > 0))
    ) {
      return true;
    }
    return false;
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getBorderStyle = (): 'divider' | 'shadow' | string => {
    let result: string;
    if (!props.hideBottomBorder) {
      if (
        theme.palette.mode === 'light' &&
        (props.showBoxShadow || mobileView)
      ) {
        result = 'shadow';
      } else {
        result = 'divider';
      }
    }
    return result;
  };

  return (
    <>
      <Grid
        id="page-title-header"
        container
        justifyContent="space-between"
        alignItems="center"
        padding={0}
        sx={{
          ...(getBorderStyle() === 'shadow' && {
            boxShadow: '0px 2px 10px lightgray',
            mb: 1,
          }),
        }}
      >
        <Grid item>
          <PageTitleWrapper
            display="flex"
            alignItems="center"
            sx={{
              ...(mobileView && { py: 1, my: 0 }),
            }}
          >
            {props.iconAsBase64 ? (
              <AvatarPageTitle
                mobileview={mobileView.toString()}
                variant="rounded"
                src={`data:image/png;base64,${props.iconAsBase64}`}
              />
            ) : (
              <AvatarPageTitle
                mobileview={mobileView.toString()}
                variant="rounded"
              >
                <props.icon
                  fontSize={mobileView === true ? 'small' : 'large'}
                />
              </AvatarPageTitle>
            )}
            <Box>
              {props.skipTitleTruncate ? (
                <Typography
                  variant={mobileView ? 'h4' : 'h3'}
                  fontSize={mobileView ? '16px' : '20px'}
                >
                  {mobileView && props.shortHeading
                    ? t(props.shortHeading)
                    : t(props.heading)}
                </Typography>
              ) : (
                <Stack
                  direction={'row'}
                  spacing={2}
                  display={'flex'}
                  alignItems={'center'}
                >
                  {getTruncatedNameByWidth(
                    mobileView && props.shortHeading
                      ? t(props.shortHeading)
                      : t(props.heading),
                    mobileView ? 20 : 30,
                    mobileView
                      ? 150
                      : props.titleWidth
                      ? props.titleWidth
                      : 500,
                    mobileView ? 'h4' : 'h3',
                    { fontSize: mobileView ? '16px' : '20px' }
                  )}
                  {props.label && (
                    <Label color={'warning'}>{t(props.label)}</Label>
                  )}
                </Stack>
              )}
              {props.heading && (
                <Typography
                  sx={{ display: { xs: 'none', md: 'block' } }}
                  variant="subtitle2"
                >
                  {t(props.subHeading)}
                </Typography>
              )}
            </Box>
          </PageTitleWrapper>
        </Grid>
        {props.actions && (
          <Grid item sx={{ float: 'left' }}>
            <PageTitleWrapper
              display="flex"
              alignItems="center"
              sx={{
                ...(mobileView && {
                  py: 1,
                  my: 0,
                }),
              }}
            >
              {props.actions.map((action, index) => (
                <PageActions key={index} {...action}></PageActions>
              ))}
              {showMore() && (
                <Tooltip title={t('More Actions')} arrow>
                  <IconButton
                    sx={{
                      ml: 1,
                      px: 0.5,
                      border: '1px solid',
                      borderColor: 'primary.light',
                      ...(mobileView && { py: 0.5 }),
                    }}
                    color="primary"
                    onClick={handleOpen}
                  >
                    <MoreVertOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </PageTitleWrapper>
          </Grid>
        )}
        {props.dateSelector && !mobileView && (
          <Grid item sx={{ pr: 4 }}>
            <DateSelector
              config={props.dateSelector}
              // fromTimestamp -> req payload key name
            ></DateSelector>
          </Grid>
        )}
      </Grid>
      {props.dateSelector && mobileView && (
        <Box display="flex" justifyContent="center" sx={{ pb: 1 }}>
          <DateSelector config={props.dateSelector}></DateSelector>
        </Box>
      )}
      {getBorderStyle() === 'divider' && (
        <Divider variant="middle" sx={{ mb: 1 }} />
      )}
      <Popover
        id={id}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: 1 }}
      >
        <Box>
          <List
            sx={{
              p: 0,
            }}
            component="nav"
          >
            {props.secondaryActions &&
              props.secondaryActions.map((action, index) => (
                <ListItem disablePadding key={index}>
                  <Button
                    sx={{
                      px: 2,
                      borderRadius: 0,
                      justifyContent: 'flex-start',
                    }}
                    size="large"
                    fullWidth
                    key={index}
                    startIcon={<action.icon fontSize="small" />}
                    color={action.color}
                    onClick={() => {
                      setAnchorEl(null);
                      action.callbackHandler();
                    }}
                  >
                    {t(action.caption)}
                  </Button>
                </ListItem>
              ))}
            {props.deleteAction && props.deleteAction.deleteUrl && (
              <ListItem disablePadding>
                <DeleteActions {...props.deleteAction} />
              </ListItem>
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default PageTitle;
