import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Divider,
  Grid,
  Typography,
  createFilterOptions,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appConfig } from '../../config';
import useAuth from '../../hooks/useAuth';
import { postAPI } from '../../utils/httputils';
import { showNotification } from '../../utils/notifyutils';
import AAISelectToolTip from '../AAISelect/AAISelectToolTip';
import { AAITextField } from './AAIAutoCompleteStyled';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface AAIAutocompleteProps {
  title: string;
  labelKey?: string;
  secondaryLabelKey?: string;
  valueKey: string;
  onChange: any;
  lastElement?: boolean;
  options?: any[];
  selectedValue?: any;
  newOption?: any;
  isMultiple?: boolean;
  apiPath?: string;
  apiResponseKey?: string; // required if apiPath is passed
  apiErrorKey?: string; // required if apiPath is passed
  showDefaultOption?: boolean;
  defaultOption?: any[]; // required if showDefaultOption is true, only 1 option supported
  allowFreeText?: boolean; // not supported when multiple options are allowed
  limitTags?: number; // The maximum number of tags that will be visible when not focused, Set -1 to disable the limit.
  errorText?: string;
  helperText?: string;
  error?: boolean;
  delete_allowed?: boolean | string;
  delete_click?: () => void;
  add_allowed?: boolean | string;
  add_click?: () => void;
  add_tooltip_text?: string;
  add_drawer_component?: JSX.Element;
  setFieldValue?(field, value);
  addInline?: boolean;
  fetchDataByChunks?: boolean;
  disabledValue?: any;
  skipPlaceholder?: boolean;
  showCheckbox?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
  formatter?: (value: any) => any;
  formattedQuery?: (query: string) => string;
}

const AAIAutocomplete = (props: AAIAutocompleteProps) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState(props.options ? props.options : []);
  const [searchTerm, setSearchTerm] = useState<any>();
  const [val, setVal] = useState(props.isMultiple ? [] : null);
  const theme = useTheme();
  const filter = createFilterOptions();

  const getObjectIndex = (obj, list, key) => {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i][key] === obj[key]) {
        return i;
      }
    }

    return -1;
  };

  // first arg is event
  const onOptionChange = (e, values) => {
    let defaultOptionIndex = -1;

    if (props.addInline && e.type === 'blur') {
      return;
    }
    if (props.isMultiple && props.showDefaultOption) {
      defaultOptionIndex = getObjectIndex(
        props.defaultOption[0],
        values,
        props.valueKey
      );
    }

    if (
      props.allowFreeText &&
      !props.isMultiple &&
      props.apiPath &&
      values === null
    ) {
      getOptions();
    }

    if (
      props.allowFreeText &&
      !props.isMultiple &&
      typeof values === 'string'
    ) {
      if (props.apiPath) {
        getOptions(values);
      } else {
        let valueObj = {};
        if (props.labelKey) {
          valueObj[props.labelKey] = values;
        }
        valueObj[props.valueKey] = values;
        values = valueObj;
      }
    }

    if (
      props.showDefaultOption &&
      defaultOptionIndex !== -1 &&
      props.isMultiple &&
      values.length > 1
    ) {
      if (defaultOptionIndex === values.length - 1) {
        // When defaultOption is selected, remove other selected options
        setVal(props.defaultOption);
        props.onChange(props.defaultOption);
      } else {
        // When defaultOption was already selected and then new option is selected, remove defaultOption from selected
        setVal(values.slice(1));
        props.onChange(values.slice(1));
      }
    } else {
      setVal(values);
      props.onChange(values);
    }
    if (props.setFieldValue) {
      props.setFieldValue('dirty', true);
    }
  };

  const getOptions = useCallback(async (query?: string) => {
    let req = { orgID: auth.user.orgID };
    if (query) {
      req['query'] = props.formattedQuery ? props.formattedQuery(query) : query;
    }
    postAPI(props.apiPath, req, (resp) => {
      if (!resp.error || resp.error === '') {
        if (props.showDefaultOption) {
          let totalOptions = props.defaultOption.concat(
            resp.data[props.apiResponseKey]
          );
          setOptions(totalOptions);
        } else {
          setOptions(resp.data[props.apiResponseKey]);
        }
      } else {
        showNotification(
          enqueueSnackbar,
          t('autocomplete_err_' + props.apiErrorKey + '_' + resp.error),
          'error'
        );
      }
    });
  }, []);

  useEffect(() => {
    if (
      !appConfig.unitTest &&
      props.allowFreeText &&
      !props.isMultiple &&
      props.apiPath
    ) {
      const delayDebounceFn = setTimeout(() => {
        if (searchTerm) {
          getOptions(searchTerm);
        }
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else {
      return () => {};
    }
  }, [searchTerm]);

  useEffect(() => {
    if (props.apiPath) {
      getOptions();
    } else {
      if (props.showDefaultOption) {
        let totalOptions = props.defaultOption.concat(props.options);
        setOptions(totalOptions);
      } else {
        setOptions(props.options);
      }
    }
  }, [
    props.apiPath,
    props.apiResponseKey,
    props.apiErrorKey,
    auth.user.orgID,
    t,
    enqueueSnackbar,
    props.options,
    props.defaultOption,
    props.showDefaultOption,
  ]);

  useEffect(() => {
    const value = props.selectedValue
      ? props.selectedValue
      : props.isMultiple
      ? []
      : null;
    setVal(value);
  }, [props.selectedValue, props.isMultiple]);

  useEffect(() => {
    if (props.newOption) {
      getOptions();
    }
  }, [props.newOption]);

  const getFormattedValue = (value: any): any => {
    if (props.formatter) {
      return props.formatter(value);
    }
    return String(value);
  };

  const addEndAdornment = (endAdornment) => {
    const children = React.Children.toArray(endAdornment.props.children);
    if (props.add_allowed) {
      children.push(
        <AAISelectToolTip
          key={props.add_tooltip_text}
          add_tooltip_text={props.add_tooltip_text}
          add_click={props.add_click}
        />
      );
    }
    return React.cloneElement(endAdornment, {}, children);
  };

  const formatOptionLabel = (option): String => {
    return typeof option === 'string' || option instanceof String
      ? option
      : option[props.labelKey] && option[props.secondaryLabelKey]
      ? t(
          `${getFormattedValue(option[props.labelKey])} (${
            option[props.secondaryLabelKey]
          })`
        )
      : option[props.labelKey]
      ? t(getFormattedValue(option[props.labelKey]))
      : option[props.secondaryLabelKey]
      ? t(option[props.secondaryLabelKey])
      : t(option[props.labelKey || props.valueKey]);
  };

  return (
    <>
      {auth.user.readOnly ? (
        <>
          {val && (
            <>
              {Array.isArray(val) ? (
                <>
                  {val.length > 0 && (
                    <Grid container gap={1} alignItems="center">
                      <Grid item sx={{ ml: 1 }}>
                        <Typography>{t(props.title)}</Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{ ...(props.title && { marginLeft: 'auto' }) }}
                      >
                        {val.map((v, i) => (
                          <Grid key={i} sx={{ my: 0.25 }} item>
                            <Chip
                              label={t(v[props.labelKey || props.valueKey])}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid container gap={1} alignItems="center">
                  <Grid item sx={{ ml: 1 }}>
                    <Typography>{t(props.title)}</Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{ ...(props.title && { marginLeft: 'auto' }) }}
                  >
                    <Grid sx={{ my: 0.25 }} item>
                      <Chip label={t(val[props.labelKey || props.valueKey])} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!props.lastElement && (
                <Divider sx={{ margin: '8px 0 0 0' }} variant="middle" />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Autocomplete
            disabled={props.disabled}
            id={'id_' + props.title + '_autocomplete'}
            freeSolo={props.allowFreeText} // allows free text
            autoSelect={props.allowFreeText} // converts free text to chip when lose focus
            fullWidth
            disableCloseOnSelect={props.showCheckbox}
            multiple={props.isMultiple}
            limitTags={props.limitTags ? props.limitTags : 2}
            value={val}
            options={options}
            disableClearable={props.disableClearable}
            getOptionLabel={(option) => {
              if (option.inputValue) {
                return option.inputValue;
              }
              return formatOptionLabel(option);
            }}
            //   defaultValue={defaultValue}
            onChange={onOptionChange}
            onInputChange={(_, v) => setSearchTerm(v)}
            blurOnSelect={props.fetchDataByChunks}
            isOptionEqualToValue={(option, value) =>
              option[props.valueKey] === value[props.valueKey]
            }
            renderOption={(propsX, option, { selected }) => (
              <React.Fragment key={Math.random()}>
                <Box
                  component="li"
                  {...propsX}
                  id={
                    'id_' +
                    props.title +
                    '_autocomplete_option_' +
                    option[props.valueKey]
                  }
                >
                  {props.showCheckbox && (
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                  )}
                  {props.showDefaultOption &&
                  option[props.valueKey] ===
                    props.defaultOption[0][props.valueKey] ? (
                    <Typography sx={{ fontWeight: 700 }}>
                      {t(option[props.labelKey || props.valueKey])}
                    </Typography>
                  ) : option[props.labelKey] &&
                    option[props.secondaryLabelKey] ? (
                    <Typography>
                      {`${getFormattedValue(option[props.labelKey])} (${
                        option[props.secondaryLabelKey]
                      })`}
                    </Typography>
                  ) : option[props.labelKey] ? (
                    <Typography>
                      {t(getFormattedValue(option[props.labelKey]))}
                    </Typography>
                  ) : option[props.secondaryLabelKey] ? (
                    <Typography>
                      {`${t(option[props.secondaryLabelKey])}`}
                    </Typography>
                  ) : (
                    <Typography>
                      {t(option[props.labelKey || props.valueKey])}
                    </Typography>
                  )}
                </Box>
                {props.showDefaultOption &&
                  option[props.valueKey] ===
                    props.defaultOption[0][props.valueKey] && (
                    <Divider
                      sx={{
                        backgroundColor: `${theme.colors.alpha.black[50]}`,
                      }}
                    ></Divider>
                  )}
              </React.Fragment>
            )}
            renderInput={(params) => {
              let selectParams = { ...params };
              if (props.add_allowed) {
                selectParams = {
                  ...selectParams,
                  InputProps: {
                    ...params.InputProps,
                    endAdornment: addEndAdornment(
                      params.InputProps.endAdornment
                    ),
                  },
                };
              }

              return (
                <AAITextField
                  id={'id_' + props.title + '_autocomplete_text'}
                  {...selectParams}
                  fullWidth
                  variant="outlined"
                  label={t(props.title)}
                  placeholder={
                    props.skipPlaceholder
                      ? ''
                      : t('Select ' + t(props.title)) + '...'
                  }
                  error={props.error}
                  helperText={
                    props.error ? t(props.errorText) : t(props.helperText)
                  }
                  InputProps={{ ...selectParams.InputProps }}
                />
              );
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (props.addInline) {
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option[props.labelKey]
                );
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    [props.labelKey]: `Add "${inputValue}"`,
                  });
                }
              }
              return filtered;
            }}
            getOptionDisabled={(option) =>
              option[props.valueKey] === props.disabledValue
            }
          />
        </>
      )}
      {props.add_drawer_component && props.add_drawer_component}
    </>
  );
};

export default AAIAutocomplete;
